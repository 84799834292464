import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class PivotalCustomerStory extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>How Pivotal Uses Grammarly Business to Communicate With Confidence</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/pivotal-customer-story/GB-Blog-banner---Pivotal----Desktop-1X.jpg")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>May 15, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">When you’re trying to build trust with a high-profile customer, you can’t afford to look sloppy or inconsistent. Some haphazard punctuation or an inadvertent typo might be all it takes to sour an executive’s impression of your company. </p>

                <p>That’s why Pivotal uses Grammarly Business to ensure clear and consistent communications with other businesses: It helps them convey confidence and trustworthiness.</p>

                <p>Pivotal equips companies as varied in scope as carmakers and telecommunications giants for the future of cloud computing and big data. And when Pivotal has important information for a client’s CEO, Sean Scanlon writes the executive summary.</p>

                <p>
                  As a global escalation manager, Scanlon regularly coordinates between engineers, sales leaders, and marketing executives. Scanlon and his teammates’ written communication must always be sharp—but even the best writers and support managers have uneven days. That’s where Grammarly
                  comes in.
                </p>

                <p>
                  “In today’s business environment, you often only get one shot to be understood, especially with CEOs and Fortune 500 customers. Using Grammarly Business has helped our team create the best first impression with our communication to those customers, which has led to us having more
                  impact and credibility when resolving critical issues,” Scanlon says.
                </p>

                <p>Before Grammarly, Scanlon and his colleagues knew they wanted to keep their writing consistently active and concise while avoiding jargon. But individual styles varied across continents, and sustaining a uniform level of quality across channels was a tall order. </p>

                <p>
                  Then Scanlon showed Grammarly to his director, who recognized the make-or-break importance of communicating clearly. After seeing a demonstration of how Grammarly can help streamline long paragraphs, the value to the team was evident. The Escalation Management team now runs on
                  Grammarly Business.
                </p>

                <p>“Uniform messaging is so important when dealing with escalations. I immediately saw the value of Grammarly Business in helping our team communicate more consistently with our customers,” says Shaun O’Brien, director of escalation management at Pivotal.</p>

                <p>Scanlon estimates that a task that would have taken 40 minutes to write, edit, and polish now only takes 20 or 30 minutes. </p>

                <p>
                  He’s not just faster, he’s more effective. Grammarly has helped rid his writing of <a href="https://www.grammarly.com/blog/hedging-language/" target="_blank">
                    hedging language
                  </a>, ensuring his message comes across clearly and with confidence. By communicating more directly, the people he’s interacting with aren’t left wondering what’s supposed to happen next.
                </p>

                <p>
                  Scanlon also feels like he can think more clearly thanks to Grammarly. That matters because he’s often coordinating with savvy executives and technical experts. For them to take his writing seriously, Sean needs to convey an authoritative understanding of the subject using
                  straightforward terms that hide nothing behind jargon or clutter.
                </p>

                <p>In that sense, Grammarly helps Pivotal inspire trust with customers. That’s paramount to Scanlon’s work.</p>

                <p>There’s one other upside: lately, he gets a lot of compliments for his direct writing style. </p>

                <p>
                <a href="/yourteam/customer-experience" target="_blank">
                  Learn more
                  </a> about how Grammarly Business helps companies create better experiences for their customers:
                </p>
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PivotalCustomerStory;
