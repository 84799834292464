import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";
import { Link } from "react-router-dom";

class UpskillMoreEfficientCXTeams extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Upskill Your Way to More Efficient CX Teams</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/upskill-more-efficient-cx-teams/upskill-more-efficient-cx-teams-desktop.png")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/upskill-more-efficient-cx-teams/upskill-more-efficient-cx-teams-mobile.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>October 12, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                
                <p className="IntroPara">Over <a href="https://www.gartner.com/en/marketing/insights/articles/key-findings-from-the-gartner-customer-experience-survey" target="_blank">two-thirds</a> of companies now compete primarily based on customer experience, up from 36 percent just ten years ago. Companies that want to drive growth and stand out from the competition should invest in a culture of upskilling and digital automation. Innovative CX leaders at these companies have the opportunity to improve efficiency and customer satisfaction by upskilling their teams through intelligent solutions.</p>

                <h2 className="my-4">Barriers to upskilling</h2>
                <img className="img-fluid w-100 mb-3" src={require("../../assets/images/blog/upskill-more-efficient-cx-teams/upskill-more-efficient-cx-teams-1.png")} alt="" />
                <p>According to Acquia, <a href="https://www.acquia.com/resources/ebooks/deliver-cx-they-expect-customer-experience-trends-report" target="_blank">51 percent</a> of respondents in its customer experience trends report struggle with the speed needed to bring customer experiences to the market. Some organizations are better at anticipating their skill sets to succeed and actively invest in training programs to support their goals. But while some forge ahead, most leaders say <a href="https://www.pwc.com/gx/en/issues/upskilling/everyone-digital-world.html" target="_blank">they are not ready</a>.</p>


                <div class="container-fluid my-5 px-0">
                  <div class="green-container">
                    <div class="row no-gutters">
                      <div class="col-12 p-3">
                        <p>In our latest ebook, Hacking CX: The New Standard for Customer Communication, we explore the different challenges facing customer experience teams today and how CX leaders can get ahead of the curve. <a href="/hacking-cx-ebook" target="_blank">Download now!</a></p>
                      </div>
                    </div>
                  </div>
                </div>

                <p><a href="https://www.mckinsey.com/business-functions/organization/our-insights/getting-practical-about-the-future-of-work" target="_blank">Sixty-four percent</a> of executives in the U.S. believe it is up to them to take the lead in closing skill gaps and preparing their teams. A new <a href="https://www.globenewswire.com/news-release/2020/05/12/2031710/0/en/Pluralsight-State-of-Upskilling-Report-Uncovers-Fundamental-Divide-Between-Leaders-and-Employees-Around-Skills-Development-and-Barriers-For-Upskilling-at-Work.html" target="_blank">State of Upskilling report</a> by technology skills platform, Pluralsight, found that although leaders understand the importance of continuous learning and upskilling, core challenges can slow skills development.</p>

                <ul>
                  <li>
                    <p><strong>Time</strong>. The number one barrier to upskilling is lack of time—<a href="https://www.globenewswire.com/news-release/2020/05/12/2031710/0/en/Pluralsight-State-of-Upskilling-Report-Uncovers-Fundamental-Divide-Between-Leaders-and-Employees-Around-Skills-Development-and-Barriers-For-Upskilling-at-Work.html" target="_blank">38 percent</a> of respondents claimed other demands prevent their learning efforts. Thirty-one percent said that leaders could enable tech skill development by allocating more work hours for learning.</p>
                  </li>
                  <li>
                    <p><strong>Lack of tailored programs</strong>. Only <a href="https://www.globenewswire.com/news-release/2020/05/12/2031710/0/en/Pluralsight-State-of-Upskilling-Report-Uncovers-Fundamental-Divide-Between-Leaders-and-Employees-Around-Skills-Development-and-Barriers-For-Upskilling-at-Work.html" target="_blank">23 percent</a> of leaders report that their upskilling practices are tailored to the needs of each individual. Workers want digital, self-paced programs.</p>
                  </li>
                  <li>
                    <p><strong>Money</strong>. Upskilling is seen as expensive. However, most companies already provide these opportunities to employees—<a href="https://www.globenewswire.com/news-release/2020/05/12/2031710/0/en/Pluralsight-State-of-Upskilling-Report-Uncovers-Fundamental-Divide-Between-Leaders-and-Employees-Around-Skills-Development-and-Barriers-For-Upskilling-at-Work.html" target="_blank">94 percent</a> reported that their employer offers upskilling programs. However, professional development stipends and tuition programs exist for workers to reach personal growth. In fact, The Wall Street Journal <a href="https://www.wsj.com/articles/the-answer-to-your-companys-hiring-problem-might-be-right-under-your-nose-11555689542" target="_blank">reported</a> that employers spend an average of $1,300 on education per employee each year. This is great for employees, but what if that money was allocated toward building capabilities in strategic, priority areas for the company?</p>
                  </li>
                  <li>
                    <p><strong>High expectations</strong>. Transforming your workforce doesn’t mean your call center staff need to become software developers. Leaders should set realistic expectations that workers can learn to work with technology and automate their work with capabilities such as natural language processing.</p>
                  </li>
                  
                </ul>

                <p>What CX teams need is a practical, low-barrier approach to upskilling. One that won’t take time away from core tasks and presents opportunities to learn while doing the core work. The good news is that many companies already have the budget and the team to do the job. They don’t need to go out and hire entirely new teams.</p>

                <h2 className="my-4">Upskilling for CX teams</h2>
                <p>For customer experience teams, soft skills such as leadership and communication are arguably more important than hard skills. Our hyper-connected world has exacerbated the need for effective communication across a variety of channels. <a href="https://www.pwc.com/us/en/advisory-services/publications/consumer-intelligence-series/pwc-consumer-intelligence-series-customer-experience.pdf#page=9" target="_blank">Forty-six percent</a> of consumers will abandon a brand if the employees are not knowledgeable. In an economy fueled by customer experience, there is little room for poor communication.</p>

                <h2 className="my-4">Better customer communication</h2>
                <p>Your CX team interacts with your customers across multiple channels—email, live chat, and social media. To build brand credibility and customer loyalty, you need to engage your customers in a consistent and authentic way. To achieve that, you need to be able to communicate clearly and effectively.</p>

                <p><a href="https://www.grammarly.com/blog/how-grammarly-uses-ai/" target="_blank">AI-powered tools</a> like Grammarly can eliminate grammar, spelling, and punctuation errors. A global customer experience firm using Grammarly Business saw an 82 percent reduction in chat error rate. Another enterprise team was able to cut down on writing mistakes by 74 percent. Once your customer messages are mistake-free, you can incorporate suggestions to improve readability, word choice, and tone. Most people struggle to write concisely, and tools that can teach your CX staff to write more concisely, will save them time in the long run.</p>

                <p>Customers gravitate towards experiences that make them feel heard and appreciated. The tone of our customer communication is incredibly important in increasing customer satisfaction and converting customers into loyal fans. According to Salesforce research, <a href="https://www.salesforce.com/research/customer-expectations/" target="_blank">84 percent</a> of customers say being treated like a person rather than a number is paramount to winning their business. Upskilling these soft skills through intuitive, smart tools can ensure your customer communication stays engaging and friendly.</p>

                <h2 className="my-4">Higher productivity</h2>
                <p>Research shows when CX teams have access to tools that improve their ability to handle customer issues and simply their work, productivity increases by up to <a href="https://www.gartner.com/smarterwithgartner/unveiling-the-new-and-improved-customer-effort-score/" target="_blank">20 percent</a>. Customer satisfaction also increases by 11 percent, and the customer's effort decreases by 9 percent.</p>

                <p>Grammarly Business helps new and tenured CX agents write better and faster everywhere they work by seamlessly integrating across customer-facing channels and platforms. Individuals have access to features that transform sentences with just one click and enhances their writing productivity as they onboard or improve their skills.</p>

                <p>Teams that use Grammarly Business for customer-facing functions report a 12 percent reduction in contact for customer resolution and a 50 percent reduction in writing time per task.</p>

                <h2 className="my-4">More Collaboration</h2>
                <p>Better <Link to="/blog/communication-empowers-teamwork">communication fosters teamwork</Link>. Clear and consistent writing not only ensures that ideas are more likely to be understood—they’re more likely to be understood similarly across a team. This shared context helps break down silos between individuals and teams, reduces unnecessary back-and-forth, and increases buy-in for decision-making.</p>

                <p>Grammarly Business can also align your team with customizable <Link to="/styleguide-cs">style guides</Link>. Company-wide style guides can ensure that teams representing your brand are using company-specific language and following brand guidelines. An enterprise customer-support team caught over 300 errors per day using the Grammarly style guide feature. As a CX leader, you can’t coach or edit everyone, but you can set up processes that automate the basics.</p>

                <h2 className="my-4">Learn More</h2>
                <p>Learn more about how Grammarly Business addresses core customer experience challenges and helps upskill CX teams with scalable communication. <a href="/hacking-cx-ebook" target="_blank">Download now!</a> Hacking CX: The New Standard for Customer Communication, or reach out to our sales team to request a demo for your team.</p>

              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default UpskillMoreEfficientCXTeams;
