
import React, { type ElementConfig } from 'react';
import Select, { components } from 'react-select';

const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <span class="caret-down"></span>
      </components.DropdownIndicator>
    );
};
 
export default DropdownIndicator;