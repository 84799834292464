import React from 'react'
import Layout from '../../components/Layout'
import ContactForm from '../../components/ContactForm'

const LetsTalk = () => (
  <Layout className="lets-talk-page">
    <div className="form-page-header-section">
      <section className="header-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="row">
                <div className="col">
                  <h1 className="card-title heading-type-1">We’re here to help!</h1>
                </div>
              </div>
              <div className="row">
                <div className="col col-md-9 pr-md-5">
                  <p className="p-type-3">We’d love to discuss how Grammarly Business can help you and
                    your team succeed. Tell us about your business
                    and we’ll do the rest.</p>
                </div>
              </div>
              <div className="row mb-4 mb-md-0 d-none d-md-block">
                <div className="col mt-4 pt-5 ml-n2">
                  <img className="lets-talk-header-img-container ml-n4" src={require('../../assets/images/Photo_Form.png')} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-5 px-0">
              <img className="form-img-right d-none d-md-block" src={require('../../assets/images/Dots.svg')} alt=""/>
              <img className="form-img-left d-none d-md-block" src={require('../../assets/images/Lines.svg')} alt=""/>
              <div className="form-container px-2 pt-3 p-md-4">
                <div className="p-2">
                  <ContactForm type="talk" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4 mb-md-0 d-md-none">
            <div className="col mt-4 pt-5 text-center">
              <img className="header-img-container img-fluid" src={require('../../assets/images/Photo_Form.png')} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default LetsTalk;
