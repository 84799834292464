import React from 'react'
import Layout from '../../components/Layout'
import ContactForm from "../../components/ContactForm"

const RequestDemo = () => (
  <Layout className="lets-talk-page">
    <div className="form-page-header-section">
      <section className="header-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="row">
                <div className="col">
                  <h1 className="card-title heading-type-1">See how it works!</h1>
                </div>
              </div>
              <div className="row">
                <div className="col col-md-9 pr-md-5">
                  <p className="p-type-3">Our tools are designed to meet your specific communication needs. We’d love to show you how Grammarly Business can empower your team and accelerate the results that matter most.</p>
                </div>
              </div>
              <div className="row mb-4 mb-md-0 d-none d-md-block">
                <div className="col mt-4 pt-5 ml-n2">
                  <img alt="" className="dots-background" src={require('../../assets/images/Dots.svg')} />
                  <img alt="" className="header-img-container ml-n4 img-fluid" src={require('../../assets/images/Multiple screens_Form.png')} />
                </div>
              </div>
            </div>
            <div className="col-md-5 px-0">
              <img className="form-img-right d-none d-md-block" src={require('../../assets/images/Dots.svg')} alt=""/>
              <img className="form-img-left d-none d-md-block" src={require('../../assets/images/Lines.svg')} alt=""/>
              <div className="form-container px-2 pt-3 p-md-4">
                <div className="p-2">
                  <ContactForm type="demo" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4 mb-md-0 d-md-none">
            <div className="col mt-4 pt-5 text-center">
              <img className="header-img-container img-fluid" src={require('../../assets/images/Photo_Form.png')} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default RequestDemo;
