import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class CXTrendsToAchieveBusinessGoals extends React.Component {
  state = {}

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>3 CX Trends That Will Help You Achieve Your Business Goals</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/3-cx-trends-to-achieve-business-goals/595-Three_CX_Trends_in_2020_Desktop_2X.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>July 16, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">Customer experience (CX) rules the world of business. In fact, by the end of 2020, CX will be the key brand differentiator, surpassing both price and product. When companies deliver superior CX, customers are likely to spend 140%<sup>1</sup> more.</p>

                <p>As a CX leader, you understand the importance of delivering a seamless, consistent, and on-brand experience. But considering its rising importance, how confident are you that your CX strategy stacks up? If you're not sure, don't worry—we're here to highlight three 2020 trends and how you can incorporate them to actualize your business goals.</p>

                <h2 className="my-4">Personalizing the experience with intelligent tech</h2>

                <p>Companies that are excelling in delivering the ultimate CX are <a href="https://www.smartinsights.com/user-experience/customer-experience-management-cxm/improve-customer-experience-intelligent-technology/" target="_blank">leveraging technology</a> to make it happen. Artificial intelligence, robotic process automation, chatbots, and the like enable customers to have 24/7 access to the company without sacrificing quality in engagement. Even better, analytics surrounding these tools help companies understand what is working and where they can improve their efforts.</p>

                <p>
                While tech tools are useful, they don’t replace strategy altogether. Ensure that when you adopt intelligent technology solutions, you do so intentionally by first understanding your company’s customer journey. If you don’t have one already, start by building out a customer journey map. Then highlight specific areas where technology can enhance the experience.
                </p>

                <h2 className="my-4">Creating cohesion with omnichannel customer engagement</h2>

                <p>
                While companies have traditionally operated within a multi-channel model for customer engagement, we're seeing a shift toward omnichannel strategies. By ensuring all channels are informed by a single strategy and integrated sources of customer data, the omnichannel approach prevents interactions that are siloed to a particular channel and enables a more seamless customer experience.  While it requires some investment, the shift in channel strategy seems to be paying off—companies who have adopted the omnichannel approach <a href="https://www.business2community.com/infographics/10-notable-omnichannel-trends-statistics-infographic-01520137" target="_blank">retain</a> 89% of their customers.
                </p>

                <p>
                In today’s tech-driven ecosystem, creating visibility and sharing information between systems is imperative. While this may seem daunting at first, consider whether moving to a centralized data hub is a feasible first step. The good news is that most applications have integration capabilities, making implementation less cumbersome.
                </p>

                <h2 className="my-4">Aligning the brand with customer-centric communication</h2>

                <p>
                Customers are looking beyond the product they are buying and focusing on the brand behind the product. High-performing companies are leaning into content as a way to express their brand values and place customers front and center. In fact, customer-centric companies <a href="https://www2.deloitte.com/content/dam/Deloitte/de/Documents/WM%20Digitalisierung.pdf" target="_blank">are 60% more profitable</a> than companies that do not prioritize CX. In 2020 and beyond, you’ll see content creation expand in diverse and meaningful ways to include customer-inspired stories, socially connected communication, and improved customer resolution. Companies can ensure a consistent and customer-centric brand experience across these content types with unified messaging, voice, and style.
                </p>

                <p>Getting individual team members to communicate as one—and in real-time—is challenging but not impossible. Consider investing in <a href="https://grammarly-enterprise.com/blog/grammarly-business-style-guide" target="_blank">AI tools</a> to generate custom style guides and rules to help your team deliver on-brand messaging every time.</p>

                <h2 className="my-4">CX is the future of business</h2>

                <p>Prioritizing the customer experience should be at the forefront of organizational leaders’ minds.  Brands that champion the ultimate customer experience empower their companies to increase revenue and achieve their business goals. If your CX has room for improvement, you’re not alone. Start with these three trends to help your organization actualize better results and end 2020 with a win.</p>

                <p className="sources-text">
                  Sources: <br />
                </p>
                <ol className="sources-text px-md-2 ml-md-1 mt-2 mb-5">
                  <li>"<a href="https://www2.deloitte.com/content/dam/Deloitte/us/Documents/process-and-operations/us-cons-the-true-value-of-customer-experiences.pdf" target="_blank">The True Value of Customer Experiences</a>," Deloitte, 2018</li>
                </ol>

              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CXTrendsToAchieveBusinessGoals;
