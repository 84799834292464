import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import {Waypoint} from "react-waypoint";
import anime from "animejs";
import Carousel from "react-bootstrap/Carousel";
import CallToAction from "../components/Button";
import Layout from "../components/Layout";
import CompanySelectForm from '../components/form-component/CompanySelectForm';

class CXCampaignPreRegistrations extends React.Component {
  state = {
    gbsSectionPlay: false,
    edmSectionPlay: false,
    centerSectionPlay: false,
    lastSectionPlay: false,
  };

  constructor() {
    super();

    const script = document.createElement("script");
    script.async = true;
    script.src = "https://js.chilipiper.com/marketing.js";
    //For head
    document.head.appendChild(script);

    this.fireChilliPiper = this.fireChilliPiper.bind(this);
  }

  componentDidMount() {
    
  }

  async fireChilliPiper() {
    await window.ChiliPiper.getQuery("grammarly", "test_inbound_router", {
      queryVariable: "Email",
    });
  }

  gbsSectionAnime = () => {
    anime({
      targets: ".gbs-image-section .main-sheet2",
      scale: [0, 1],
      duration: 1000,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
    anime({
      targets: ".gbs-image-section .main-sheet",
      scale: [0, 1],
      // translateX: [500, 0],
      // translateY: [-255, 0],
      duration: 1200,
      delay: 1000,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
    anime({
      targets: ".gbs-image-section .main-sheet1",
      scale: [0, 1],
      // translateX: [500, 0],
      // translateY: [-255, 0],
      duration: 1200,
      delay: 1500,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
  };

  edmSectionAnime = () => {
    anime({
      targets: ".edm-mockup-img-section .edm-img1",
      translateX: [-210, 0],
      duration: 2200,
      easing: "easeInOutBack",
      autoplay: false,
    }).play();
    anime({
      targets: ".edm-mockup-img-section .edm-img2",
      translateX: [-330, 0],
      duration: 2200,
      easing: "easeInOutBack",
      autoplay: false,
    }).play();
    anime({
      targets: ".edm-mockup-img-section .edm-img3",
      translateX: [-460, 0],
      duration: 2200,
      easing: "easeInOutBack",
      autoplay: false,
    }).play();
  };

  centerSectionAnime = () => {
    anime({
      targets: "#centralised-img1",
      translateX: [530, 0],
      duration: 1500,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
    anime({
      targets: "#centralised-img2",
      translateX: [-360, 0],
      duration: 1500,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
  };

  lastSectionAnime = () => {
    const timeline = anime
      .timeline({
        duration: 1500,
        easing: "easeInOutSine",
        autoplay: false,
      })
      .add({
        targets: ".why-work-img-collection .oval",
        scale: [0.5, 1],
        autoplay: false,
      })
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets1",
          translateX: [-310, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      ) // relative offset
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets2",
          translateX: [-360, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      )
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets3",
          translateX: [-410, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      ) // relative offset
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets4",
          translateX: [-460, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      );
    timeline.play();
  };

  animateSection = (section) => {
    const {gbsSectionPlay, edmSectionPlay, centerSectionPlay, lastSectionPlay} = this.state;
    switch (section) {
      case "gbsSection":
        if (!gbsSectionPlay) {
          this.gbsSectionAnime();
          this.setState({gbsSectionPlay: true});
        }
        break;
      case "edmSection":
        if (!edmSectionPlay) {
          this.edmSectionAnime();
          this.setState({edmSectionPlay: true});
        }
        break;
      case "centerSection":
        if (!centerSectionPlay) {
          this.centerSectionAnime();
          this.setState({centerSectionPlay: true});
        }
        break;
      case "lastSection":
        if (!lastSectionPlay) {
          this.lastSectionAnime();
          this.setState({lastSectionPlay: true});
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <Layout className="new-home-landing-page">
        <div className="container-fluid px-0">
          <section className="header-section pt-2 pt-md-0">
            <div className="container">
              <div className="row pt-md-5">
                <div className="col-md-5">
                  <h1 className="mb-2">Better communications, brighter connections</h1>
                  <p className="py-3 pr-md-5">Real-time writing suggestions to empower teams of three or more.</p>
                </div>
                <div className="col-md-6 offset-md-1 mt-5 mt-md-0 pt-md-4 pb-3">
                  <div className="form-container position-relative">
                    <div className="Thankyou-Container px-3">
                      <CompanySelectForm  leadSourceInputData="UpskillMoreEfficientCXBlog-PreRegHackingCXEbook" /> 
                    </div>
                  </div>
                  <button ref={button => this.CPbutton = button} className="CilliPiperBtn hiden-btn" onClick={() => this.fireChilliPiper()}>Fire</button>
                  <div className="w-100 text-right position-absolute header-lines d-none d-md-block">
                    <img src={require("../assets/images/lines.png")} alt="" />
                  </div>
                </div>
                <div className="w-100 position-relative header-dots">
                  <img src={require("../assets/images/header-dots.png")} alt="" />
                </div>
              </div>
            </div>
          </section>
        </div>
          <section className="section-grey testimonial-1 mt-4 mt-md-0">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-7">
                  <div className="p-2 align-self-stretch mt-4 text-sm-left text-left">
                    <blockquote className="blockquote">
                      <img className="inverted-comma" src={require("../assets/images/double-inverted-comma.png")} alt="" />
                      <p className="blockquote-body">Grammarly allows us to move quickly while not sacrificing accuracy and professionalism, which is important to building something big.”</p>
                      <footer className="blockquote-footer-1">
                        <cite>
                          <span className="author mb-2">Kati Voluntine</span>
                          <span className="position">Head of People Operations, BuildingConnected</span>
                        </cite>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </section>
         
      </Layout>
    );
  }
}

export default CXCampaignPreRegistrations;
