import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";

class ReCAPTCHAExecComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    verifyCaptcha(value) {
        if(value != ""){
            $('#FormSubmitBtn').removeClass('d-none');
            $('#FormSubmitBtn').addClass('d-flex');
        }
        else{
            $('#FormSubmitBtn').removeClass('d-flex');
            $('#FormSubmitBtn').addClass('d-none');
        }
    }

    render() { 
        return (
            <ReCAPTCHA 
            sitekey="6Lcm_7EZAAAAAHKVOJZw7F0Hgg-H-FBRGTNwiPue" 
            render="explicit" 
            onChange={this.verifyCaptcha}
            />
        );
    }
}
 
export default ReCAPTCHAExecComponent;