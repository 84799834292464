import React from "react";
import CallToAction from "../../components/Button";
import $ from "jquery";
import Layout from "../../components/Layout";
import {Helmet} from "react-helmet";

import CompanySelectForm from '../../components/form-component/CompanySelectForm';

class VoxStyleGuide extends React.Component {
  state = {
    url: "",
  };

  constructor() {
    super();

    this.submitForm = this.submitForm.bind(this);
    this.scrollToRef = this.scrollToRef.bind(this);
  }

  showForm(event) {
    if (event.target.value != "1-100") {
      $(".Form-Intro-Container").hide();
      $(".form-container .form-elements").show();
    } else if (event.target.value == "1-100") {
      $(".form-container .initialBtn").hide();
      $(".form-container .selectedBtn").show();
      $(".form-container .form-elements").hide();
      $(".Form-Intro-Container").show();
    } else {
      $(".form-container .initialBtn").show();
      $(".form-container .selectedBtn").hide();
      $(".form-container .form-elements").hide();
      $(".Form-Intro-Container").show();
    }

    $(".connect-form").hide(); 
  }

  submitForm() {
    if ($("#pardot-form")[0].checkValidity()) {
      var leadData = JSON.parse(JSON.stringify($("#pardot-form").serializeArray()));
      var leadObject = {};
      for (let i = 0; i < leadData.length; i++) {
        leadObject[leadData[i].name] = leadData[i].value;
      }

      this.setState({
        url:
          "https://grammarly-enterprise.com/thankyou?Firstname=" +
          leadObject.first_name +
          "&Lastname=" +
          leadObject.last_name +
          "&Email=" +
          leadObject.email +
          "&Phone=" +
          leadObject.phone +
          "&Company_Size__c=" +
          leadObject.companySize +
          "&Title=" +
          leadObject.title +
          "&Company=" +
          leadObject.company +
          "",
      });
    } else {
      $("#pardot-form")[0].reportValidity();
      console.log("Invalid");
    }
  }

  scrollToRef(){
    window.scrollTo(0, 0);
  }

  showConnectForm(){
    $(".connect-form").show(); 
  }

  render() {
    return (
      <Layout className="new-home-landing-page vox-style-guide-page">
        <div className="container-fluid px-0">
          <div className="container header-banner" id="header-banner-form">
            <div className="row mt-5 pt-md-5">
              <div className="col-md-6">
                <h1>A strong brand needs consistent writing</h1> 
                <p className="">Help your whole team capture your company’s<br class="d-none d-xl-block" /> unique voice and style.</p>
                {/* <CallToAction to="https://www.grammarly.com/business/" className="green-btn green-btn-ggb mt-4" useTargetClass="yes">Get Grammarly Business</CallToAction> */}
              </div>
              <div className="col-md-6 mt-0 pb-3 form-container"  >
                <CompanySelectForm />
              </div>
            </div>

            <div className="row sponsor-section-container justify-content-center justify-content-md-start join-customer-section">
              <div className="col-12 col-md-auto sponsor-lable text-center text-md-left mb-4 mb-md-0">
                <h6 className="pt-0 pt-md-2">Join our 10,000+ customers:</h6>
              </div>
              <div className="col-4 col-md-auto">
                <img src={require("../../assets/images/newHome/Cisco@2x.png")} className="sponsor-logos" />
              </div>
              <div className="col-4 col-md-auto">
                <img src={require("../../assets/images/newHome/A.png")} className="sponsor-logos" />
              </div>
              <div className="col-4 col-md-auto">
                <img src={require("../../assets/images/newHome/Dell@2x.png")} className="sponsor-logos" />
              </div>
              <div className="col-6 col-md-auto second-row-img mt-md-0 text-center text-md-left">
                <img src={require("../../assets/images/newHome/Expedia@2x.png")} className="sponsor-logos" />
              </div>
              <div className="col-6 col-md-auto second-row-img mt-md-0 text-center">
                <img src={require("../../assets/images/newHome/Expensify@2x.png")} className="sponsor-logos" />
              </div>
            </div>
          </div>

          {/* Block 1: Style guide */}
          <div className="grey-container-gradient style-guide-section">
            <div className="container section-block">
              <div className="row py-5">
                
                <div className="col-md-5 col-xl-4 pt-4">
                  <img className="section-icon mb-5" src={require("../../assets/images/vox-style-guide/icon-01.svg")} alt="" />
                  <h4>
                    <span className="green-text">Style guide</span>
                    <br />
                    Keep your team aligned with a shared library of words, terms, and phrases specific to your organization.
                  </h4>
                </div>

                <div className="col-md-6 offset-md-1 col-xl-6 offset-xl-2 px-md-0  align-self-center img-section">
                  <img src={require("../../assets/images/vox-style-guide/content-1-1.png")} className="img-fluid edge-stretch-mob" alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* Block 2: Names matter */}
          <div className="container section-block">
            <div className="row py-5">
              <div className="col-md-7 ">
                <img src={require("../../assets/images/vox-style-guide/content-2-1.png")} className="img-fluid d-none d-md-block" alt="" />
              </div>

              <div className="col-md-4 offset-md-1 pt-4">
                <img className="section-icon mb-5" src={require("../../assets/images/vox-style-guide/icon-02.svg")} alt="" />
                <h4 className="mb-0">
                  <span className="green-text">Names matter</span>
                  <br />
                  Make sure your company, product,<br class="d-none d-xl-block" /> and trademark names are properly spelled<br class="d-none d-xl-block" /> and capitalized.
                </h4>
                

                <img src={require("../../assets/images/vox-style-guide/content-7.png")} className="img-fluid d-block d-md-none edge-stretch-mob" alt="" />

                <img className="section-icon section-icon-2" src={require("../../assets/images/vox-style-guide/icon-03.svg")} alt="" />
                <h4>
                  <span className="green-text">Replace acronyms<br class="d-none d-xl-block" /> and jargon</span>
                  <br />
                  Create rules to help<br class="d-none d-xl-block" /> your team avoid using confusing acronyms or jargon with customers.
                </h4>
                <img src={require("../../assets/images/vox-style-guide/content-8.png")} className="img-fluid d-block d-md-none edge-stretch-mob" alt="" />

                <button onClick={() => this.scrollToRef()} className="green-btn mb-0">Learn more</button>
                {/* <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mb-0 mx-auto green-btn-auto-mb">
                  Learn more
                </CallToAction> */}
              </div>
            </div>
          </div>

          {/* Block 3: Custom style guide rules */}
          <div className="grey-container-gradient">
            <div className="container section-block">
              <div className="row pt-5">
                
                <div className="col-md-7 align-self-center">
                  <img src={require("../../assets/images/vox-style-guide/content-3.png")} className="img-fluid d-none d-md-block" alt="" />
                </div>

                <div className="col-md-4 offset-md-1 pt-4">
                  <img className="section-icon mb-5" src={require("../../assets/images/vox-style-guide/icon-04.svg")} alt="" />
                  <div className="administration-tools-container ">ADMINISTRATION TOOLS</div>
                  <h4>
                    <span className="green-text">Custom style guide rules</span>
                    <br />
                    Rules are easy to create and update, so keeping your style guide up-to-date is a snap.
                  </h4>
                  <img src={require("../../assets/images/vox-style-guide/content-9.png")} className="img-fluid d-bock d-md-none" alt="" />
                </div>

              </div>
            </div>
          </div>

          {/* Block 4: Reporting and statistics */}
          <div className="container section-block reporting-section ">
            <div className="row py-5">
              
              <div className="col-md-4 pt-4">
                <img className="section-icon mb-5" src={require("../../assets/images/vox-style-guide/icon-05.svg")} alt="" />
                <div className="administration-tools-container ">ADMINISTRATION TOOLS</div>
                <h4>
                  <span className="green-text">Reporting and statistics</span>
                  <br />
                  Get continuous feedback and make adjustments painlessly to fit your team’s needs.
                </h4>

                <button onClick={() => this.scrollToRef()} className="green-btn mb-0">Learn more</button>
                {/* <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-5 mb-0 mx-auto">
                  Learn more
                </CallToAction> */}
              </div>

              <div className="col-md-6 offset-md-2 align-self-center img-stretch-edge">
                <img src={require("../../assets/images/vox-style-guide/content-4-top.png")} className="img-fluid top-img d-none d-md-block" alt="" />
                <img src={require("../../assets/images/vox-style-guide/content-10.png")} className="img-fluid top-img d-block d-md-none" alt="" />
                <div className="bottom-img-container">
                  <img src={require("../../assets/images/vox-style-guide/content-4.png")} className="img-fluid bottom-img" alt="" />
                </div>
              </div>

            </div>
          </div>
          
          {/* Block 5: Brand voice and tone */}
          <div className="container section-block">
            <div className="row py-5">
              
              <div className="col-md-7">
                <img src={require("../../assets/images/vox-style-guide/content-5.png")} className="img-fluid d-none d-md-block" alt="" />
              </div>

              <div className="col-md-4 offset-md-1 pt-4 pt-md-0">
                <img className="section-icon mb-5" src={require("../../assets/images/vox-style-guide/icon-06.svg")} alt="" />
                <div className="coming-soon-container">COMING SOON</div>
                <h4>
                  <span className="green-text">Brand voice and tone</span>
                  <br />
                  Convey your brand tone everywhere.
                </h4>

                <ul className="tick-grey-ul">
                  <li>Your website</li>
                  <li>Press releases</li>
                  <li>Sales emails</li>
                  <li>Support tickets</li>
                </ul>
                
              </div>

            </div>
          </div>
          
          {/* Get Better Results, Everywhere They Write */}
          <div className="grey-container sponsor-section-container mt-4">
            <div className="container">
              <div className="row justify-content-center ">
                <div className="col-md-10 text-center">
                  <h2 className="heading-type-2">Get Better Results, Everywhere They Write</h2>
                  <p className="px-md-5">Quality writing is more than just mechanics. Grammarly Business goes deeper with real-time suggestions for readability, word choice, and tone.</p>
                </div>
              </div>
              <div className="row justify-content-around">
                <div className="col-md-3 text-center text-md-left">
                  <img src={require("../../assets/images/vox-style-guide/marketing-icon.svg")} alt="" className="image-text-block" />
                  <h3 className="service-title">Marketing</h3>
                  <h5 className="d-none d-md-block">Craft messaging that excites and engages your customers.</h5>
                  <div className="service-sub-title">Works with:</div>
                  <div className="row justify-content-around pt-3 pt-md-0 service-sub-img">
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/vox-style-guide/customer-icon/medium.svg")} alt="" />
                      <div className="services-sponsor-image-text">Medium</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/vox-style-guide/customer-icon/facebook.svg")} alt="" />
                      <div className="services-sponsor-image-text">Facebook</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images second-row-img" src={require("../../assets/images/vox-style-guide/customer-icon/twitter.svg")} alt="" />
                      <div className="services-sponsor-image-text">Twitter</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images second-row-img" src={require("../../assets/images/vox-style-guide/customer-icon/wordpress.svg")} alt="" />
                      <div className="services-sponsor-image-text">Wordpress</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                  </div>
                </div>
                <div className="col-md-3 mt-5 mt-md-0 pt-4 pt-md-0 text-center text-md-left">
                  <img src={require("../../assets/images/vox-style-guide/sales-icon.svg")} alt="" className="image-text-block" />
                  <h3 className="service-title ">Sales</h3>
                  <h5 className="d-none d-md-block">
                    Write polished, compelling emails to close your deals.
                    <br />
                  </h5>
                  <div className="service-sub-title ">Works with:</div>
                  <div className="row justify-content-around pt-3 pt-md-0 service-sub-img">
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/vox-style-guide/customer-icon/gmail.svg")} alt="" />
                      <div className="services-sponsor-image-text">Gmail</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/vox-style-guide/customer-icon/linkedIn.svg")} alt="" />
                      <div className="services-sponsor-image-text">LinkedIn</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images second-row-img" src={require("../../assets/images/vox-style-guide/customer-icon/salesforce.svg")} alt="" />
                      <div className="services-sponsor-image-text">Salesforce</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images second-row-img" src={require("../../assets/images/vox-style-guide/customer-icon/gdocs.svg")} alt="" />
                      <div className="services-sponsor-image-text">Google Docs</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                  </div>
                </div>
                
                <div className="col-md-3 mt-5 mt-md-0 pt-4 pt-md-0 text-center text-md-left">
                  <img src={require("../../assets/images/vox-style-guide/support-icon.svg")} alt="" className="image-text-block" />
                  <h3 className="service-title ">Support</h3>
                  <h5 className="d-none d-md-block">Respond quickly with clear, polite, and friendly resolutions.</h5>
                  <div className="service-sub-title ">Works with:</div>
                  <div className="row justify-content-around pt-3 pt-md-0 service-sub-img">
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/vox-style-guide/customer-icon/zendesk.svg")} alt="" />
                      <div className="services-sponsor-image-text">Zendesk</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/vox-style-guide/customer-icon/freshdesk.svg")} alt="" />
                      <div className="services-sponsor-image-text">freshdesk</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images second-row-img" src={require("../../assets/images/vox-style-guide/customer-icon/slack.svg")} alt="" />
                      <div className="services-sponsor-image-text">Slack</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images second-row-img" src={require("../../assets/images/vox-style-guide/customer-icon/zohodesk.svg")} alt="" />
                      <div className="services-sponsor-image-text">Zoho Desk</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Purple box container */}
          <div className="purple-container">
            <div className="container ">
              <div className="row ">
                <div className="col-md-8">
                  <h2>Help Your Team Do Better Work</h2>
                  <p className="">Get started today and instantly elevate the communication <br className="d-none d-xl-block" /> skills of your entire team.</p>
                  <button onClick={() => this.scrollToRef()} className="green-btn mb-0">Get Grammarly Business</button>
                </div>
                <div className="col-md-4 img-section">
                  <img src={require("../../assets/images/vox-style-guide/content-6.png")} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </Layout>
    );
  }
}

export default VoxStyleGuide;
