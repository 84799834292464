import React from 'react';

const LinkedinPixelsConversion = () => {
    return (  
        <noscript>
            <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=429908&conversionId=2003178&fmt=gif" />
        </noscript>
    );
}
 
export default LinkedinPixelsConversion;