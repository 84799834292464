import React from "react"
import { Link } from "react-router-dom"
import ReactGA from "react-ga";
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent";
import logo from '../assets/images/Grammarly-Business-Logo.svg'
import { useHistory } from "react-router-dom";
import CallToAction from '../components/Button';
import Pdf from '../assets/pdf/styleguide-custom-rules.pdf';

const Footer = ({ siteTitle, landing }) => {
  
  const history = useHistory();
  const registerGAPageViewCustom = (e) => {
    e.preventDefault();
    
    var redirectLocation = "/";
    if( e.target.getAttribute('href') ){
      redirectLocation = e.target.getAttribute('href');
    }
    redirectLocation = redirectLocation + window.location.search;
    
    ReactGA.pageview(redirectLocation);
    history.push( redirectLocation );
  }

  return (
    <>
      <footer className="footer section-dark-grey py-5 text-color-grey ">
        <div className="container">
          {landing ? (
            <div className="row align-items-center">
              <div className="col-12 col-lg-3 text-small text-center text-lg-left pb-5 pb-lg-0">
                2019 &copy; Grammarly Inc.
              </div>
              <div className="col-12 col-lg-9 text-small">
                1.&nbsp;<ReactGA.OutboundLink eventLabel="Ebook-Footer" className="text-color-grey" to="https://allwork.space/2019/05/coworking-is-the-new-normal-and-these-stats-prove-itt/">https://allwork.space/2019/05/coworking-is-the-new-normal-and-these-stats-prove-itt/</ReactGA.OutboundLink><br />
                2.&nbsp;<ReactGA.OutboundLink eventLabel="Ebook-Footer" className="text-color-grey" to="https://withoutbullshit.com/blog/new-research-on-business-writing-infographic-and-report">https://withoutbullshit.com/blog/new-research-on-business-writing-infographic-and-report</ReactGA.OutboundLink><br />
                3.&nbsp;<ReactGA.OutboundLink eventLabel="Ebook-Footer" className="text-color-grey" to="https://www.washingtonpost.com/blogs/post-leadership/post/how-much-time-you-really-spend-emailing-at-work/2012/07/31/gJQAI50sMX_blog.html">https://www.washingtonpost.com/blogs/post-leadership/post/how-much-time-you-really-spend-emailing-at-work/2012/07/31/gJQAI50sMX_blog.html</ReactGA.OutboundLink><br />
                4.&nbsp;<ReactGA.OutboundLink eventLabel="Ebook-Footer" className="text-color-grey" to="https://www.thedailybeast.com/bad-writing-costs-businesses-billions">https://www.thedailybeast.com/bad-writing-costs-businesses-billions</ReactGA.OutboundLink>
              </div>
            </div>
          ) : (
            <>
              <div className="row ">
                <div className="col-md footer-gb">
                  <h4 className="heading-type-4">Grammarly Business</h4>
                  <Link to="/yourteam/customer-experience" onClick={registerGAPageViewCustom} className="fservteams">Grammarly for Customer Experience Teams</Link>
                  <Link to="/yourteam/hr" onClick={registerGAPageViewCustom} className="fhrteams">Grammarly for HR Teams</Link>
                  <Link to="/yourteam/marketing" onClick={registerGAPageViewCustom} className="fmarteams">Grammarly for Marketing
                    Teams</Link>
                  <Link to="/yourteam/sales" onClick={registerGAPageViewCustom} className="fsalteams">Grammarly for Sales
                    Teams</Link>
                  {/* <Link to="/casestudyhome" onClick={registerGAPageViewCustom} className="fservteams">Case Study Page</Link> */}
                  {/* <Link to="/chilipipertest" onClick={registerGAPageViewCustom} className="fservteams">Chili Piper Test</Link> */}
                  {/* <Link to="/styleguide?utm_source=google" onClick={registerGAPageViewCustom} className="fservteams">UTM Test</Link> */}
                  {/* <Link to="/blog/3-cx-trends-to-achieve-business-goals" className="fservteams">3 CX Trends That Will Help You Achieve Your Business Goals</Link> */}
                </div>
                <div className="col-md footer-resource mt-4 mt-md-0">
                  <h4 className="heading-type-4">Resources</h4>
                  {/* <ReactGA.OutboundLink eventLabel="Ebook-Footer" to="https://www.grammarly.com/blog/category/product" target="_blank">Blog</ReactGA.OutboundLink> */}
                  {/* <a href="https://www.grammarly.com/blog/category/product" target="_blank" rel="noopener noreferrer">Blog</a> */}
                  <CallToAction
                  to="https://www.grammarly.com/blog/category/product"
                  className=""
                  useTargetClass="yes"
                  >
                    Blog
                  </CallToAction>

                  <Link to="/ebook" onClick={registerGAPageViewCustom} className="febook">Workforce Success eBook</Link>
                  <Link to="/customer-experience-ebook" onClick={registerGAPageViewCustom} className="febook">Customer Experience eBook</Link>

                  {/* <ReactGA.OutboundLink eventLabel="Ebook-Footer" to="https://www.grammarly.com/press" target="_blank">Newsroom</ReactGA.OutboundLink> */}
                  {/* <a href="https://www.grammarly.com/press" target="_blank" rel="noopener noreferrer">Newsroom</a> */}
                  <CallToAction
                  to="https://www.grammarly.com/press"
                  className=""
                  useTargetClass="yes"
                  >
                    Newsroom
                  </CallToAction>

                  {/* <ReactGA.OutboundLink eventLabel="Ebook-Footer" to="https://www.grammarly.com/privacy-policy" target="_blank">Privacy Policy</ReactGA.OutboundLink> */}
                  {/* <a href="https://www.grammarly.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> */}
                  <CallToAction
                  to="https://www.grammarly.com/privacy-policy"
                  className=""
                  useTargetClass="yes"
                  >
                    Privacy Policy
                  </CallToAction>

                  {/* <ReactGA.OutboundLink eventLabel="Ebook-Footer" to="https://www.grammarly.com/press/research/docs/2019-state-of-workplace-email.pdf" target="_blank">State of Email Report</ReactGA.OutboundLink> */}
                  {/* <a href="https://www.grammarly.com/press/research/docs/2019-state-of-workplace-email.pdf" target="_blank" rel="noopener noreferrer">State of Email Report</a> */}
                  <CallToAction
                  to="https://www.grammarly.com/press/research/docs/2019-state-of-workplace-email.pdf"
                  className=""
                  useTargetClass="yes"
                  >
                    State of Email Report
                  </CallToAction>
                  <CallToAction
                  to="/styleguide-custom-rules.pdf"
                  className=""
                  useTargetClass="yes"
                  >
                    Custom style rules
                  </CallToAction>

                </div>
                <div className="col-md footer-logo mt-4 mt-md-0">
                  <img className="img-fluid rounded" src={logo} alt="Grammarly Business logo" />
                  {/* <ReactGA.OutboundLink eventLabel="Ebook-Footer" to="https://grammarly.com/contact/sales?source=enterprise" className="btn btn-primary btn-small-cm text-uppercase fgetstart" target="_blank">GET STARTED</ReactGA.OutboundLink> */}
                  {/* <a href="https://grammarly.com/contact/sales?source=enterprise" className="btn btn-primary btn-small-cm text-uppercase fgetstart" target="_blank" rel="noopener noreferrer">GET STARTED</a> */}
                  <CallToAction
                  to="https://www.grammarly.com/upgrade/business?utm_medium=internal&utm_source=enterprise&utm_campaign=enterprise-site"
                  className="btn btn-primary btn-small-cm text-uppercase fgetstart"
                  useTargetClass="yes"
                >
                  GET STARTED
                </CallToAction>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <span className="copy-tm">2021 &copy; Grammarly Inc.</span>
                </div>
              </div>
            </>
          )}
        </div>
      </footer>
      <CookieConsent
        location="bottom"
        buttonText={<span>x</span>}
        disableStyles
        containerClasses="cookie-notification-alert"
        cookieName="hasUserAcceptedGrammarlyCookie"
        contentClasses="alert alert-dismissible fade show text-dark"
        buttonClasses="close"
        expires={10}
      >
        {/* <span>This website uses cookies to make Grammarly work for you. By using this site, you agree to our <ReactGA.OutboundLink eventLabel="Cookie-Consent" to="https://www.grammarly.com/cookie-policy" className="" target="_blank">cookie policy</ReactGA.OutboundLink></span> */}
        <span>This website uses cookies to make Grammarly work for you. By using this site, you agree to our <a href="https://www.grammarly.com/cookie-policy" className="" target="_blank" rel="noopener noreferrer">cookie policy</a></span>
      </CookieConsent>
    </>
  );
} 

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {
  siteTitle: ``,
  landing: false
};

export default Footer
