import React from "react";
import {Link} from "react-router-dom";
// import Lottie from 'react-lottie'
import {Waypoint} from "react-waypoint";
import anime from "animejs";
import Carousel from "react-bootstrap/Carousel";
import Layout from "../components/Layout";
import CallToAction from "../components/Button";
// import animationData from '../assets/animate'
import ReactGA from "react-ga";
import {withRouter} from "react-router";
import $ from "jquery";
import CountryListSelect from "../components/CountryListSelect";
import CompanySelectForm from "../components/form-component/CompanySelectForm";
import AllTeamsCardSection from "../components/AllTeamsCardSection";

// const defaultOptions = {
//   loop: false,
//   autoplay: true,
//   animationData: animationData,
//   renderer: 'svg/canvas/html',
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice'
//   }
// };

class IndexPage extends React.Component {
  constructor() {
    super();
    this.UTMSource = "";
    this.UTMSourceFlag = false;
    this.getURLQuery();

    // this.submitForm = this.submitForm.bind(this);
  }
  state = {
    gbsSectionPlay: false,
    edmSectionPlay: false,
    centerSectionPlay: false,
    lastSectionPlay: false,
    url: "",
  };

  gbsSectionAnime = () => {
    anime({
      targets: ".gbs-image-section .main-sheet2",
      scale: [0, 1],
      duration: 1000,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
    anime({
      targets: ".gbs-image-section .main-sheet",
      scale: [0, 1],
      // translateX: [500, 0],
      // translateY: [-255, 0],
      duration: 1200,
      delay: 1000,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
    anime({
      targets: ".gbs-image-section .main-sheet1",
      scale: [0, 1],
      // translateX: [500, 0],
      // translateY: [-255, 0],
      duration: 1200,
      delay: 1500,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
  };

  edmSectionAnime = () => {
    anime({
      targets: ".edm-mockup-img-section .edm-img1",
      translateX: [-210, 0],
      duration: 2200,
      easing: "easeInOutBack",
      autoplay: false,
    }).play();
    anime({
      targets: ".edm-mockup-img-section .edm-img2",
      translateX: [-330, 0],
      duration: 2200,
      easing: "easeInOutBack",
      autoplay: false,
    }).play();
    anime({
      targets: ".edm-mockup-img-section .edm-img3",
      translateX: [-460, 0],
      duration: 2200,
      easing: "easeInOutBack",
      autoplay: false,
    }).play();
  };

  centerSectionAnime = () => {
    anime({
      targets: "#centralised-img1",
      translateX: [530, 0],
      duration: 1500,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
    anime({
      targets: "#centralised-img2",
      translateX: [-360, 0],
      duration: 1500,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
  };

  lastSectionAnime = () => {
    const timeline = anime
      .timeline({
        duration: 1500,
        easing: "easeInOutSine",
        autoplay: false,
      })
      .add({
        targets: ".why-work-img-collection .oval",
        scale: [0.5, 1],
        autoplay: false,
      })
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets1",
          translateX: [-310, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      ) // relative offset
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets2",
          translateX: [-360, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      )
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets3",
          translateX: [-410, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      ) // relative offset
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets4",
          translateX: [-460, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      );
    timeline.play();
  };

  animateSection = (section) => {
    const {gbsSectionPlay, edmSectionPlay, centerSectionPlay, lastSectionPlay} = this.state;
    switch (section) {
      case "gbsSection":
        if (!gbsSectionPlay) {
          this.gbsSectionAnime();
          this.setState({gbsSectionPlay: true});
        }
        break;
      case "edmSection":
        if (!edmSectionPlay) {
          this.edmSectionAnime();
          this.setState({edmSectionPlay: true});
        }
        break;
      case "centerSection":
        if (!centerSectionPlay) {
          this.centerSectionAnime();
          this.setState({centerSectionPlay: true});
        }
        break;
      case "lastSection":
        if (!lastSectionPlay) {
          this.lastSectionAnime();
          this.setState({lastSectionPlay: true});
        }
        break;
      default:
        break;
    }
  };

  // history = useHistory();
  registerGAPageViewCustom = (e) => {
    const {history} = this.props;

    e.preventDefault();

    var redirectLocation = "/";
    if (e.target.getAttribute("href")) {
      redirectLocation = e.target.getAttribute("href");
    }
    redirectLocation = redirectLocation + window.location.search;

    ReactGA.pageview(redirectLocation);
    history.push(redirectLocation);
  };

  showForm(event) {
    if (event.target.value != "1-100") {
      $(".Form-Intro-Container").hide();
      $(".form-container .form-elements").show();
    } else if (event.target.value == "1-100") {
      $(".form-container .initialBtn").hide();
      $(".form-container .selectedBtn").show();
      $(".form-container .form-elements").hide();
      $(".Form-Intro-Container").show();
    } else {
      $(".form-container .initialBtn").show();
      $(".form-container .selectedBtn").hide();
      $(".form-container .form-elements").hide();
      $(".Form-Intro-Container").show();
    }
    $(".connect-form").hide();
  }

  // submitForm() {
  //   if ($("#pardot-form")[0].checkValidity()) {
  //     var leadData = JSON.parse(JSON.stringify($("#pardot-form").serializeArray()));
  //     var leadObject = {};
  //     for (let i = 0; i < leadData.length; i++) {
  //       leadObject[leadData[i].name] = leadData[i].value;
  //     }

  //     this.setState({url: "https://grammarly-enterprise.com/thankyou?Firstname=" + leadObject.first_name + "&Lastname=" + leadObject.last_name + "&Email=" + leadObject.email + "&Phone=" + leadObject.phone +"&Company_Size__c=" + leadObject.companySize + "&Title=" + leadObject.title + "&Company="+ leadObject.company +""});

  //   } else {
  //     $("#pardot-form")[0].reportValidity();
  //     console.log("Invalid");
  //   }
  // }

  getURLQuery() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("utm_source")) {
      this.UTMSource = urlParams.get("utm_source");
      this.UTMSourceFlag = true;
      console.log(this.UTMSource);
    } else {
      this.UTMSource = "";
    }
  }

  showConnectForm() {
    $(".connect-form").show();
  }

  scrollToRef() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout className="ml-gm-page">
        <div className="header-testimonial-section">
          <section className="header-section ml-home-header-section" style={{position: "relative"}}>
            <div className="container ">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col">
                      <h1 className="card-title heading-type-1">Better communications, brighter connections</h1>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="p-type-1">Real-time writing suggestions to empower teams of three or more.</p>
                      <div>
                        <CallToAction to="https://www.grammarly.com/upgrade/business?utm_medium=internal&utm_source=enterprise&utm_campaign=enterprise-site" eType="See-Demo" eName="service-team" eLabel="enterprise-service-team-see-demo" className="serv1seedem">
                          Get in touch
                        </CallToAction>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mb-4 mb-md-0">
                    <div className="col">
                      <CallToAction to="https://grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta banner-Btn m1reqdem" eName="service-team" eLabel="enterprise-service-teams-demo">
                        Get in touch
                      </CallToAction>
                    </div>
                  </div> */}
                </div>
                {/* <div className="col-md-6 col-sm-12 lottie-section-holder p-0 mt-5 mt-md-0"> */}
                {/* <div className="lottie-animation-holder">
                    <div id="lottie"> */}
                {/* <Lottie options={defaultOptions} resizeMode="center"/> */}
                <div className="col-md-6">
                  {/* <div className="w-100 text-right position-absolute">
                    <img className="header-lines" src={require("../assets/images/lines.png")} alt="" />
                  </div>
                  <div className="form-container pt-4 pb-3 offset-md-1 position-relative">
                    <CompanySelectForm />
                  </div> */}
                </div>
                {/* <div className="w-100 position-relative header-dots">
                  <img src={require("../assets/images/header-dots.png")} alt="" />
                </div> */}
              </div>
            </div>
          </section>
          <section className="section-grey testimonial-1 mt-4 mt-md-0">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-7">
                  <div className="p-2 align-self-stretch mt-4 text-sm-left text-left">
                    <blockquote className="blockquote">
                      <img className="inverted-comma" src={require("../assets/images/double-inverted-comma.png")} alt="" />
                      <p className="blockquote-body">Grammarly allows us to move quickly while not sacrificing accuracy and professionalism, which is important to building something big.”</p>
                      <footer className="blockquote-footer-1">
                        <cite>
                          <span className="author mb-2">Kati Voluntine</span>
                          <span className="position">Head of People Operations, BuildingConnected</span>
                        </cite>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="all-teams-section ">
          <div className="container continer-show-dots all-teams-header">
            <div className="row text-center">
              <div className="col">
                <h2 className="heading-type-2">See how Grammarly Business helps you perform better</h2>
              </div>
            </div>
          </div>

          <AllTeamsCardSection />
        </section>
        <section className="map-holder-section">
          <div className="container">
            <div className="row ">
              <div className="col-sm-5 align-self-center text-center text-md-left">
                <h2 className="heading-type-2 heading-type-2-white">Recognized for excellence, used by leading companies</h2>
              </div>
              <div className="col-sm-7 ">
                <div className="d-flex justify-content-end">
                  <div className="fast-company-section">
                    <div className="row">
                      <div className="col-4 mic-img-section align-self-center">
                        <img className="img-fluid" src={require("../assets/images/mic_logo.png")} alt="MIC Logo" />
                      </div>
                      <div className="col-8 fc-img-section">
                        <img className="img-fluid" src={require("../assets/images/FC_logo.png")} alt="FC Logo" />
                        <p className="p-type-2 pr-3 pr-md-0">One of Fast Company’s Most Innovative Companies in AI</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <div className="logo-collection">
                    <div className="row">
                      <div className="col-6 col-lg-auto lc-cisco text-center text-md-left">
                        <img className="img-fluid" src={require("../assets/images/cisco.png")} alt="Cisco logo" />
                      </div>
                      <div className="col-6 col-lg-auto lc-dell text-center text-md-left">
                        <img className="img-fluid ml-lg-2" src={require("../assets/images/dell.png")} alt="Dell logo" />
                      </div>
                      <div className="col-6 col-lg-auto lc-expedia text-center text-md-left">
                        <img className="img-fluid ml-lg-2 mt-md-3 mt-lg-0" src={require("../assets/images/expedia.png")} alt="Expedia logo" />
                      </div>
                      <div className="col-6 col-lg-auto lc-expensify ">
                        <img className="img-fluid ml-lg-3 mt-md-3 mt-lg-0" src={require("../assets/images/expensify-logo_one-color.png")} alt="Expensify logo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-grey beyond-grammar-section">
          <div className="container text-center">
            <div className="row">
              <div className="col-sm">
                <h2 className="heading-type-2">Beyond Grammar</h2>
              </div>
            </div>
          </div>

          <div className="container bgs-text-img-section bgs-gb">
            <div className="row">
              <div className="col-md-6 col-lg-6 ">
                <Waypoint onEnter={() => this.animateSection("gbsSection")} topOffset="70%">
                  <div id="gbsSection" className="gbs-image-section" style={{overflow: "hidden", position: "relative", width: "612px", marginLeft: "-136px", height: "412px"}}>
                    <img className="" src={require("../assets/images/beyond.png")} style={{opacity: "0"}} alt="" />
                    <img className="main-background" src={require("../assets/images/goal-based-suggestion.png")} alt="Goal based" style={{position: "absolute", top: "0", right: "0"}} />
                    <img className="main-sheet" src={require("../assets/images/goal-based-suggestion1.png")} alt="Goal based" style={{position: "absolute", top: "79px", width: "216px", left: "335px"}} />
                    <img className="main-sheet1" src={require("../assets/images/goal-based-suggestion2.png")} alt="Goal based" style={{position: "absolute", top: "142px", width: "130px", right: "54px"}} />
                    <img className="main-sheet2" src={require("../assets/images/goal-based-suggestion3.png")} alt="Goal based" style={{position: "absolute", top: "88px", right: "30px", width: "121px"}} />
                  </div>
                </Waypoint>
              </div>
              <div className="col-md-6 col-lg-5 offset-lg-1 bgs-text-section">
                <h3 className="heading-type-3">Goal-based suggestions</h3>
                <p className="pr-md-1">
                  Grammarly processes writing in real time, as your employees type. Sophisticated AI-powered algorithms offer comprehensive improvements for spelling and grammar as well as in-depth suggestions for improving clarity, readability, word choice, writing style, and tone.
                </p>
                <CallToAction to="https://www.grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta d-none d-md-inline-block m6reqdem" eName="service-team" eLabel="enterprise-service-teams-demo">
                  Request a Demo
                </CallToAction> 
                {/* <a href="#"  
                className="btn btn-primary btn-talk btn-cta d-none d-md-inline-block m6reqdem"
                onClick={ (e) => {e.preventDefault();this.scrollToRef();} } >Get in touch</a> */}
                {/* <Link to="/get-in-touch" onClick={this.registerGAPageViewCustom} className="btn btn-primary btn-talk btn-cta d-none d-md-inline-block m6reqdem">
                  Request a Demo
                </Link> */}
              </div>
            </div>
          </div>

          <div className="container bgs-text-img-section bgs-pa ">
            <div className="row">
              <div className="col-md-6 col-lg-5 offset-lg-1 order-md-2 continer-show-dots edm-mockup-img-section">
                <Waypoint onEnter={() => this.animateSection("edmSection")} topOffset="70%">
                  <div id="edmAnimation" className="edmAnimationSection" style={{width: "463px", overflow: "hidden", position: "relative", paddingRight: "35px"}}>
                    <img className="" src={require("../assets/images/edm_mockup3-1.png")} style={{opacity: "0"}} alt="" />
                    <img className="edm-img1" src={require("../assets/images/edm1.png")} alt="Edm mockup" style={{width: "218px", position: "absolute", left: "0", bottom: "0"}} />
                    <img className="edm-img2" src={require("../assets/images/edm2.png")} alt="Edm mockup" style={{width: "218px", position: "absolute", bottom: "30px", left: "118px"}} />
                    <img className="edm-img3" src={require("../assets/images/edm3.png")} alt="Edm mockup" style={{width: "218px", position: "absolute", right: "35px", top: "0"}} />
                  </div>
                </Waypoint>
              </div>
              <div className="col-md-6 col-lg-6 bgs-text-section order-md-1">
                <h3 className="heading-type-3">Personalized assistance</h3>
                <p className="pr-md-5">
                  Grammarly offers detailed explanations along with every suggestion, so your team can up-level their writing skills as they go about their usual responsibilities. Each team member receives a personalized weekly report highlighting where their writing is strong and where it could be
                  stronger.
                </p>
                <CallToAction to="https://www.grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta d-none d-md-inline-block m7reqdem" eName="service-team" eLabel="enterprise-service-teams-demo">
                  Request a Demo
                </CallToAction>
                {/* <Link to="/get-in-touch" onClick={this.registerGAPageViewCustom} className="btn btn-primary btn-talk btn-cta d-none d-md-inline-block m7reqdem">
                  Request a Demo
                </Link> */}
              </div>
            </div>
          </div>

          <div className="container bgs-text-img-section bgs-centralised">
            <div className="row">
              <div className="col-md-6 col-lg-6 centralised-img-section">
                <Waypoint onEnter={() => this.animateSection("centerSection")} topOffset="70%">
                  <div id="centralisedAnimation" className="clearfix" style={{overflow: "hidden", position: "relative", height: "347px", width: "537px", marginLeft: "-75px"}}>
                    <img className="centralized-img-whole" src={require("../assets/images/centralized.png")} style={{opacity: "0"}} alt="" />
                    <img id="centralised-img1" className="" src={require("../assets/images/centralised_report1.png")} alt="Centralized" style={{position: "absolute", top: "0", right: "0"}} />
                    <img id="centralised-img2" className="" src={require("../assets/images/centralised_report3.png")} alt="Centralized" style={{position: "absolute", bottom: "0", left: "0", width: "390px"}} />
                  </div>
                </Waypoint>
              </div>
              <div className="col-md-6 col-lg-5 offset-lg-1 bgs-text-section">
                <h3 className="heading-type-3">Centralized reporting and security</h3>
                <p className="pr-md-1">
                  Admin dashboards allow team leaders to monitor adoption and aggregated writing trends. Centralized tools make it easy to manage your team account. SAML single sign-on provides your team with a simple experience to onboard and access Grammarly while also enforcing identity-related
                  security controls.
                </p>
                <CallToAction to="https://www.grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta d-none d-md-inline-block m8reqdem" eName="service-team" eLabel="enterprise-service-teams-demo">
                  Request a Demo
                </CallToAction>
                {/* <Link to="/get-in-touch" onClick={this.registerGAPageViewCustom} className="btn btn-primary btn-talk btn-cta d-none d-md-inline-block m8reqdem">
                  Request a Demo
                </Link> */}
              </div>
            </div>
          </div>

          <div className="container bgs-text-img-section bgs-wi">
            <div className="row">
              <div className="col-md-5 offset-md-1 order-md-2">
                <div className="where-you-work">
                  <Waypoint onEnter={() => this.animateSection("lastSection")} topOffset="70%">
                    <div id="lastAnimation" className="why-work-img-collection" style={{width: "530px", height: "436px", position: "relative", overflow: "hidden"}}>
                      <img className="" src={require("../assets/images/works.png")} style={{opacity: "0"}} alt="" />
                      <img className="oval" src={require("../assets/images/where-you-work-oval.png")} alt="Works" style={{position: "absolute", bottom: "0", right: "0", width: "385px"}} />
                      <img className="msg-sheets1" src={require("../assets/images/where-you-work4.png")} alt="Works" style={{position: "absolute", top: "0", left: "0", width: "310px"}} />
                      <img className="msg-sheets2" src={require("../assets/images/where-you-work1.png")} alt="Works" style={{position: "absolute", top: "35px", left: "50px", width: "310px"}} />
                      <img className="msg-sheets3" src={require("../assets/images/where-you-work2.png")} alt="Works" style={{position: "absolute", top: "77px", left: "100px", width: "310px"}} />
                      <img className="msg-sheets4" src={require("../assets/images/where-you-work3.png")} alt="Works" style={{position: "absolute", top: "115px", left: "150px", width: "310px"}} />
                    </div>
                  </Waypoint>
                </div>
              </div>
              <div className="col-md-6 bgs-text-section order-md-1">
                <h3 className="heading-type-3">Works where you work</h3>
                <p className="pr-md-1">Seamlessly integrating across devices and platforms, Grammarly Business is designed to meet the needs of today's workplace. Team members are empowered to execute tasks with speed without sacrificing quality – at their desk or on the go.</p>
                <CallToAction to="https://www.grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta d-none d-md-inline-block m9reqdem" eName="service-team" eLabel="enterprise-service-teams-demo">
                  Request a Demo
                </CallToAction>
                {/* <Link to="/get-in-touch" onClick={this.registerGAPageViewCustom} className="btn btn-primary btn-talk btn-cta d-none d-md-inline-block m9reqdem">
                  Request a Demo
                </Link> */}
              </div>
            </div>
          </div>
          <div className="container py-3 section-grey sticky-bottom d-md-none">
            <div className="row">
              <div className="col-md-12">
                {/* <CallToAction to="https://grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta" eName="service-team" eLabel="enterprise-service-teams-demo m10reqdem">
                  Get in touch
                </CallToAction> */}
                <a
                  href="#"
                  className="btn btn-primary btn-talk btn-cta"
                  onClick={(e) => {
                    e.preventDefault();
                    this.scrollToRef();
                  }}
                >
                  Get in touch
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="section-grey align-team-with-company-section">
          <div className="container">
            <div className="text-center ">
              <h2 className="heading-type-2">Align Your Team With Your Company’s Style</h2>
              <p className="sub-para">
                With Grammarly Business, you can create a company style guide to keep
                <br className="d-none d-lg-block" /> every team member’s communication clear and on-brand.
              </p>

              <div className="row content-section">
                <div className="col-md-6">
                  <div className="img-container">
                    <img src={require("../assets/images/align-team-with-company1.png")} className="img-fluid" />
                  </div>
                  <p className="pb-5 pb-md-0">Make sure your company, product, and trademark names are properly spelled and capitalized.</p>
                </div>
                <div className="col-md-6">
                  <div className="img-container">
                    <img src={require("../assets/images/align-team-with-company2.png")} className="img-fluid" />
                  </div>
                  <p>Help your team avoid jargon that confuses your customers.</p>
                </div>
              </div>

              <div className="cta-link-section">
                <Link to="/styleguide">Learn more about style guides ></Link>
              </div>
            </div>
          </div>
        </section>

        <section className="soc2-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <a href="https://www.aicpa.org/soc4so" target="_blank">
                  <img src={require("../assets/images/aicpa-soc1.png")} className="img-fluid" />
                </a>
              </div>
              <div className="col-md-6 col-lg-5 offset-lg-1 d-flex flex-column justify-content-center">
                <div className="">
                  <h3 className="heading-type-3">Keeping Your Words and Data Safe</h3>
                  <p className="pr-md-1">
                    Grammarly’s SOC 2 (Type I) report attests to our enterprise-grade system and organization controls regarding security, privacy, availability, and confidentiality. This examination by a third-party auditor validates Grammarly’s commitment to customer trust.
                  </p>
                  <Link to="/get-in-touch-soc-2" className="d-block">
                    Contact sales
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="accelarate-results-section d-none d-md-flex">
          <div className="container continer-show-dots ars-container">
            <div className="text-center ars">
              <h2 className="heading-type-2">Up-level execution, accelerate results</h2>
              <video id="explorevideo" muted autoPlay>
                <source src={require("../assets/videos/mother_demo_desktop_v1.webm")} type="video/webm" />
                <source src={require("../assets/videos/mother_demo_desktop_v1.mp4")} type="video/mp4" />
              </video>
              {/* <CallToAction  eType="Talk" className="mt-0" eName="service-team" eLabel="enterprise-service-teams-talk m11letstalk">
                Let's Talk
              </CallToAction> */}
              <a
                href="/"
                className="btn btn-primary btn-talk mt-0"
                onClick={(e) => {
                  e.preventDefault();
                  this.scrollToRef();
                }}
              >
                Let's Talk
              </a>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid px-0 continer-show-dots ars-container d-md-none">
            <div className="text-center ars">
              <h2 className="heading-type-2 mt-5 mb-4 pt-5">Up-level execution, accelerate results</h2>

              <Carousel>
                <Carousel.Item>
                  <img src={require("../assets/images/Mobile1_4.gif")} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={require("../assets/images/Mobile2_3.gif")} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={require("../assets/images/Mobile3_3.gif")} alt="" />
                </Carousel.Item>
              </Carousel>
              <CallToAction to="https://grammarly.com/contact/sales?source=enterprise" eType="Talk" className="mt-4 mb-5 w-75" eName="service-team" eLabel="enterprise-service-teams-talk m12letstalk">
                Let's Talk
              </CallToAction>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default withRouter(IndexPage);
