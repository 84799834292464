import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";
import { Link } from "react-router-dom";

class InvestInConsistentTeamWriting extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Why You Should Invest in Consistent Team Writing</h1>
              </div>
            </div>
          </div>
          <div className="mt-4 header-section-content">
            <div className="col-12 px-0">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/invest-in-consistent-team-writing/invest-in-consistent-team-writing-desktop.png")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/invest-in-consistent-team-writing/invest-in-consistent-team-writing-mobile.jpg")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-12 text-left text-md-center">
                            <span>November 11, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                
                <p className="IntroPara">When seemingly everything—business, politics, pop culture—is content-driven, good writing is not only necessary; it’s a game-changer. For businesses, the rise of communication and digital content has increased their brand's power and reach. What do strong brands need to succeed? A deep understanding of their customers, a clear purpose, and, most importantly, a remarkable brand experience delivered consistently across touchpoints—an “it” factor to drive brand awareness and business performance.</p>
                <p>The good news is that you don’t have to be a professional editor to produce excellent writing. There are tools at your disposal to strengthen your team’s communication skills and deliver engaging messages with consistency. The Grammarly Premium offering is robust, but Grammarly Business is built for teams. Read on to learn how Grammarly Business can turn your team’s communication into a competitive advantage with features like custom style guides.</p>

                <div className="container-fluid my-5 px-0">
                  <div className="green-container p-3">
                    <div className="row no-gutters">
                      <div className="col-lg-8 text-center text-lg-left">
                        <h3>Consistency can be tough.</h3>
                        <p>Grammarly Business style guides make it easy.</p>
                      </div>
                      <div className="col-lg-4">
                        <div className="d-flex flex-column h-100 justify-content-center">
                          <Link to="/blog/ensure-consistent-branding-with-style-guides?utm_source=content&utm_medium=enterpriseblog&utm_campaign=consistency" className="btn btn-primary btn-small-cm mx-auto text-uppercase">
                            Learn more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                

                <h2 className="my-4">Upgrade to a living, breathing style guide</h2>
                <p>Creating a company style guide can be a time-consuming investment. When teams do have one, it’s typically a static document, locked away in the corner, used by a few teammates. It’s likely outdated and doesn’t reflect your brand.</p>
                <p>Grammarly envisioned what a style guide should be—a real-time source of truth for all writing and language representing your brand.</p>

                <h2 className="my-4">Stay on-brand</h2>
                <p>Based on a survey of 3,900 professionals using Grammarly for work, 83 percent of marketers say their work is more polished and professional when using Grammarly. Whether it’s product documentation, social media, or email campaigns, it’s crucial for marketers to build trust and credibility by being clear, correct, and consistently on-brand.</p>
                <p>A custom style guide ensures that everyone on your team writes the company name, executive names, product names, and brand terms correctly and consistently. Grammarly Business style guides can ensure that specific words, acronyms, and titles are consistent across the different platforms, browsers, and tools your team uses.</p>

                <h2 className="my-4">Deliver a reliable customer experience</h2>
                <p>Your sales team communicates with potential and existing customers every day. It’s imperative that salespeople use consistent grammar and branded language. Grammarly Business includes features to help you stay consistent in punctuation and spelling, tone, and delivery. You can set goals for outbound emails based on your audience, formality, tone, and intent.</p>

                <h2 className="my-4">“95 percent of salespeople who use Grammarly say it saves them time writing and editing.”</h2>
                <img className="img-fluid w-100 mb-3" src={require("../../assets/images/blog/invest-in-consistent-team-writing/invest-in-consistent-team-writing-In-line-image.png")} alt="" />
                
                <h2 className="my-4">Improve writing and fluency</h2>
                <p>When using a digital writing assistant that’s checking for consistency (and correctness and clarity), your writing will improve over time. Not only will you make fewer of the same mistakes, but everyone on your team will also learn the brand rules. No, the product names don't need to be capitalized, your CEO’s last name doesn’t include an e at the end, and the marketing team prefers you use the Oxford <a href="https://www.grammarly.com/blog/comma/" target="_blank">comma</a>.</p>
                <p>And as work becomes more global, your team may include folks who learned English as a second language or are English language learners. Grammarly Business is a great resource for these teammates and can help build their confidence and autonomy in written communications. In addition to learning as they use Grammarly, each teammate receives an informative (and fun!) weekly summary of their writing skills, allowing them to progressively learn how to write more effectively over time.</p>

                <h2 className="my-4">Track performance and improvements</h2>
                <p>Grammarly Business also provides managers and admins with access to simple reports and controls. As an admin, you can identify the team’s most common mistakes and inconsistencies and see improvements. And it’s all tracked in one central place, where they can also manage billing and add new accounts as the organization grows.</p>
                <p>Pitch your procurement team on all the ways your team can benefit from a one-stop-shop for consistent communication:</p>
                <ul>
                  <li><p class="mb-1">You can keep company names, terms, and style consistent with a custom style guide</p></li>
                  <li><p class="mb-1">You’ll wow your customers with consistently well-written and intentional messages</p></li>
                  <li><p class="mb-1">You can help people on your team become better writers</p></li>
                  <li><p class="mb-1">You can track performance and areas of improvement </p></li>
                </ul>
                <p><Link to="/?utm_source=content&utm_medium=blog&utm_campaign=consistency">Grammarly Business</Link> takes everything that Grammarly Premium offers—clarity, engagement, delivery—and adds features to help get your team on the same page. With Grammarly Business custom style guides, you can avoid the occasional snafu, improve your team’s writing over time, and rest easy knowing that customers are getting a consistent, brand-aligned experience at every interaction. To learn more about Grammarly Business, <Link to="/meet-grammarly-business">watch this video</Link> to see it in action.</p>

              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default InvestInConsistentTeamWriting;
