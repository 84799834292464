import React from 'react';
import $ from "jquery";

import CountryListSelect from './CountryListSelect';
import ReCAPTCHA from "react-google-recaptcha";

class ServiceForm extends React.Component {
    state = {
        url: "",
    };

    constructor() {
        super();

        this.UTMSource = "";
        this.UTMSourceFlag = false;
        this.pageURL = window.location.href;
        this.getURLQuery();

    }

    getURLQuery() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has("utm_source")) {
            this.UTMSource = urlParams.get("utm_source");
            this.UTMSourceFlag = true;
            console.log(this.UTMSource);
        } else {
            this.UTMSource = "";
        }
    }

    showConnectForm(){
        $(".connect-form").show(); 
    }

    verifyCaptcha(value) {
        if(value != ""){
            $('#FormSubmit').removeClass('d-none');
            $('#FormSubmit').addClass('d-flex');
        }
        else{
            $('#FormSubmit').removeClass('d-flex');
            $('#FormSubmit').addClass('d-none');
        }
    }

    showForm(event) {
        if (event.target.value != "1-100") {
          $(".Form-Intro-Container").hide();
          $(".form-container .form-elements").show();
        } else if (event.target.value == "1-100") {
          $(".form-container .initialBtn").hide();
          $(".form-container .selectedBtn").show();
          $(".form-container .form-elements").hide();
          $(".Form-Intro-Container").show();
        } else {
          $(".form-container .initialBtn").show();
          $(".form-container .selectedBtn").hide();
          $(".form-container .form-elements").hide();
          $(".Form-Intro-Container").show();
        }
    
        $(".connect-form").hide(); 
      }

    render() { 
        return (  
          <h2 className="pt-4 mt-2 pb-3">Stronger writing means better business results.</h2>
            // <form className="pt-4 pb-4" id="pardot-form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
            //         <h2>Stronger writing means better business results.</h2>
            //         <p className="my-5 mx-2">Leave your information and we'll get back to you shortly.</p>
            //         <label className="mt-0 text-left pl-md-3 pl-2 ml-1 ml-md-0">Select Company Size:</label>
            //         <div className="row pl-md-3">
            //           <span className="col-6 col-md px-0 default-option text-center">
            //             <input type="radio" className="customRadioBtn" onChange={this.showForm} name="00N3c000007ui19" id="companyOne" value="1-100" />
            //             <label htmlFor="companyOne">1-100</label>
            //           </span>
            //           <span className="col-6 col-md px-0 default-option text-center">
            //             <input type="radio" id="companyTwo" className="customRadioBtn" onChange={this.showForm} name="00N3c000007ui19" value="101-750" />
            //             <label htmlFor="companyTwo">101-750</label>
            //           </span>
            //           <span className="col-6 col-md px-0 default-option text-center">
            //             <input type="radio" id="companyThree" className="customRadioBtn" onChange={this.showForm} name="00N3c000007ui19" value="751-2500" />
            //             <label htmlFor="companyThree">751-2500</label>
            //           </span>
            //           <span className="col-6 col-md px-0 default-option text-center">
            //             <input type="radio" id="companyFour" className="customRadioBtn" onChange={this.showForm} name="00N3c000007ui19" value="2500+" />
            //             <label htmlFor="companyFour">2500+</label>
            //           </span>
            //         </div>
            //         <p className="text-danger connect-form pl-3 mt-1 mb-0 text-left" style={{display: "none"}}>Please make a selection</p>
            //         <br />
            //         <input type={"hidden"} name="oid" value="00D80000000d8Lr" />
            //         {/* <input type={"hidden"} name="retURL" value={this.state.url} /> */}
            //         <input type={"hidden"} name="retURL" value="https://grammarly-enterprise.com/thankyou" />
            //         <input type={"hidden"} name="lead_source" value="grammarly-enterprise" />
            //         <input type={"hidden"} id="00N80000003lP66" name="00N80000003lP66" title="License Type" value="Volume License" />
            //         <input type={"hidden"} id="00N3c000006cghu" name="00N3c000006cghu" title="utmSource" value={this.UTMSource}></input>
            //         <input type={"hidden"} id="00N3c000006wQW2" maxLength="255" name="00N3c000006wQW2" size="20"  />

            //         <div className="form-elements pl-2 pl-md-4 pr-2 pb-4">
            //           <p className="sources-text text-left">
            //             <em>All fields are mandatory unless stated otherwise</em>
            //           </p>
            //           {/* <label for="first_name">First Name</label> */}
            //           <input id="first_name" maxLength="40" name="first_name" placeholder="First Name" size="20" type="text" required />
            //           <br />

            //           {/* <label for="last_name">Last Name</label> */}
            //           <input id="last_name" maxLength="80" name="last_name" placeholder="Last Name" size="20" type="text" required />
            //           <br />

            //           {/* <label for="title">Job Title</label> */}
            //           <input id="title" maxLength="40" name="title" placeholder="Job Title" size="20" type="text" required />
            //           <br />

            //           {/* <label for="company">Company Name</label> */}
            //           <input id="company" maxLength="40" name="company" placeholder="Company Name" size="20" type="text" required />
            //           <br />

            //           {/* <label for="email">Work Email</label> */}
            //           <input id="email" maxLength="80" name="email" placeholder="Work Email" size="20" type="email" required />
            //           <br />

            //           {/* <label for="country">Country</label> */}
            //           {/* <input id="00N3c000007umem" maxlength="40" name="00N3c000007umem" size="20" type="text" placeholder="Country" required /> */}
            //           <CountryListSelect />

            //           {/* <label for="phone">Phone Number</label> */}
            //           <input id="phone" maxLength="40" name="phone" placeholder="Phone Number (Optional)" size="20" type="text" />
            //           <br />

            //           {/* <label className="d-none">Notes:</label> */}
            //           <textarea id="00NC0000007FZ9t" name="00NC0000007FZ9t" placeholder="Which challenges are you looking to overcome with Grammarly?" type="text" wrap="soft"></textarea>
            //           <br />
            //           <br />

            //           <ReCAPTCHA sitekey="6Lcm_7EZAAAAAHKVOJZw7F0Hgg-H-FBRGTNwiPue" render="explicit" onChange={this.verifyCaptcha}/>
            //           <br />

            //           <input id="FormSubmit" className="d-none align-self-center" type="submit" name="submit" value="Submit" />
            //         </div>
            //       </form>
        );
    }
}
 
export default ServiceForm;