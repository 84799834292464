import React from 'react';
import $ from "jquery";
import Select from 'react-select';
import DropdownIndicator from './DropdownIndicator';

const options = [
    { value: '', label: '--None--' },
    { value: 'Student', label: 'Student' },
    { value: "Faculty", label: "Faculty" }
];
  

class CSFFacultySelect extends React.Component {
    state = {  }

    componentDidMount(){
        $(".rsdc-csf-faculty-select-custom > input").attr("id", "00N3c000006wQH2");
        $(".rsdc-csf-faculty-select-custom > input").attr("title", "Student or Faculty?");
    }

    render() { 
        return (  
            <>
            <label className="">Student or Faculty?</label>
            <Select 
            components={{ DropdownIndicator }}
            id="rsd-csf-faculty-select-id" 
            name="00N3c000006wQH2" 
            options={options} 
            className="rs-dropdown-container-custom mt-0 rsdc-csf-faculty-select-custom" 
            placeholder="Make a selection" 
            styles={{
                indicatorSeparator: () => {}, // removes the "stick"
            }}
            />
            </>
        );
    }
}
 
export default CSFFacultySelect;