import React from 'react';

const BlogResourcesSelfHostedVidsModal = (props) => {
    
    const setShowSelfHostedVids = props.setShowSelfHostedVids;
    const showSelfHostedVidsUrl = props.showSelfHostedVidsUrl;

    return (
    <>
    <div class="modal modal-vids-custom modal-sh-vids-custom fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button onClick={ () => setShowSelfHostedVids(false) } type="button" class="close" data-dismiss="modal" aria-label="Close">
                        {/* <span aria-hidden="true">&times;</span> */}
                        <img src="/assets/images/green-cross-icon.png" alt=""/>
                    </button>
                    <video id="explorevideo" autoplay="" controls>
                        <source src={showSelfHostedVidsUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop modal-vids-backdrop-custom fade show"></div>
    </>
    );
}
 
export default BlogResourcesSelfHostedVidsModal;