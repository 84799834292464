import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";


class CommunicationEmpowersTeamwork extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Communication Empowers Great Teamwork—Here’s How</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/communication-empowers-teamwork/cet-hero-desktop.png")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/communication-empowers-teamwork/cet-hero-mobile.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>May 25<sup>th</sup>, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">
                As any leader who has helped build or scale an organization knows, teamwork takes work. It’s not enough to simply hire great people.
                </p> 
                
                <p>As Google famously found<sup>1</sup> through hundreds of interviews and reviewing the attributes of high-performing teams, how a team interacts tends to matter more than who is on it. Research cites a number of factors that contribute to teamwork—collaboration, psychological safety, and vision, to name a few—which vary slightly depending on the source. But regardless of how studies rank or uniquely describe these factors, they embrace a common foundation: the importance of communication.
                </p>

                <p>
                Grounded in two decades of data, Harvard Business Review found that employees spend more than three-quarters of their day communicating with their colleagues <sup>2</sup>. That means that team members spend more time communicating than doing anything else, including the critical work they’re accountable for completing individually. It’s no wonder then that communication is a common theme in discussions about teamwork—it’s arguably the single most effective lever in facilitating positive change. 
                </p>
                
                <h2 className="my-4">How Communication Facilitates Teamwork</h2>
                <p>
                At its core, communication is about the exchange of ideas and information. But for that exchange to be fully realized, ideas must be conveyed clearly. Comprehensible, consistent communication ensures that ideas are not just more likely to be understood—they’re more likely to be understood similarly across a team. This shared context helps break down siloes between individuals and teams, streamlines unnecessary back-and-forth, and increases buy-in for decision-making. The result is a shift from sporadic success to more purposeful progress across the organization.                  
                </p>

                <div className="container-fluid px-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <img className="mt-4 mb-5 img-fluid d-none d-md-block" src={require("../../assets/images/blog/communication-empowers-teamwork/cet-in-post-desktop.png")} alt="" />
                      <img className="mt-4 mb-5 img-fluid d-md-none" src={require("../../assets/images/blog/communication-empowers-teamwork/cet-in-post-mobile.png")} alt="" />
                    </div>
                  </div>
                </div>

                <h2 className="mb-4">Creating Communication Norms</h2>

                <p>
                It’s a tall order to get groups of increasingly diverse, distributed, digitally connected individuals communicating as a cohesive unit. The task becomes even more complex when most organizations have developed behaviors around communication already—intentionally or not. Changing behavior takes time, but by embracing and modeling effective habits, leadership can set the tone for establishing new communication norms.
                </p>

                <p>Here are three ways to get started:</p>

                <ol>
                  <li className="my-2">
                    <b>Documentation:</b> Take a few minutes to write things down. Whether it’s that five-year plan you’ve been noodling on or this quarter’s immediate objectives; an articulation of how annual planning plays out or the steps involved in getting a press release approved—put your thoughts in writing. This practice will help you articulate your thinking more clearly, and create a central source of information that you can share broadly as the organization scales. 
                  </li>
                  <li className="my-2">
                    <b>Cadence:</b> Set expectations for regular communication rhythms. After teamwide meetings, for example, consider circulating the notes and key takeaways via email. If you present a quarterly plan, consider a monthly communication that tracks progress. Not only does this create an opportunity to reinforce the original messages, but it empowers team members to make better decisions based on the most up-to-date information. 

                  </li>
                  <li className="my-2">
                    <b>Delivery:</b> Many organizations rely on a <a href="https://grammarly-enterprise.com/blog/technology-cx-goals" target="_blank">
                      complex technology stack
                    </a> to connect team members across roles, functions, and time zones. Whether it’s Google Docs, Slack, Confluence, or regular old email, choose your communication channels intentionally to ensure the right information reaches the right team members and that it’s easily accessible. You may also consider dedicating channels to specific types of communication over time.
                  </li>
                </ol>

                <p>
                In the words of Henry Ford, “Coming together is a beginning. Keeping together is progress. Working together is success.” Building a strong foundation of communication within your organization will help you bring your teams together—and keep them together—on the journey toward effective teamwork.
                </p>

                <p className="mb-5 sources-text">
                  Sources: <br />
                  <ol className="px-md-2 ml-md-1 mt-2">
                    <li>Google, The Five Keys to a Successful Google Team, 2015</li>
                    <li>Harvard Business Review, Collaborative Overload, January-February 2016 Issue</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                        onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>

                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CommunicationEmpowersTeamwork;
