import React from 'react';
import ReactGA from "react-ga";
import Header from './Header';
import Footer from './Footer';
import PropTypes from "prop-types";
import Meta from "./Meta";

ReactGA.initialize('UA-6331378-27', {
  debug: false,
  // testMode: true, // false to enable in production
  // titleCase: false
});

ReactGA.pageview(window.location.pathname + window.location.search);

const Layout = ({ children, className, landing, meta }) => (
  <div className="section-dark-grey">
    <div className={`whole-page ${className}`}>
      <Meta {...meta} />
      <Header landing={landing}/>
      <main>{children}</main>
      <Footer landing={landing}/>
    </div>
  </div>
);

Layout.defaultProps = {
  className: ``,
  landing: false,
};

Layout.propTypes = {
  className: PropTypes.string,
  landing: PropTypes.bool,
  meta: PropTypes.object
};

export default Layout
