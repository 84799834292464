import React from "react"
import Layout from "../../components/Layout"
import CallToAction from '../../components/Button'

const Marketing = () => (
  <Layout className="marketing-landing-page">
    <section className="banner-section">
      <div className="banner">
        <div className="banner-items">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="card-title heading-type-1">Grammarly for Marketing Teams</h1>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="card-text p-type-1">Make Your Message Memorable</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CallToAction
                  to="https://grammarly.com/contact/sales?source=enterprise"
                  eType="See-Demo"
                  eName="marketing-team"
                  eLabel="enterprise-marketing-team-see-demo"
                  className="mar1seedem"
                >
                  Get in touch
                </CallToAction>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-grey testimonial-1">
      <div className="container">
        <div className="d-flex flex-row flex-sm-column flex-column flex-md-row flex-xl-row">
          <div className="p-2 fb-img-block">
            <img src={require('../../assets/images/Module2_UI_marketing.gif')} alt="" />
          </div>
          <div className="p-2 align-self-stretch mt-4 text-center text-md-left">
            <blockquote className="blockquote">
              <img className="inverted-comma" src={require('../../assets/images/double-inverted-comma.png')} alt="" />
              <p className="blockquote-body">How we employ language is our distinguisher—it assists us in
                attracting and retaining satisfied clients.”</p>
              <footer className="blockquote-footer-1">
                <cite>
                  <span className="author mb-2">Hekk Wuorinen</span>
                  <span className="position">Director of Client Services, Thy Agency</span>
                </cite>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>

    <div className="sticky-container-holder">

      <section className="help-your-team">
        <div className="container">

          <div className="row text-center hyt-img-header">
            <div className="col-md-12 hyt-img-section">
              <img src={require('../../assets/images/Marketing_PopOut_Icon.png')} alt="" />
              <h2 className="heading-type-4">Individuals view 4,000 to 10,000 marketing messages per day.</h2>
            </div>
          </div>

          <div className="row">
            <div className="w-100 hr-line" />
          </div>

          <div className="row text-left text-md-center hyt-sub-header">
            <div className="col-md help-team mt-3 mt-md-0 ">
              <h2 className="heading-type-2">Grammarly Business can help your team:</h2>
            </div>
          </div>

          <div className="row help-team">
            <div className="col-md">
              <h3>Cut through the noise</h3>
              <p>Brightening messages with engaging language captures attention more quickly.</p>
            </div>

            <div className="col-md">
              <h3>Improve brand perception</h3>
              <p>Clear messaging promotes a high-quality public image that remains true to your brand.</p>
            </div>

            <div className="col-md">
              <h3>Stay consistent</h3>
              <p>Keep a steady hand on your campaigns, perfecting every message in the same polished
                style.</p>
            </div>

            <div className="col-md">
              <h3>Scale across platforms</h3>
              <p>Grammarly Business works with Facebook, Twitter, Gmail, WordPress, Salesforce, and
                hundreds of other applications on desktop, laptop, and mobile.</p>
            </div>
          </div>

          <div className="row text-center d-none d-md-block">
            <div className="col-sm">
              <CallToAction
                to="https://grammarly.com/contact/sales?source=enterprise"
                eType="Lets-talk"
                eName="marketing-team"
                eLabel="enterprise-marketing-team-lets-talk"
                className="mar2letstalk"
              >
                Let's Talk
              </CallToAction>
            </div>
          </div>

        </div>
      </section>

      <section className="section-grey testimonial-2">
        <div className="container continer-show-dots">
          <div className="row">
            <div className="col-md">
              <blockquote className="blockquote text-center">
                <img className="inverted-comma" src={require('../../assets/images/double-inverted-comma.png')} alt="" />
                <p className="blockquote-body">Clear and consistent language and messaging help frame our
                  offerings and delivery at the quality our clients expect, every time. This is why we
                  encourage our entire team to use Grammarly.”</p>
                <footer className="blockquote-footer-1">
                  <cite>
                    <span className="author mb-2">Hekk Wuorinen</span>
                    <span className="position">Director of Client Services, Thy Agency</span>
                  </cite>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section className="why-service-team">
        <div className="container container-circle">
          <div className="row">
            <div className="col text-left text-md-center">
              <h2 className="heading-type-3">Why Marketing Leaders Love Grammarly Business</h2>
            </div>
          </div>
          <div className="row card-row">
            <div className="col-md">
              <img src={require('../../assets/images/Marketing_1_Clear.png')} className="img-fluid" alt=""/>
              <h3>Clear</h3>
              <p>Grammarly simplifies messages and encourages marketers to write in the active voice.
              </p>
            </div>
            <div className="col-md">
              <img src={require('../../assets/images/Marketing_2_Engaging.png')} className="img-fluid" alt="" />
              <h3>Engaging</h3>
              <p>Grammarly brightens communications by suggesting synonyms that capture a reader&#8217;s
                attention.</p>
            </div>
            <div className="col-md">
              <img src={require('../../assets/images/Marketing_3_Consistent.png')} className="img-fluid" alt="" />
              <h3>Consistent</h3>
              <p>Grammarly supports companies in communicating across channels with credible, professional
                writing.</p>
            </div>
          </div>
          <div className="row d-none d-md-block">
            <div className="col text-center">
              <CallToAction
                to="https://grammarly.com/contact/sales?source=enterprise"
                eType="Lets-talk"
                eName="marketing-team"
                eLabel="enterprise-marketing-team-lets-talk"
                className="mar3letstalk"
              >
                Let's Talk
              </CallToAction>
            </div>
          </div>
        </div>
      </section>

      <div className="col-12 px-0 sticky-bottom d-md-none sb-custom">
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <CallToAction
                  to="https://grammarly.com/contact/sales?source=enterprise"
                  className="d-md-none mar4letstalk"
                  eType="Lets-talk"
                  eName="marketing-team"
                  eLabel="enterprise-marketing-team-lets-talk"
                >
                  Let's Talk
                </CallToAction>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

export default Marketing;
