import React from "react";
import ReactGA from "react-ga";

const registerEventToGA = (eName, eType, eLabel) => {
    
    console.log("event fired");  
    ReactGA.event({
        category: eName,
        action: eType,
        label: eLabel,
        // value: window.location.href
    });
}

export {registerEventToGA};