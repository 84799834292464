import React from 'react';
import {
  BrowserRouter as Router,
  // HashRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/sass/style.scss';

// Import pages here
import Home from './routes/Home';
import NotFound from './routes/NotFound';
import Ebook from './routes/Ebook';
import CustomerExperienceeBook from './routes/CustomerExperienceeBook';
import Hr from "./routes/yourteam/Hr";
import Marketing from "./routes/yourteam/Marketing";
import Sales from "./routes/yourteam/Sales";
import Service from "./routes/yourteam/Service";
import LetsTalk from "./routes/form/LetsTalk";
import RequestDemo from "./routes/form/RequestDemo";
import Newhome from './routes/newhome/home';
import ChiliPiper from './routes/newhome/chilli';
import VoxStyleGuide from './routes/newhome/VoxStyleGuide';
import StyleGuideMarketing from './routes/newhome/StyleGuideMarketing';
import HackingCXeBook from './routes/HackingCXeBook';
import HackingCXeBookNoForm from './routes/HackingCXeBookNoForm';
import RequestLiveDemo from './routes/RequestLiveDemo';

import GetInTouch from './routes/GetInTouch';
import ThankYou from './routes/thankyou';
import ThankYou2 from './routes/thankyou2';
import ThankYouCPNotScheduled from './routes/thankyou-cp-not-scheduled';
import ThankYouCPScheduled from './routes/thankyou-cp-scheduled';
import ThankYouCPSupport from './routes/thankyou-cp-support';
import GetInTouch2 from './routes/GetInTouch2';
import CXCampaignPreRegistrations from './routes/CXCampaignPreRegistrations';
import ThankYouPreRegEbook from './routes/ThankYouPreRegEbook';
import GetInTouchPardot from './routes/GetInTouchPardot';

import CaseStudyHome from './routes/caseStudy/home';
import ZapierCaseStudy from './routes/caseStudy/zapier-case-study';
import QuonticCaseStudy from './routes/caseStudy/QuonticCaseStudy';
import BlindCaseStudy from './routes/caseStudy/BlindCaseStudy';
import ZouraGBCaseStudy from './routes/caseStudy/ZouraGBCaseStudy';
import CPanelCaseStudy from './routes/caseStudy/CPanelCaseStudy';

import TechnologyCXGoals from './routes/blog/technology-cx-goals';
import ReduceMiscommunicationCostFeatures from './routes/blog/reduce-miscommunication-cost-features';
import EbookCustomerExperience from './routes/blog/ebook-customer-experience';
import ConsistentCustomerExperience from './routes/blog/consistent-customer-experience';
import PivotalCustomerStory from './routes/blog/pivotal-customer-story';
import EmpatheticLeadershipResources from './routes/blog/empathetic-leadership-resources';
import CommunicationEmpowersTeamwork from './routes/blog/communication-empowers-teamwork';
import ConsistentCommunicationRetainsCustomers from './routes/blog/consistent-communication-retains-customers';
import GrammarlyBusinessStyleGuide from './routes/blog/grammarly-business-style-guide';
import EnsureConsistentBrandingWithStyleGuides from './routes/blog/ensure-consistent-branding-with-style-guides';
import BlogResources from './routes/blog/BlogResources';
import CXTrendsToAchieveBusinessGoals from './routes/blog/3-cx-trends-to-achieve-business-goals';
import MeetGrammarlyBusinessVideo from './routes/blog/MeetGrammarlyBusinessVideo';
import FourCommunicationTacticsOpsPlaybook from './routes/blog/four-communication-tactics-ops-playbook';
import GrowCommunicationsAtScale from './routes/blog/grow-communications-at-scale';
import CustomersDontNeedAnotherWay from './routes/blog/CustomersDontNeedAnotherWay';
import EnshoredCustomerStory from './routes/blog/EnshoredCustomerStory';
import ConsistencyCriticalWorkCommunication from './routes/blog/ConsistencyCriticalWorkCommunication';
import UpskillMoreEfficientCXTeams from './routes/blog/UpskillMoreEfficientCXTeams';
import TheValueofLiveAgents from './routes/blog/TheValueofLiveAgents';
import InvestInConsistentTeamWriting from './routes/blog/InvestInConsistentTeamWriting';
import MeetGrammarlyBusinessVideoLp from './routes/MeetGrammarlyBusinessVideoLp';
import BestPracticesCustomerCommunication from './routes/blog/BestPracticesCustomerCommunication';
import ReorganizeYourWorkBench from './routes/blog/ReorganizeYourWorkBench';
import DeliverRobustChannelExperience from './routes/blog/DeliverRobustChannelExperience';
import ThreeStepsCombatIneffectiveCommunication from './routes/blog/ThreeStepsCombatIneffectiveCommunication';

import LinkedinPixelsConversion from './routes/common/LinkedinPixelsConversion';
import ThankEbook from './routes/thankyou-ebook';
import MeetGrammarlyBusinessVideoThankyou from './routes/blog/MeetGrammarlyBusinessVideoThankyou';


import FrostSullivanLandingPage from './routes/landing-page/FrostSullivanLandingPage';

function App() {
  return (
    <Router>
      {(window.location.hash)?<Redirect to={window.location.hash.replace("#", "")} />:""}
      <ScrollToTop>
        <Switch>
          <Route path="/yourteam/hr">
            <Hr />
          </Route>
          <Route path="/yourteam/marketing">
            <Marketing />
          </Route>
          <Route path="/yourteam/sales">
            <Sales />
          </Route>

          <Redirect from='/yourteam/service' to={`/yourteam/customer-experience`+ window.location.search}/>
          <Route path="/yourteam/customer-experience">
            <Service />
          </Route>

          <Route path="http://google.com">
            <RequestDemo />
          </Route>
          <Route path="/form/lets-talk">
            <LetsTalk />
          </Route>
          <Route path="/ebook">
            <Ebook />
          </Route>
          <Route path="/customer-experience-ebook">
            <CustomerExperienceeBook />
          </Route>
          
          <Route path="/hacking-cx-ebook">
            <HackingCXeBook />
          </Route>

          <Route path="/meet-grammarly-business-video-lp">
            <MeetGrammarlyBusinessVideoLp />
          </Route>

          <Route path="/hacking-cx-ebook-2">
            <HackingCXeBookNoForm />
          </Route>

          <Route path="/request-demo">
            <RequestLiveDemo />
          </Route>

          <Route exact path="/">
            <Home />
            <LinkedinPixelsConversion />
          </Route>
          {/* <Route path="/styleguide">
            <Newhome />
          </Route> */}
          <Route path="/chilipipertest">
            <ChiliPiper />
          </Route>

          <Route path="/get-in-touch">
            <GetInTouch />
          </Route>
          
          <Route path="/get-in-touch-soc-2">
            <GetInTouch2 />
          </Route>
          
          <Route path="/get-in-touch-3">
            <GetInTouchPardot />
          </Route>

          <Route path="/prereghackingcxebook">
            <CXCampaignPreRegistrations />
          </Route>

          <Route path="/thankyou">
            <ThankYou />
          </Route>
          <Route path="/thankyou-2">
            <ThankYou2 />
          </Route>
          <Route path="/thankyou-ebook">
            <ThankEbook />
          </Route>
          <Route path="/thankyou-prereg">
            <ThankYouPreRegEbook />
          </Route>

          <Route path="/cp-notscheduled">
            <ThankYouCPNotScheduled />
          </Route>
          <Route path="/cp-scheduled">
            <ThankYouCPScheduled />
          </Route>
          <Route path="/cp-support">
            <ThankYouCPSupport />
          </Route>

          <Route path="/resources">
            <BlogResources />
          </Route>
          
          <Route path="/frostandsullivan-case-study">
            <CaseStudyHome />
          </Route>
          <Route path="/zapier-case-study">
            <ZapierCaseStudy />
          </Route>
          <Route path="/quontic-case-study">
            <QuonticCaseStudy />
          </Route>
          <Route path="/leading-global-bpo-case-study">
            <BlindCaseStudy />
          </Route>
          <Route path="/leading-enterprise-software-case-study">
            <ZouraGBCaseStudy /> 
          </Route>
          <Route path="/leading-web-hosting-platform">
            <CPanelCaseStudy /> 
          </Route>
          <Route path="/ccvirtual">
            <FrostSullivanLandingPage />
          </Route>

          <Route path="/blog/technology-cx-goals">
            <TechnologyCXGoals />
          </Route>
          <Route path="/blog/reduce-miscommunication-cost-features">
            <ReduceMiscommunicationCostFeatures />
          </Route>
          <Route path="/blog/ebook-customer-experience">
            <EbookCustomerExperience />
          </Route>
          <Route path="/blog/customer-trust-consistency">
            <ConsistentCustomerExperience />
          </Route>
          <Route path="/blog/pivotal-customer-story">
            <PivotalCustomerStory />
          </Route>
          <Route path="/blog/empathetic-leadership-resources">
            <EmpatheticLeadershipResources />
          </Route>
          <Route path="/blog/communication-empowers-teamwork">
            <CommunicationEmpowersTeamwork />
          </Route>
          <Route path="/blog/consistent-communication-retains-customers">
            <ConsistentCommunicationRetainsCustomers />
          </Route>
          <Route path="/blog/ensure-consistent-branding-with-style-guides">
            <EnsureConsistentBrandingWithStyleGuides />
          </Route>
          <Route path="/blog/3-cx-trends-to-achieve-business-goals">
            <CXTrendsToAchieveBusinessGoals />
          </Route>
          <Route path="/blog/four-communication-tactics-ops-playbook">
            <FourCommunicationTacticsOpsPlaybook />
          </Route>
          <Route path="/upskill-teams-at-scale">
            <GrowCommunicationsAtScale />
          </Route>
          <Route path="/blog/customers-dont-need-another-way">
            <CustomersDontNeedAnotherWay />
          </Route>
          <Route path="/blog/enshored-customer-story">
            <EnshoredCustomerStory />
          </Route>
          <Route path="/blog/consistency-critical-to-work-communication">
            <ConsistencyCriticalWorkCommunication />
          </Route>
          <Route path="/blog/upskill-more-efficient-cx-teams">
            <UpskillMoreEfficientCXTeams />
          </Route>
          <Route path="/three-steps-combat-ineffective-communication">
            <ThreeStepsCombatIneffectiveCommunication />
          </Route>
          <Route path="/reorganize-your-workbench">
            <ReorganizeYourWorkBench />
          </Route>
          <Route path="/deliver-robust-channel-experience">
            <DeliverRobustChannelExperience />
          </Route>
          <Route path="/the-value-of-live-agents">
            <TheValueofLiveAgents />
          </Route>
          <Route path="/blog/invest-in-consistent-team-writing">
            <InvestInConsistentTeamWriting />
          </Route>
          <Route path="/Best-Practices-for-Customer-Communication-2020">
            <BestPracticesCustomerCommunication />
          </Route>
          <Route path="/styleguide-cs">
            <VoxStyleGuide />
            <LinkedinPixelsConversion />
          </Route>
          <Route path="/styleguide">
            <StyleGuideMarketing />
            <LinkedinPixelsConversion />
          </Route>
          <Route path="/blog/grammarly-business-style-guide">
            <GrammarlyBusinessStyleGuide />
          </Route>
          <Route path="/meet-grammarly-business">
            < MeetGrammarlyBusinessVideo />
          </Route>
          <Route path="/meet-grammarly-business-video-ty">
            < MeetGrammarlyBusinessVideoThankyou />
          </Route>
          <Route path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
