import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class ConsistentCommunicationRetainsCustomers extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Kickstart Your Efforts to Keep Everyone On-Brand</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/consistent-communication-retains-customers/458_Hero_desktop_1x.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>May 28, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">
                  According to Adobe’s Digital Trends Report, customer experience (CX) is the single most exciting opportunity for 2020—ahead of content marketing, video, and social<sup>1</sup>. While the benefits of putting the customer first are clear when it comes to competitive differentiation and
                  revenue, savvy marketing leaders also understand that prioritizing certain segments of customers can lead to even greater returns.
                </p>

                <p>
                  Studies have shown that it can cost, at minimum, five times as much to attract a new customer than to retain an existing one<sup>2</sup>. This makes sense considering most consumers require multiple touchpoints before making a purchase decision; compounding that, an increasingly saturated
                  market makes it challenging for advertisers to vie for attention. The takeaway? Your current customers are incredibly valuable.
                </p>

                <h2 className="my-4">Delivering on Customer Expectations</h2>

                <p>
                  Individuals and businesses alike put considerable time, research, and intention into selecting the brands they choose to fulfill their needs. When you’re the brand that’s chosen, there’s an expectation that you’ll deliver. Customers hope putting in this time up front will pay off
                  in an ability to rely on your product or service and help avoid having to go through the selection process all over again. Consistency is what enables you to build customer trust in your brand’s capacity to deliver on those expectations.
                </p>

                <p>
                  Consistency signals trustworthiness, stability, decisiveness, and sound judgment. When customers experience communications from your brand the same way, time after time, that consistency serves as a decision-making shortcut. Customers can feel confident about sticking with their
                  initial decision about your brand, easing their burden of having to process the ever-increasing amount of information in today’s digital landscape. An investment toward consistency rewards your existing customers for making that initial decision to choose your brand.
                </p>

                <h2 className="my-4">Setting the Standard</h2>

                <p>
                  Dictionary.com defines consistency as the steadfast adherence to the same principles, course, or form. To ensure your teams understand what they’re adhering to, you first need to develop some clearly defined communication principles. Here are three steps to kickstart your process:
                </p>

                <ol>
                  <li className="mb-2">
                    <b>Conduct an audit.</b> Enlist your team’s help in systematically cataloging your customer-facing communications across channels. This will help you understand where things currently stand and streamline the process for making adjustments if necessary.
                  </li>
                  <li className="mb-2">
                    <b>Identify areas of misalignment.</b> Revisit your customer experience goals and use them as a way to assess whether your communications are hitting the mark.
                  </li>
                  <li>
                    <b>Outline and document.</b> Use your findings from the previous two steps to determine not only where you need to course-correct, but also provide clearer guidance around a communication north star. You’ll likely need to articulate multiple principles that, together, move your
                    customer communications in the right direction. For example, a communication principle could read something like this:
                    <br />
                    <br />
                    <b>Open:</b> Our team is inviting, accepting, and ready to collaborate. We ask questions to ensure we understand and use tone matching to make customers feel heard.
                  </li>
                </ol>

                <p>
                  Once you’ve articulated these communication principles, discuss them with your team and publish them in a central place that can be easily accessed. With your team consistently relying on a single source of input, the higher the likelihood of consistent output in their
                  communications.
                </p>

                <p className="mb-5 sources-text">
                  Sources: <br />
                  <ol className="px-md-2 ml-md-1 mt-2">
                    <li>eConsultancy/Adobe, Digital Trends Report, 2019.</li>
                    <li>Harvard Business Review, The Value of Keeping the Right Customers</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" target="_blank" rel="noopener noreferrer" class="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ConsistentCommunicationRetainsCustomers;
