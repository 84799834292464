import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class TechnologyCXGoals extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Is Technology Helping or Hurting Your CX Goals?</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/case-study.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>April 28, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">
                  Customer Experience (CX) leaders are no strangers to <a href="https://www.salesforce.com/products/platform/what-is-digital-transformation/" target="_blank">
                    digital transformation
                  </a>. Tasked with delivering excellent experiences throughout customer journeys that range from <a href="https://www.thinkwithgoogle.com/feature/search-intent-marketing-funnel/#/" target="_blank">three to 500</a> touchpoints, technology has been the key to unlocking automation, personalization, data-driven insights, and more for many brands.
                </p>

                <p>
                  Today, more than <a href="https://futurumresearch.com/research-reports/futurum-2018-digital-transformation-index/" target="_blank">70% of brands</a> have a digital transformation strategy in place or in progress. As the competitive necessity of embracing new technology and evolving operational processes becomes increasingly clear, brands are expanding their internal toolkits. The average enterprise now uses <a href="https://www.forbes.com/sites/falonfatemi/2020/08/27/how-apis-can-transform-your-company/#b0abbb700993" target="_blank">more than 1,200</a> cloud apps, up from just five to ten in the 1990s.
                </p>
                <div className="container-fluid px-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <img className="mt-4 mb-5 img-fluid" src={require("../../assets/images/blog/In-post-image-1-1.png")} alt="" />
                    </div>
                  </div>
                </div>
                <p>
                Despite the benefits, introducing new technology and changing long-standing processes also means an increase in complexity. With complexity comes overhead. CX leaders now need to ensure that standards for consistency and quality are met across not only a growing number of individuals and teams but also a growing number of systems. 
                </p>
                <p>So can leaders ensure their technology is helping, not hurting their CX goals? Let’s dive into three tactics you can implement, no matter where you are in your digital transformation journey.</p>

                <h2 className="my-4">Involve your team</h2>
                <p>
                  Adopting new technology involves more than learning new tools—it requires behavioral change as well. Habits are not easy to break and re-learn, but your team members may be more receptive when they feel part of the process. Research by McKinsey shows brands that empower employees to generate their own ideas are <a href="https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/Organization/Our%20Insights/Unlocking%20success%20in%20digital%20transformations/Unlocking-success-in-digital-transformations.ashx" target="_blank">1.4 times more likely</a> to report successful digital transformations. Ask for input from stakeholders who interact with customers on a daily basis about where technology could introduce efficiencies or unlock opportunities to delight customers. 
                </p>
                <h2 className="my-4">Expand with intention</h2>
                <p>
                  For CX leaders looking to bring about digital transformation, there are now <a href="https://chiefmartec.com/2019/04/marketing-technology-landscape-supergraphic-2019/" target="_blank">more than 7,000</a> marketing technology solutions to choose from. And with increasing expectations both internally and externally, it can be tempting to go all-in with new technology. However, organizations that <a href="https://www.mckinsey.com/business-functions/mckinsey-digital/our-insights/five-moves-to-make-during-a-digital-transformation" target="_blank">report</a> successful transformation processes are more likely to have focused instead on a few digital changes that align with clear business objectives. Starting with company-wide goals and working backward when evaluating new technologies will ensure your efforts stay focused.
                </p>
                <div className="container-fluid px-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <img className="my-4 img-fluid" src={require("../../assets/images/blog/In-post-image-2.png")} alt="" />
                    </div>
                  </div>
                </div>
                <h2 className="my-4">Integrate your technology</h2>
                <p>
                  Customers expect a consistent experience with your brand, regardless of the number of channels or people involved. As you adopt new technology, connecting your systems so that team members have easy access to the same information can help your brand deliver on this expectation.
                  According to Adobe’s 2019 Digital Trends report, companies with an integrated tech stack are <a href="https://www.adobe.com/content/dam/acom/en/modal-offers/econsultancy-digital-trends-2019/pdfs/econsultancy-2019-digital-trends_US.pdf" target="_blank">2.3 times more likely</a> to have outperformed their business goals.
                </p>

                <h2 className="my-4">CX Realities and Strategies in 2020 and Beyond</h2>
                <p>Customer communication has gotten easier, more prolific, but in some ways, also more transactional. Customer service calls have been replaced by automated systems and customer service chats. This transformation puts the onus on customer support teams to deliver top notch written communication.</p>


                <p><strong>Download our latest ebook, <em>Four Strategies to Maximize Customer Value in 2020 & Beyond</em></strong> to learn how to navigate the complexity brought on by technology and digital transformation.</p>

                {/* <div className="container-fluid px-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <img className="my-4 img-fluid" src={require("../assets/images/blog/CTA.png")} alt="" />
                    </div>
                  </div>
                </div>

                <h3 className="text-center">Learn more about how you can integrate your CX tech stack, and other strategies for CX leaders.</h3>
                <p className="text-center">Check out our new ebook, How to Thrive in the Age of Customer Experience.</p> */}
                <Link to="/customer-experience-ebook?utm_source=content&utm_medium=technology_blog&utm_campaign=customer_experience">
                  <button className="ghost-btn mt-2 d-flex mx-auto mb-5">Download the Ebook</button>
                </Link>

                
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" 
                      className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TechnologyCXGoals;
