const config = {
  apiKey: "AIzaSyAfY4Q3oH9ibZ6JOgl1Gbs-so2XsbqVdY0",
  authDomain: "apparentgrammarlyenterprise.firebaseapp.com",
  databaseURL: "https://apparentgrammarlyenterprise.firebaseio.com",
  projectId: "apparentgrammarlyenterprise",
  storageBucket: "apparentgrammarlyenterprise.appspot.com",
  messagingSenderId: "371412077172",
  appId: "1:371412077172:web:25ff1cb2765a31bc099f21"
};

// const config = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.FIREBASE_DATABASE_URL,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID
// }

let firebaseInstance
const getFirebase = firebase => {
  if (firebaseInstance) {
    return firebaseInstance
  }

  firebase.initializeApp(config)
  firebaseInstance = firebase

  return firebase
}
export default getFirebase
