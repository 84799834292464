import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class ConsistencyCriticalWorkCommunication extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page">
        <Helmet>
          <link rel="canonical" href="https://www.grammarly.com/blog/consistency-critical-to-work-communication/" />
        </Helmet>
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>How Consistency Is Critical to Work Communication</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/consistency-critical-to-work-communication/how-consistemcy-is-critical-hero.jpg")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/consistency-critical-to-work-communication/how-consistemcy-is-critical-hero.jpg")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>October 12, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                
                <p className="IntroPara">Excellent communication isn’t just a top skill needed in multiple roles across an organization. It’s the cornerstone of a good brand.</p>

                <p>Great work communication uses consistent, clear, and engaging language. If you can communicate your message consistently and directly in your writing, you will get buy-in from colleagues, engagement from customers, and accomplish more in your career.</p>

                <p>Roles today are increasingly distributed and cross-functionally collaborative, which can make work communication more complicated. Working with a team in a different time zone, learning internal buzz words, and writing messages for people outside your organization make it challenging to keep everything consistent.</p>

                <p>Grammarly Premium includes features that can save you time and effort by keeping your punctuation, dates, and style consistent. But consistency in work communication is so much more—it’s as much <Link to="/blog/ensure-consistent-branding-with-style-guides" >an exercise in branding</Link> as it is in writing. Here’s how consistency contributes to your (and your organization’s) success.</p>

                <h2 className="my-4">Put your brand language on autopilot</h2>
                <p>Whether you’re a brand steward for your company or keen on developing a strong personal brand at work, consistency is vital. At the bare minimum, your written communications should include correct spelling, capitalization, and punctuation. Nailing these details says that you are thoughtful about your work. But don’t stop there. There's an opportunity to ensure that terms and language specific to your brand are consistent throughout your emails, sales materials, and presentations. Your company name, executive team members, product features, or event titles, for instance, are high stakes. Any inconsistencies in key branded terms can look unprofessional and can chip away at hard-earned <Link to="/blog/customer-trust-consistency">customer trust</Link>.</p>

                <p>Grammarly Business recently introduced <Link to="/blog/grammarly-business-style-guide" >style guides</Link>, a solution to align teams and keep their branded terms consistent in communication.</p>

                <h2 className="my-4">Save time and energy</h2>
                <p>Raise your hand if you work in a role that relies heavily on written communication. For those who must write, edit, and audit brand messages, using a full-featured digital writing assistant for work communication is invaluable. Most marketing, recruitment, and sales teams don’t have access to a full-time editor that can review writing before it’s sent or published.</p>

                <p>Grammarly Premium and Grammarly Business are more cost-effective than hiring a full-time editor. By leveraging features that promote brand consistency, you can save time, effort, and money.</p>

                <h2 className="my-4">Produce work that supports your objectives</h2>
                <p>Consistency in our work communication can lead to positive results over time, especially for customer-facing roles like sales, customer service, or marketing. If you work in a customer success role, for example, the way you communicate reflects your company, the brand, and business objectives. If you have a team objective to increase customer satisfaction by 10 percent this quarter, then delivering kind, well-written customer resolution tickets can make a big difference in achieving it. You can lean on the Grammarly tone detector and <a href="https://www.grammarly.com/blog/set-goals-grammarly-editor/" target="_blank">set goals</a> by specifying the formality, tone, and intent of your message. Moreover, when you and your team use consistent language that engages customers and is free of internal jargon, you can create an authentic, brand-forward experience across channels.</p>

                <p>Learn more about <Link to="/styleguide" >Grammarly Business style guides</Link> and how this feature can align your team and brand.</p>
                
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ConsistencyCriticalWorkCommunication;
