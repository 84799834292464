import React, { useEffect } from 'react'

const PardotContactForm = () => {
    useEffect(() => {
        
        const form = 'https://go.grammarly.com/l/894581/2020-10-29/6yl';
        // const form = 'https://go.grammarly.com/l/894581/2020-11-22/h52';
        const params = window.location.search;
        const thisScript = document.getElementById("pardot-contact-form");
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', form + params);
        iframe.setAttribute('width', '100%');
        iframe.setAttribute('height', 950);
        iframe.setAttribute('type', 'text/html');
        iframe.setAttribute('frameborder', 0);
        iframe.setAttribute('allowTransparency', 'true');
        iframe.style.border = '0';
        thisScript.parentElement.replaceChild(iframe, thisScript);
      
        document.body.appendChild(thisScript);
      
        return () => {
          document.body.removeChild(thisScript);
        }
    }, []);

    return (
    <>
        {/* <iframe src="https://go.grammarly.com/l/894581/2020-10-29/6yl" width="100%" height="500" type="text/html" frameBorder="0" allowtransparency="true" style={{border: 0}} id="myiFrame"></iframe> */}

        <noscript>
        <iframe src="https://go.grammarly.com/l/894581/2020-10-29/6yl" width="100%" height="950" type="text/html" frameborder="0" allowTransparency="true" style="border: 0"></iframe>
        </noscript>
        <div id="pardot-contact-form"></div>
    </>
    )
}

export default PardotContactForm;
