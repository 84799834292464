import React from 'react'
import Layout from "../../components/Layout"
import CallToAction from '../../components/Button'
import { Link } from "react-router-dom"

import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

const Service = () => {
  const history = useHistory();
  const registerGAPageViewCustom = (e) => {
    e.preventDefault();
    
    var redirectLocation = "/";
    if( e.target.getAttribute('href') ){
      redirectLocation = e.target.getAttribute('href');
    }
    redirectLocation = redirectLocation + window.location.search;
    
    ReactGA.pageview(redirectLocation);
    history.push( redirectLocation );
  }

  return (
  <Layout className="customer-service-landing-page">
    <section className="banner-section">
      <div className="banner">
        <div className="banner-items">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="card-title heading-type-1">Built for Teams, Good for Customers</h1>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="card-text p-type-1">Deliver a five-star customer experience seamlessly across channels.</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CallToAction
                  to="https://grammarly.com/contact/sales?source=enterprise"
                  eType="See-Demo"
                  eName="service-team"
                  eLabel="enterprise-service-team-see-demo"
                  className="serv1seedem"
                >
                  Get in touch
                </CallToAction>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-grey testimonial-1">
      <div className="container">
        <div className="d-flex flex-row flex-sm-column flex-column flex-md-row flex-xl-row">
          <div className="p-2 fb-img-block">
            <img src={require('../../assets/images/Module2_UI.gif')} alt="" />
          </div>
          <div className="p-2 align-self-stretch mt-4 text-center text-md-left">
            <blockquote className="blockquote">
              <img className="inverted-comma" src={require('../../assets/images/double-inverted-comma.png')} alt="" />
                <p className="blockquote-body">Grammarly Business has helped our team create the best first impression with our customers, which has led to us having more impact and credibility when resolving critical issues.”</p>
                <footer className="blockquote-footer-1">
                  <cite>
                    <span className="author mb-2">Sean Scanlon</span>
                    <span className="position">Global Escalation Manager - Pivotal</span>
                  </cite>
                </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>

    <div className="sticky-container-holder">

      <section className="help-your-team">
        <div className="container">

          <div className="row text-center hyt-img-header">
            <div className="col-md-12 hyt-img-section">
              <img src={require('../../assets/images/CS_PopOut_Icon.png')} alt="" />
                <h2 className="heading-type-4">High-stakes operations require sharp communication. According to Gartner, 87% of companies are investing in communication skills for their teams.<sup>1</sup></h2>
            </div>
          </div>

          <div className="row">
            <div className="w-100 hr-line" />
          </div>

          <div className="row text-left text-md-center hyt-sub-header">
            <div className="col-md help-team mt-3 mt-md-0 ">
              <h2 className="heading-type-2">A tailored solution for customer-facing teams</h2>
              <p>What does success look like? A deep understanding of your customers, a clear purpose, and, most importantly, a remarkable brand experience delivered consistently across touchpoints.</p>
            </div>
          </div>

          <div className="row help-team">
            <div className="col-md">
              <h3>Set your team up for success</h3>
              <p>50% of consumers say that unclear communication is the biggest barrier to a positive customer experience. Give your team the tools to communicate with empathy and accuracy everywhere they write. Grammarly Business can help reduce writing missteps by 74%.</p>
            </div>

            <div className="col-md">
              <h3>Accelerate operations</h3>
              <p>Grammarly Business supports thousands of applications and works within your existing tools and platforms. Improve workflow efficiency for all team members without disruptions or learning curves.</p>
            </div>

            {/* <div className="col-md">
              <h3>Reduce the back-and-forth</h3>
              <p>Clear responses from service staff ensure they get their message across first time when resolving support tickets. This vastly improves customer experience and satisfaction.</p>
            </div> */}

            <div className="col-md">
              <h3>Build customer trust</h3>
              <p>Your team communicates with new and existing customers every day. It’s imperative to use consistent grammar, tone, and branded language. Grammarly Business has features like custom style guides to help you deliver consistent interactions every time.</p>
            </div>
          </div>

          <div className="row text-center d-none d-md-block">
            <div className="col-sm">
              {/* <Link to="/customer-experience-ebook" onClick={registerGAPageViewCustom} className="btn btn-primary btn-eBook">Get Grammarly</Link> */}
              <CallToAction
                  to="https://grammarly.com/contact/sales?source=enterprise"
                  eType="See-Demo"
                  eName="service-team"
                  eLabel="enterprise-service-team-see-demo"
                  className="btn btn-primary btn-eBook serv1seedem"
                >
                  Get Grammarly
              </CallToAction>
            </div>
          </div>

        </div>
      </section>

      <section className="section-grey testimonial-2">
        <div className="container continer-show-dots">
          <div className="row">
            <div className="col-md">
              <blockquote className="blockquote text-center">
                <img className="inverted-comma" src={require('../../assets/images/double-inverted-comma.png')} alt="" />
                  <p className="blockquote-body">We pride ourselves on having a diverse, multi-cultural team from around the world. But that also comes with a set of challenges—different languages, styles, culture. Grammarly Business ensures that everything we write is clear and understood.” </p>
                  <footer className="blockquote-footer-1">
                    <cite>
                      <span className="author mb-2">Milton Lee</span>
                      <span className="position">Global Program Manager at Uber</span>
                    </cite>
                  </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section className="why-service-team">
        <div className="container container-circle">
          <div className="row">
            <div className="col text-left text-md-center">
              <h2 className="heading-type-3">Features to Boost Productivity and Performance</h2>
            </div>
          </div>
          <div className="row card-row">
            <div className="col-md">
              
              <img src={require('../../assets/images/consistent_icon.png')} alt=""
                   className="img-fluid"/>
              <h3>Custom Style Guides</h3>
              <p>Stay on brand. Create a real-time source for truth for all writing and language across your organization.</p>
            </div>
            <div className="col-md">
              <img src={require('../../assets/images/real_time_qa_icon.png')} alt="" className="img-fluid"/>
              <h3>Tone Detection</h3>
              <p>Your brand tone is critical when speaking to customers. Grammarly can detect over 40 different tones.</p>
            </div>
            <div className="col-md">
              <img src={require('../../assets/images/integrated_icon.png')}  alt="" className="img-fluid"/>
              <h3>Actionable Reports</h3>
              <p>Track performance, monitor usage, and leverage centralized billing with admin controls.</p>
            </div>
          </div>
          <div className="row d-none d-md-block">
            <div className="col text-center">
              <CallToAction
                to="https://grammarly.com/contact/sales?source=enterprise"
                eType="Lets-talk"
                eName="service-team"
                eLabel="enterprise-service-team-lets-talk"
                className="serv3letstalk"
              >
                Let's Talk
              </CallToAction>
            </div>
          </div>
        </div>
      </section>

      <div className="col-12 px-0 sticky-bottom d-md-none sb-custom">
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <CallToAction
                  to="https://grammarly.com/contact/sales?source=enterprise"
                  className="d-md-none serv4letstalk"
                  eType="Lets-talk"
                  eName="service-team"
                  eLabel="enterprise-service-team-lets-talk"
                >
                  Let's Talk
                </CallToAction>
              </div>
            </div>
          </div>
        </section>
        
      </div>

      <section className="citation">
        <div className="container">
          <div className="row">
            1.&nbsp;<a className="" href="#">Gartner – 2018 Resource Allocation Benchmarking Report</a>
          </div>
        </div>
      </section>

    </div>
  </Layout>
);
}

export default Service;
