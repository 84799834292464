import React from "react";
import CallToAction from "../../components/Button";
import $ from "jquery";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";

class CaseStudyHome extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="new-home-landing-page frost-sullivan-landing-page">
        <div className="container-fluid px-0">
          <img className="img-fluid d-md-none" src={require("../../assets/images/case-study/Header-IMG@2x.png")} alt=""/>
          <div className="headerContainer">
            <div className="container px-0">
              <div className="row">
                <div className="col-md-7">
                  <h6 className="pre-header">CASE STUDY</h6>
                  <h1>How Frost & Sullivan cut editing time by 66%</h1>
                  <p className="py-md-3 pr-md-5">Using Grammarly Business, Frost & Sullivan increased their writing team’s productivity. The firm cut editing time for client stories from 3 hours to 1.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-5 mb-5 mb-md-0">
                <img className="img-fluid" src={require("../../assets/images/case-study/500-Plus@2x.png")} />
              </div>
              <div className="col-md-5">
                <img className="img-fluid" src={require("../../assets/images/case-study/17M-Plus@2x.png")} />
              </div>
            </div>
            <div className="row mt-3 mt-md-5 pt-3 pt-md-5">
              <div className="col-12">
                <h3>The Challenge</h3>
                <p className="pt-4">
                  At Frost & Sullivan, Tonya Fowler's team is responsible for putting together crafted research reports on some of the world’s most innovative and sophisticated companies. These reports require a large amount of writing, which means there’s much to write, edit, and review. Although Tonya
                  focuses on hiring the best possible writers, good editing remains essential to ensuring the highest quality material and time savings. Tonya had developed a peer review process, but this process took substantial time and was not always objective.
                </p>
              </div>
            </div>
          </div>
          <div className="QuoteContainer">
            <div className="container px-0">
              <div className="row">
                <div className="col-md-6">
                  <h3>"Our team members interact with CEOs and have discussions about what’s happening in the market. Our challenge is to create an engaging piece of marketing material that is a strong balance of research and marketability."</h3>
                  <h6 className="quote-author mt-5 mr-2 mr-md-0 pr-4 pr-md-0">
                    Tonya Fowler, Frost & Sullivan
                    <br />
                    Global Research Vice President of Best Practice
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-3 mb-md-5 pb-4 pb-md-5">
            <div className="row">
              <div className="col-12">
                <h3>The Solution</h3>
              </div>
            </div>
            <div className="row mt-4 mb-3 mb-md-5 pb-3 pb-md-5">
              <div className="col-12">
                <p>
                  Once Tonya found Grammarly Business, she implemented it instantly, asking her writing team to install and use the platform. Tonya made using Grammarly Business a mandatory requirement. This ensured that every writer on her team was getting the same feedback. Grammarly Business
                  acted as an editing assistant for Tonya and her team.
                </p>
                <p>
                  "If one of our writers is constantly making the same mistake, such as using passive voice too often, Grammarly Business catches this on our behalf and reminds the writer,” said Tonya. “In this way, Grammarly Business acts as a teacher, which removes that burden from me and the peer
                  editors."
                </p>
              </div>
            </div>
            <div className="row justify-content-center mt-3 mt-md-5">
              <div className="col-md-8">
                <h3>
                  Our team members interact with CEOs and have discussions about what’s happening in the market. <span className="green-highlight gh-d-mob">Our challenge is to create an engaging piece of marketing material that is a strong</span>
                  <span className="green-highlight gh-d-ipad">Our challenge is to create an</span> <span className="green-highlight gh-d-ipad">engaging piece of marketing material that is</span> <span className="green-highlight gh-d-ipad">a strong</span>
                  <span className="green-highlight gh-d-lg-ipad">Our challenge is to</span> <span className="green-highlight gh-d-lg-ipad">create an engaging piece of marketing material that is a</span> <span className="green-highlight gh-d-lg-ipad">strong</span>
                  <span className="green-highlight gh-d-large-screen">Our challenge is to create</span> <span className="green-highlight gh-d-large-screen">an engaging piece of marketing material that is a strong</span> balance of research and marketability.”
                </h3>
              </div>
            </div>
          </div>

          <div className="green-container my-3 my-md-5 py-3 py-md-5">
            <div className="container py-5">
              <div className="row">
                <h3>The Results</h3>
                <p className="mt-3">
                  Thanks to Grammarly Business, Tonya and her team were able to cut editing time substantially. No matter how skilled a writer is, they need good editors that not only catch simple mistakes, but also make sure the piece of writing is easy to read. Grammarly Business made this process
                  more efficient for Tonya and her team.
                  <br />
                  <br />
                  Thanks to time saved editing, Tonya’s team was able to increase output. Tonya’s team is tasked with creating many client stories in a given year. The more they create, the better it is for the business, but only if these stories are extremely high quality.
                </p>
              </div>
            </div>
          </div>
          <div className="container my-3 my-md-5 py-4 py-md-5">
            <div className="row text-center">
              <div className="col-12">
                <h3>Keys to Success</h3>
              </div>
              {/* <div className="col-12 mt-3 mb-5">
                <p>Lorem ipsum ad infinitum, lorem ipsum ad infinitum.</p>
              </div> */}
            </div>
            <div className="row mt-3 mt-md-5">
              <div className="col-md">
                <img src={require("../../assets/images/case-study/Icon-Bigger-05.png")} alt="" />
                <div className="mt-5"><span className="heading-type-3">Make Grammarly a mandatory part of the process.</span></div>
                <p className="mt-4">
                  For Frost & Sullivan’s research team, Grammarly isn’t a “nice-to-have” tool. Instead, it’s an essential part of the process. Every writer on Tonya’s team is required to use Grammarly, and a performance score is used to ensure each piece of writing meets quality requirements before
                  the peer review process.
                </p>
              </div>
              <div className="col-md mt-4 mt-md-0">
                <img src={require("../../assets/images/case-study/Icon-Bigger-01.png")} alt="" />
                <div className="mt-5"><span className="heading-type-3">Implement a performance standard.</span></div>
                <p className="mt-4">
                  To reach her goal of cutting back editing time, Tonya asked her team members to use Grammarly Business for every piece of writing they craft. If Grammarly Business presented anything less than a performance score of 80, writers needed to edit their own work before sending it out
                  for peer review. If an editor received a piece of writing with a Grammarly Business score that was less than 80, they were to send it back to the original writer.
                </p>
              </div>
              <div className="col-md mt-4 mt-md-0">
                <img src={require("../../assets/images/case-study/Icon-Bigger-03.png")} alt="" />
                <div className="mt-5"><span className="heading-type-3">Maintain a culture of positive learning.</span></div>
                <p className="mt-4">
                  No matter where we are in our writing journeys, there are always opportunities to improve. Building a culture of learning can help keep team members engaged and motivated. For Frost & Sullivan, Grammarly Business acted as a teaching tool, reminding writers of when they were using
                  the passive voice or repeating words too often. Additionally, it helped the team give and receive feedback. Grammarly’s tone detector tool makes it easier for Tonya to remind team members about what is subjective editing, and what is not.
                </p>
              </div>
            </div>
          </div>
          <div className="purple-container py-5">
            <div className="container py-5">
              <div className="row text-center">
                <div className="col-12">
                  <h1>Help Your Team Do Their Best Work</h1>
                  <p className="mb-5 mt-4">Get started today and instantly elevate the writing skills of your entire team.</p>
                  {/* <button className="green-btn mt-5">Speak To Us Today</button> */}
                  {/* <Link to="/" className="green-btn mt-5">Speak To Us Today</Link> */}
                  <CallToAction to="https://www.grammarly.com/contact/sales?source=enterprise" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto">
                    Get in touch
                  </CallToAction>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container py-5">
            <div className="row py-5 text-center">
              <div className="col-12">
                <h3>Read More Customer Stories</h3>
              </div>
            </div>
            <div className="row mt-5 text-center">
              <div className="col-md">
                <img src={require("../../assets/images/newHome/Slack@2x.png")} alt="" />
                <p className="px-5 mt-4">Lorem ipsum ad. Lorem ipsum ad infinitum.</p>
              </div>
              <div className="col-md">
                <img src={require("../../assets/images/newHome/zendesk-1@2x.png")} alt="" />
                <p className="px-5 mt-4">Lorem ipsum ad. Lorem ipsum ad infinitum.</p>
              </div>
              <div className="col-md">
                <img src={require("../../assets/images/newHome/Freshdesk-1@2x.png")} alt="" />
                <p className="px-5 mt-4">Lorem ipsum ad. Lorem ipsum ad infinitum.</p>
              </div>
            </div>
          </div> */}
        </div>
      </Layout>
    );
  }
}

export default CaseStudyHome;
