import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import ServiceForm from "../../components/Form";

class BlindCaseStudy extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="zapier-case-study-page blind-case-study-page">
        <div className="container-fluid px-0">
          
          <div className="headerContainer">
            <div className="container px-lg-0">
              <div className="row">
                <div className="col-lg-9  order-2 order-lg-1">
                  <h6 className="pre-header">CASE STUDY</h6>
                  <h1 className="pr-md-1">How a leading global BPO improved CSAT by 17% through clear customer communication</h1>
                  <p className="py-md-3 pr-md-5">Using Grammarly Business, a global Business Process Outsourcing (BPO) company increased the quality of its customer service, as well as its efficiency. The team improved its CSAT score, tickets responded to per hour, and the number of contacts per solved ticket.</p>
                </div>
                <div className="col-lg-3 p-lg-0 order-1 order-lg-2">
                  <div className="h-100 d-flex justify-content-left justify-cintent-lg-center align-items-center ">
                    <img className="img-fluid mb-4 mb-lg-0 banner-hero-image" src={require("../../assets/images/case-study/blind-case-study/hero-image.png")} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-0 mt-lg-4">
            <div className="row">
              <div className="col-12">
                <p className="IntroPara">
                  An established outsourcing provider sought to up-level the customer experience on behalf of their clients, understanding that communication would be key. 
                </p>
                <p>
                  The company was seeing a high volume of support requests on a daily basis spanning live chat, email, and a number of other channels. With 19 locations, they served a wide range of industries, from finance to telecommunications, to delivery retailers. 
                </p>
                <p>
                  After fielding complaints from clients and their customers about communication quality, the business process outsourcing company decided to invest in Grammarly Business for the team. By implementing the tool for 250 of its customer service agents, the company experienced substantial results.
                </p>
              </div>
            </div>

            <div className="row my-5 tilesContainer">
              <div className="col-md-4 mb-5 mb-md-0">
                <div className="row h-100">
                  <div className="col-12">
                    <div className="green-container container-type-2-custom rounded d-flex h-100">
                      <div className="txt-section">
                        <h1>5M</h1>
                        <div className="txt-copy mw-xl-75-custom">
                          <h3 className="">Client customers served</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <div className="row h-100" >
                  <div className="col-12">
                    <div className="blue-container container-type-2-custom rounded d-flex h-100">
                      <div className="txt-section">
                        <h1>323K</h1>
                        <div className="txt-copy">
                          <h3 className="">Contacts per month</h3>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row h-100">
                  <div className="col-12">
                    <div className="purple-container container-type-2-custom rounded d-flex h-100">
                      <div className="txt-section">
                        <h1>250</h1>
                        <div className="txt-copy">
                          <h3 className="">Team members using Grammarly Business</h3>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 pt-3 ">
              <div className="col-12">
                <h3>The Challenge</h3>
                <p className="pt-4">
                  When clients’ customers took to social media to complain about a lack of professionalism in their support experience, a global BPO was faced with unhappy clients, and in some cases, monthly penalties. The company knew there was an opportunity to focus on improving communication.
                </p>
                <p>
                  As a global organization, many agents weren’t native English speakers. While they had a relatively high mastery of English, there are extra steps that non-native speakers sometimes need to take to ensure their message comes across as they intend. Lack of tools and time constraints can make these extra steps challenging. 
                </p>
                <p>
                  The client services team tried several options to address these challenges. First, they tried canned responses. These worked to a degree but were not a perfect solution. Customers felt they were talking to a robot, and often these canned responses weren’t appropriate for complex customer support questions. Managers encouraged team members to adjust the canned responses, but agents struggled to make adjustments that were clear to customers.
                </p>
              </div>
            </div>
          </div>
          <div className="QuoteContainer">
            <div className="container ">
              <div className="row">
                <div className="col-md-12 ">
                  <h3 class="pr-lg-4">Thanks in part to Grammarly Business, the organization was able to improve the CSAT score by 17%. The team achieved these results by improving efficiency and polishing communication.</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-3 mb-md-5 pb-4">
            <div className="row mt-3">
              <div className="col-12">
                <h3>The Solution</h3>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <p>
                  The company discovered Grammarly Business through an individual who had been experimenting with Grammarly on their own. This individual noticed that Grammarly was a huge help in accomplishing daily tasks such as writing emails and creating presentations and thought the tool could benefit the broader team
                </p>
                <p>
                  The global BPO company rolled out Grammarly Business to 250 customer support agents, who used it to ensure that adjustments to canned responses would be clear to customers. 
                </p>
                <p>
                  Grammarly Business helped these agents catch spelling mistakes, adjust grammar and syntax, and write in a friendly tone. It also functioned as a teaching tool, which saved substantial time for managers who were helping coach their agents. Not only did Grammarly Business show agents when they made a mistake, but it also provided guidance about how to fix it.
                </p>
              </div>
              
            </div>
            

          </div>
          
          <div className="green-container my-3 my-md-5 py-3 py-md-5">
            <div className="container py-4">
              <div className="row pr-xs-15p-custom">
                <div className="col-12">
                  <h3>The Results</h3>
                  <div className="tiles-section tiles-section-type-2-custom">
                    {/* addition of box   */}
                    <div className="row my-5 tilesContainer">
                      <div className="col-md-3 mb-5 mb-md-0 pr-xl-0">
                        <div className="row h-100">
                          <div className="col-12">
                            
                            <div className="green-container container-type-2-custom rounded d-flex h-100">
                              <div className="txt-section">
                                <h1>17%</h1>
                                <div className="txt-copy ">
                                  <h3 className="">Improvement in CSAT score</h3>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mb-5 mb-md-0 pr-xl-0">
                        <div className="row h-100">
                          <div className="col-12">
                            
                            <div className="green-container container-type-2-custom rounded d-flex h-100">
                              <div className="txt-section">
                                <h1>12%</h1>
                                <div className="txt-copy ">
                                  <h3 className="">Reduction in the number of contacts per ticket</h3>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mb-5 mb-md-0 pr-xl-0">
                        <div className="row h-100">
                          <div className="col-12">
                            
                            <div className="green-container container-type-2-custom rounded d-flex h-100">
                              <div className="txt-section">
                                <h1>74%</h1>
                                <div className="txt-copy ">
                                  <h3 className="">Reduction in tickets with spelling or grammar issues</h3>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mb-5 mb-md-0 pr-xl-0">
                        <div className="row h-100">
                          <div className="col-12">
                            
                            <div className="green-container container-type-2-custom rounded d-flex h-100">
                              <div className="txt-section">
                                <h1>22%</h1>
                                <div className="txt-copy ">
                                  <h3 className="">More tickets responded to per hour</h3>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* addition of box   */}
                  </div>

                  <p className="mt-3">
                    By measuring quality scores for the customer support experience throughout this process, the company was able to see the impact of Grammarly Business. The most important needle to move? The Customer Satisfaction Score (CSAT), which measures how happy customers are with the overall support experience.
                  </p>
                  <p>
                    Thanks in part to Grammarly Business, the organization was able to improve the CSAT score by 17%. The team achieved these results by improving efficiency and polishing communication.
                  </p>
                  <p>
                  The client operations team saw a 74% reduction in tickets with spelling or grammar issues, resulting in more polished communication. 
                  </p>
                  <p>
                    Because tickets could be edited by Grammarly Business, rather than another agent, 22% more tickets were responded to each hour. Additionally, the company saw a 12% reduction in the number of contacts per ticket, which indicates that customer support agents were better able to solve customer issues. In combination, this represented a massive gain in productivity. As a result of the polished, professional communication, the overall CSAT score improved by 17%.
                  </p>
                  <p class="mb-0">
                    The global BPO and its customer support team were able to improve the overall customer experience by leveraging Grammarly Business. Now, they are able to deliver a better product to their clients through clear communication and increased productivity.
                  </p>
                </div>
              </div>
            
            </div>
          </div>
          <div className="container my-3 my-md-5 py-4  key-to-success-section">
            <div className="row text-center">
              <div className="col-12">
                <h3>Keys to Success</h3>
              </div>
            </div>
            <div className="row mt-3 mt-md-5">
              <div className="col-md-4">
                <img src={require("../../assets/images/case-study/blind-case-study/icon-1.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Emphasize good grammar as a means for better communication.</span>
                </div>
                <p className="mt-4">The company understood that grammatical issues and spelling mistakes were damaging the customer support experience and, by extension, the representation of its clients. By improving spelling and grammar in customer support tickets, this global BPO  had happy customers who were able to get their questions answered faster.</p>
              </div>
              <div className="col-md-4 mt-4 mt-md-0">
                <img src={require("../../assets/images/case-study/blind-case-study/icon-2.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Equip your team with tools to increase productivity.</span>
                </div>
                <p className="mt-4">
                  Team members want to be efficient, but they need tools to support them. By implementing  Grammarly Business, the company not only helped improve written communications but also increased productivity. Team members now advocate for Grammarly internally, and new team members want to use it immediately.
                </p>
              </div>
              <div className="col-md-4 mt-4 mt-md-0">
                <img className="" src={require("../../assets/images/case-study/blind-case-study/icon-3.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Measure incremental improvements.</span>
                </div>
                <p className="mt-4">Before the global BPO began using Grammarly Business, they tracked a variety of metrics such as CSAT scores, number of tickets per contact, and how many tickets each agent responded to per hour. After implementing Grammarly Business, they were able to measure incremental improvements, which showed massive ROI.</p>
              </div>
            </div>
          </div>
          <div className="blue-container pb-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      {/* <a href="#"  
                      className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction> */}
                      <CallToAction to="https://www.grammarly.com/contact/sales?source=enterprise" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto">
                        Get in touch
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default BlindCaseStudy;
