import React from "react";
import Layout from "../components/Layout";
import CallToAction from "../components/Button";
import ebookFile from "../assets/pdf/Grammarly_eBook.pdf";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import {saveAs} from "file-saver";

class Ebook extends React.Component {
  
  constructor() {
    super()
    this.UTMSource = ""
    this.UTMSourceFlag = false
    this.getURLQuery();

    this.state = {
        retUrl: "https://grammarly-enterprise.com/thankyou-ebook",
    };
  }

  getURLQuery() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('utm_source')){
      this.UTMSource = urlParams.get('utm_source');
      this.UTMSourceFlag = true;
    }
    else{
      this.UTMSource = "";
    }
  }

  verifyCaptcha(value) {
    if (value != "") {
      $("#FormSubmit").removeClass("d-none");
      $("#FormSubmit").addClass("d-flex");
    } else {
      $("#FormSubmit").removeClass("d-flex");
      $("#FormSubmit").addClass("d-none");
    }
  }

  submitForm(e) {
    saveAs(ebookFile, "Grammarly_eBook.pdf");
    // e.preventDefault();
  }

  render() {
    return (
      <Layout className="ebook-landing-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
              <section className="py-4 py-lg-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 pr-lg-0">
                      <h1 className="font-family-circular font-weight-bold heading-txt">Closing the Communication Gap</h1>
                      <h2 className="font-family-AkkuratPro font-subheader">How to Set the Modern Workforce up for Success</h2>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section-grey pb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-6 text-center d-none d-md-block h-100 align-self-center">
                      <div className="py-3 py-lg-0 text-center form-container text-lg-left h-100">
                        
                        {/* <form className="pt-4 pb-4" id="pardot-form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                          <input type={"hidden"} name="oid" value="00D80000000d8Lr" />
                          <input type={"hidden"} name="retURL" value={this.state.retUrl} />
                          <input type={"hidden"} name="lead_source" value={"Workforce Success eBook"} />
                          <input type={"hidden"} id="00N80000003lP66" name="00N80000003lP66" title="License Type" value="Volume License"/>
                          <input type={"hidden"} id="00N3c000006cghu" name="00N3c000006cghu" title="utmSource" value={this.UTMSource}></input>
                          <input type={"hidden"} id="00N3c000006wQW2" maxLength="255" name="00N3c000006wQW2" size="20"  />

                          <div className="form-elements pb-2">
                            <input id="first_name" maxLength="40" name="first_name" placeholder="First Name" size="20" type="text" required />
                            <br />

                            <input id="last_name" maxLength="80" name="last_name" placeholder="Last Name" size="20" type="text" required />
                            <br />

                            <input id="company" maxLength="40" name="company" placeholder="Company Name" size="20" type="text" required />
                            <br />

                            <input id="email" maxLength="80" name="email" placeholder="Work Email" size="20" type="email" required />
                            <br />
                            <br />

                            <ReCAPTCHA sitekey="6Lcm_7EZAAAAAHKVOJZw7F0Hgg-H-FBRGTNwiPue" render="explicit" onChange={this.verifyCaptcha} />
                            <br />

                            <input id="FormSubmit" className="d-none align-self-center btn btn-primary btn-talk" type="submit" name="submit" value="Download Now" onClick={this.submitForm} />
                            <CallToAction to={ebookFile} eType="Download" id="DownloadBtn" className="py-3 px-5 d-none" eName="Customer Experience ebook" eLabel="customer-experience-ebook-download" noRouter addGAEvent="yes">
                              Download Now
                            </CallToAction>
                          </div>
                        </form>
                       */}
                        <div className="iframe-form-ebook d-flex flex-column ">
                          {/* <iframe src="https://go.grammarly.com/l/894581/2020-12-07/2chx" width="100%" height="250" type="text/html" frameborder="0" allowTransparency="true" style={{border: 0}}></iframe> */}
                          <iframe src="https://go.grammarly.com/l/894581/2021-01-19/4735i" width="100%" height="250" type="text/html" frameborder="0" allowTransparency="true" style={{border: 0}}></iframe>
                        </div>
                      </div>
                      {/* <div className="py-3 py-lg-0 text-center text-lg-left">
                    <CallToAction
                      to={ebookFile}
                      eType="Download"
                      className="py-3 px-5"
                      eName="Workforce Success eBook"
                      eLabel="workforce-success-ebook-download"
                      noRouter
                      addGAEvent="yes"
                    >
                      Download Now
                    </CallToAction>
                    <a href={require('../assets/pdf/Grammarly_eBook.pdf')} className="btn btn-primary py-3 px-5" target="_blank">Download Now</a>
                  </div> */}
                    </div>
                    <div className="col-12 col-lg-6 text-center">
                      <img src={require("../assets/images/eBook_FrontCover.png")} className="img-fluid rounded hero-cover hero-cover-static ml-0 ml-lg-3 pt-5" alt="Grammarly Ebook" />
                    </div>
                  </div>
                </div>
              </section>

              <section className="section-grey pb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-6 pb-5 pb-lg-0">
                      <p className="text-big">
                        The modern workplace is changing quickly – and so must organizations that want to accomplish their goals. Noteworthy trends include more distributed and diverse workers, an acceleration of new technologies, and rising customer and employee demands to execute tasks with speed
                        but without sacrificing quality. The result is an imperative for better communication. But there is a growing gap between the quality of most communication and what is required for success in this environment.
                      </p>
                    </div>
                    <div className="col-12 col-lg-6">
                      <p className="text-big">
                        Inside <i>Closing the Communication Gap</i>, Grammarly shares its perspective on how and why effective written communication is more important than ever before for organizations, teams, and individuals to succeed.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="py-5">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 text-lg-center">
                      <h2 className="font-family-circular font-weight-bold pb-3">Consider this:</h2>
                    </div>
                    <div className="col-6 col-lg-3 py-4">
                      <div>
                        <img src={require("../assets/images/location.png")} className="img-fluid rounded pb-3 pb-lg-4" alt="" />
                      </div>
                      <h3 className="font-weight-bold">A distributed workforce means less direct oversight</h3>
                      <p className="text-normal">
                        14% of large companies use off-site spaces.<sup>1</sup>
                      </p>
                    </div>

                    <div className="col-6 col-lg-3 py-4">
                      <div>
                        <img src={require("../assets/images/Inclusion_Icon.png")} className="img-fluid rounded pb-3 pb-lg-4" alt="" />
                      </div>
                      <h3 className="font-weight-bold">Diverse backgrounds mean different levels of readiness</h3>
                      <p className="text-normal">
                        62% of workers did not feel that college/school prepared them for writing at work.<sup>2</sup>
                      </p>
                    </div>

                    <div className="col-6 col-lg-3 py-4">
                      <div>
                        <img src={require("../assets/images/brain.png")} className="img-fluid rounded pb-3 pb-lg-4" alt="" />
                      </div>
                      <h3 className="font-weight-bold">Digital transformation means more writing (and reading)</h3>
                      <p className="text-normal">
                        28% of the work week is spent reading and answering email.<sup>3</sup>
                      </p>
                    </div>

                    <div className="col-6 col-lg-3 py-4">
                      <div>
                        <img src={require("../assets/images/brain.png")} className="img-fluid rounded pb-3 pb-lg-4" alt="" />
                      </div>
                      <h3 className="font-weight-bold">Poorly written communication means significant costs</h3>
                      <p className="text-normal">
                        Companies lose a combined $396 billion every year to time employees spend trying to understand poorly written material.<sup>4</sup>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-12 px-0 sticky-bottom d-md-none">
              <section className="section-grey">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center">
                      <div className="py-3 text-center">
                        <CallToAction to={ebookFile} download eType="Download" className="py-3 px-5" eName="Workforce Success eBook" eLabel="workforce-success-ebook-download" noRouter addGAEvent="yes">
                          Download Now
                        </CallToAction>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Ebook;
