import React from 'react';
import $ from "jquery";

import Select from 'react-select';
import countryList from '../data/country-list';


class CountryListSelect extends React.Component {
    state = {  }

    componentDidMount(){
        $(".rsdc-country-custom > input").attr("id", "country");
    }

    render() { 
        return (  
            <Select id="rs-dropdown-container-id" name="country" options={countryList} className="rs-dropdown-container-custom rsdc-country-custom"
            placeholder="Country"
            styles={{
                indicatorSeparator: () => {}, // removes the "stick"
                dropdownIndicator: defaultStyles => ({
                    ...defaultStyles,
                    color: '#8189A9',
                    '& svg': { display: 'none' }
                })
            }}
            />
        );
    }
}
 
export default CountryListSelect;