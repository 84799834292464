/**
 * Data are added sorted from new to old as per publish date 
 */
const blogLists = [
    {
      "id": "36",
      "img": "/assets/images/three-steps-combat-ineffective-communication.png",
      "title": "Three Steps to Combat the Cost of Ineffective Communication",
      "date": "2021-02-02",
      "content_show": "Communication is often an afterthought when leaders are thinking about their bottom line.",
      "author": "Grammarly",
      "publish_date_show": "February 2nd, 2021",
      "team_type": [
        "miscellaneous",
        "customer-experience",
        "marketing"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "three-steps-combat-ineffective-communication",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "35",
      "img": "/assets/images/deliver-robust-channel-experience.png",
      "title": "Deliver a robust channel experience with data and insights",
      "date": "2021-01-25",
      "content_show": "A seamless journey is the hallmark of an excellent customer experience. While many companies look to their channel strategy to deliver that seamless experience, the key actually lies in customer data.",
      "author": "Grammarly",
      "publish_date_show": "January 25th, 2021",
      "team_type": [
        "miscellaneous",
        "customer-experience",
        "marketing"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "deliver-robust-channel-experience",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "34",
      "img": "/assets/images/reorganize-your-workbench.png",
      "title": "Reorganize your workbench to unlock agent effectiveness and engagement",
      "date": "2021-01-20",
      "content_show": "Despite the reality that customers spend more with brands to which they are loyal, nearly a third of customer-facing leaders perceive customer support as an expense and lack clear frameworks for understanding the return on their investment. Considering that American companies lost $62 billion in revenue due to poor customer experiences, it’s critical for leaders to understand the vast and cumulative impact of empowering their teams to be the rockstars of the brand.",
      "author": "Grammarly",
      "publish_date_show": "January 20th, 2021",
      "team_type": [
        "miscellaneous",
        "customer-experience",
        "marketing"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "reorganize-your-workbench",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "33",
      "img": "/assets/images/cpanel-case-study.png",
      "title": "How a leading web hosting platform leveraged tech to upskill their customer support team",
      "date": "2021-01-11",
      "content_show": "Using Grammarly Business, a web hosting platform up-leveled communication skills across the customer support team and helped reduce employee burnout.",
      "author": "Grammarly",
      "publish_date_show": "January 11th, 2021",
      "team_type": [
        "miscellaneous",
        "customer-experience",
        "marketing"
      ],
      "content_type": [
        "case-studies"
      ],
      "content_info": "5mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/leading-web-hosting-platform",
      "open_newtab": "no",
      "file_info": "5 minutes"
    },
    {
      "id": "32",
      "img": "/assets/images/professional_communication_team.png",
      "title": "PDF: Professional Communication for your team",
      "date": "2021-01-11",
      "content_show": "Learn how Grammarly Business helps teams elevate their communication with smart writing features.",
      "author": "Grammarly",
      "publish_date_show": "January 11th, 2021",
      "team_type": [
        "miscellaneous"
      ],
      "content_type": [
        "pdf"
      ],
      "content_info": "PDF | 4.9MB",
      "cta_text": "DOWNLOAD",
      "cta_link": "assets/pdf/professional_communication_team.pdf",
      "cta_element_type": "a-tag",
      "open_newtab": "yes",
      "file_info": "4.9 MB"
    },
    {
      "id": "31",
      "img": "/assets/images/zoura-case-study.png",
      "title": "How a leading global enterprise software company champions the customer experience channeling soft skills",
      "date": "2021-01-06",
      "content_show": "Using Grammarly Business, the company empowered a globally diverse team to leverage empathy, tone, and clarity through written communication.",
      "author": "Grammarly",
      "publish_date_show": "January 6th, 2021",
      "team_type": [
        "miscellaneous",
        "customer-experience",
        "marketing"
      ],
      "content_type": [
        "case-studies"
      ],
      "content_info": "5mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/leading-enterprise-software-case-study",
      "open_newtab": "no",
      "file_info": "5 minutes"
    }, 
    {
      "id": "30",
      "img": "/assets/images/blind-case-study.png",
      "title": "How a leading global BPO improved CSAT by 17% through clear customer communication",
      "date": "2021-01-06",
      "content_show": "Using Grammarly Business, a global Business Process Outsourcing (BPO) company increased the quality of its customer service, as well as its efficiency. The team improved its CSAT score, tickets responded to per hour, and the number of contacts per solved ticket.",
      "author": "Grammarly",
      "publish_date_show": "January 6th, 2021",
      "team_type": [
        "miscellaneous",
        "customer-experience",
        "marketing"
      ],
      "content_type": [
        "case-studies"
      ],
      "content_info": "5mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/leading-global-bpo-case-study",
      "open_newtab": "no",
      "file_info": "5 minutes"
    }, 
    {
      "id": "29",
      "img": "/assets/images/best-practices-customer-communication.png",
      "title": "Best Practices for Customer Communication Based on 2020 Trends",
      "date": "2020-12-22",
      "content_show": "What This Year’s Communication Trends Tell Us About Customers How to Reach Customers According to 2020 Communication Trends",
      "author": "Grammarly",
      "publish_date_show": "December 22nd, 2020",
      "team_type": [
        "miscellaneous",
        "customer-experience",
        "marketing"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "Best-Practices-for-Customer-Communication-2020",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },  
    {
      "id": "28",
      "img": "/assets/images/invest-in-consistent-team-writing.png",
      "title": "Why You Should Invest in Consistent Team Writing",
      "date": "2020-11-11",
      "content_show": "Learn how consistent writing and messages can help teams deliver better experiences.",
      "author": "Grammarly",
      "publish_date_show": "November 11th, 2020",
      "team_type": [
        "miscellaneous",
        "customer-experience",
        "marketing"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/invest-in-consistent-team-writing",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },  
    {
      "id": "27",
      "img": "assets/images/hacking-cx-ebook-cover.png",
      "title": "eBook: Hacking CX",
      "date": "2020-10-23",
      "content_show": "The New Standard for Customer Communication",
      "author": "Grammarly",
      "publish_date_show": "October 23rd, 2020",
      "team_type": [
        "customer-experience"
      ],
      "content_type": [
        "blog",
        "ebook",
        "pdf"
      ],
      "content_info": "1min Read",
      "cta_text": "READ MORE",
      "cta_link": "/hacking-cx-ebook",
      "open_newtab": "no",
      "file_info": "1 minute"
    },
    {
      "id": "26",
      "img": "assets/images/Quontic.png",
      "title": "Case Study: Quontic Case Study",
      "date": "2020-10-20",
      "content_show": "How Quontic uses communication to bridge talent, innovation, and culture gaps",
      "author": "Grammarly",
      "publish_date_show": "October 20th, 2020",
      "team_type": [
        "marketing"
      ],
      "content_type": [
        "case-studies"
      ],
      "content_info": "5mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/quontic-case-study",
      "open_newtab": "no",
      "file_info": "5 minutes"
    },
    {
      "id": "25",
      "img": "/assets/images/the-value-of-live-agents-thumbnail.png",
      "title": "AI and CX: The Value of Live Agents",
      "date": "2020-10-20",
      "content_show": "Learn why this impactful pairing makes for a more successful customer experience.",
      "author": "Grammarly",
      "publish_date_show": "October 20th, 2020",
      "team_type": [
        "miscellaneous",
        "customer-experience"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/the-value-of-live-agents",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "24",
      "img": "/assets/images/upskill-more-efficient-cx-teams.png",
      "title": "Upskill Your Way to More Efficient CX Teams",
      "date": "2020-10-12",
      "content_show": "Find out how to upskill without the massive time or financial commitment.",
      "author": "Grammarly",
      "publish_date_show": "October 12th, 2020",
      "team_type": [
        "miscellaneous",
        "customer-experience"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/upskill-more-efficient-cx-teams",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "23",
      "img": "/assets/images/how-consistemcy-is-critical-hero.jpg",
      "title": "How Consistency Is Critical to Work Communication",
      "date": "2020-10-12",
      "content_show": "Consistency in work communication is as much an exercise in branding as it is in writing.",
      "author": "Grammarly",
      "publish_date_show": "October 12th, 2020",
      "team_type": [
        "miscellaneous",
        "customer-experience"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/consistency-critical-to-work-communication",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "22",
      "img": "/assets/images/637_Thumbnail_-How-Global-BPO-Ensored2X.jpg",
      "title": "How Global BPO Enshored Remains Competitive in an Evolving Market",
      "date": "2020-09-01",
      "content_show": "A leading outsourcing firm implements Grammarly Business to deliver top-notch customer experiences.",
      "author": "Grammarly",
      "publish_date_show": "September 1st, 2020",
      "team_type": [
        "miscellaneous",
        "customer-experience"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/enshored-customer-story",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "21",
      "img": "/assets/images/636_Thumbnail_Your_Customers_Don_t_Need_Another_Way_to_Get_In-Touch.png",
      "title": "Your Customers Don’t Want Another Way to Get in Touch—Here’s Why",
      "date": "2020-08-13",
      "content_show": "Learn how to preserve your brand by up-leveling your communication strategy",
      "author": "Grammarly",
      "publish_date_show": "August 13th, 2020",
      "team_type": [
        "miscellaneous",
        "customer-experience"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/customers-dont-need-another-way",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "20",
      "img": "/assets/images/thumnail-grow-communications-at-scale.png",
      "title": "Ops Leaders, Learn How to Upskill Your Teams at Scale",
      "date": "2020-08-11",
      "content_show": "Learn how to develop your team’s skills at scale while still having the bandwidth to tackle your ops goals.",
      "author": "Grammarly",
      "publish_date_show": "August 11th, 2020",
      "team_type": [
        "miscellaneous",
        "customer-experience"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/upskill-teams-at-scale",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "19",
      "img": "/assets/images/634_Thumbnail__Four_Ways_to_Accelerate_Your_Customer_Operations_Through_Communication.png",
      "title": "4 Communication Tactics You Need In Your Playbook",
      "date": "2020-08-05",
      "content_show": "Learn how top ops leaders deliver customer-centric experiences at every touchpoint of the customer lifecycle.",
      "author": "Grammarly",
      "publish_date_show": "August 5th, 2020",
      "team_type": [
        "miscellaneous",
        "customer-experience"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/four-communication-tactics-ops-playbook",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "18",
      "img": "/assets/images/mgb-video-thumbnail.png",
      "title": "Video: Meet Grammarly Business",
      "date": "2020-07-27",
      "content_show": "Learn how Grammarly Business helps organizations communicate more clearly and effectively.",
      "author": "Grammarly",
      "publish_date_show": "July 27th, 2020",
      "team_type": [
      ],
      "content_type": [
        "video"
      ],
      "content_info": "2 mins Video",
      "cta_text": "WATCH NOW",
      "cta_link": "/meet-grammarly-business",
    },
    {
      "id": "17",
      "img": "/assets/images/595-_Three_CX_Trends_in_2020_Thumbnail_1x.png",
      "title": "Blog: Three CX Trends That Will Help You Achieve Your Business Goals",
      "date": "2020-07-16",
      "content_show": "Get insight into three 2020 customer experience trends and how to incorporate them in your strategy.",
      "author": "Grammarly",
      "publish_date_show": "July 16th, 2020",
      "team_type": [
        "miscellaneous"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4 mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/3-cx-trends-to-achieve-business-goals",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "14",
      "img": "assets/images/Ensure-Consistent-Branding-with-Style-Guides.jpg",
      "title": "Blog: Three Ways to Reinforce Branding With Style Guide",
      "date": "2020-06-28",
      "content_show": "Consider these three types of branding when setting up your company style guide.",
      "author": "Grammarly",
      "publish_date_show": "June 28th, 2020",
      "team_type": [
        "marketing"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "2mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/ensure-consistent-branding-with-style-guides",
      "open_newtab": "no",
      "file_info": "2 minutes"
    },
    {
      "id": "4",
      "img": "/assets/images/Guide_to_Custom_Rules_Thumbnail.png",
      "title": "PDF: Guide to Custom Rules",
      "date": "2020-06-09",
      "content_show": "Get step-by-step guidance on getting started with company style guides from Grammarly Business.",
      "author": "Grammarly",
      "publish_date_show": "June 9th, 2020",
      "team_type": [
        "miscellaneous"
      ],
      "content_type": [
        "pdf"
      ],
      "content_info": "PDF | 2.7MB",
      "cta_text": "READ MORE",
      "cta_link": "/styleguide-custom-rules.pdf",
      "cta_element_type": "a-tag",
      "open_newtab": "yes",
      "file_info": "2.7 MB"
    },
    {
      "id": "16",
      "img": "/assets/images/How-Consistent-Communication-Retains-Customers.jpg",
      "title": "Blog: Kickstart Your Efforts to Keep Everyone On-Brand",
      "date": "2020-05-28",
      "content_show": "Learn how to set communication standards can enable consistency in these three steps.",
      "author": "Grammarly",
      "publish_date_show": "June 9th, 2020",
      "team_type": [
        "miscellaneous"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "2mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/consistent-communication-retains-customers",
      "open_newtab": "no",
      "file_info": "2 minutes"
    },
    {
      "id": "15",
      "img": "assets/images/Style-Guide-Announcement.jpg",
      "title": "Blog: Style Guide Announcement",
      "date": "2020-06-09",
      "content_show": "Deliver customized, real-time writing suggestions to your employees with this new feature.",
      "author": "Grammarly",
      "publish_date_show": "June 9th, 2020",
      "team_type": [
        "hr",
        "marketing",
        "sales",
        "customer-experience",
        "miscellaneous"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "2mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/grammarly-business-style-guide",
      "open_newtab": "no",
      "file_info": "2 minutes"
    },
    {
      "id": "2",
      "img": "assets/images/Zapier.jpg",
      "title": "Case Study: Zapier Case Study",
      "date": "2020-06-05",
      "content_show": "Learn how a software application company enabled more consistent, on-brand writing across teams.",
      "author": "Grammarly",
      "publish_date_show": "June 5th, 2020",
      "team_type": [
        "marketing"
      ],
      "content_type": [
        "case-studies"
      ],
      "content_info": "5mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/zapier-case-study",
      "open_newtab": "no",
      "file_info": "5 minutes"
    },
    {
      "id": "13",
      "img": "assets/images/Communications-Empowers-Great-Teamwork.jpg",
      "title": "Blog: Communication Empowers Great Teamwork—Here's How",
      "date": "2020-05-25",
      "content_show": "Get actionable tips to implement more effective communication norms within your teams.",
      "author": "Grammarly",
      "publish_date_show": "May 25th, 2020",
      "team_type": [
        "hr",
        "marketing",
        "sales",
        "customer-experience",
        "miscellaneous"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "3mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/communication-empowers-teamwork",
      "open_newtab": "no",
      "file_info": "3 minutes"
    },
    {
      "id": "7",
      "img": "assets/images/Pivotal.jpg",
      "title": "Blog: Pivotal Customer Story",
      "date": "2020-05-15",
      "content_show": "Learn how Pivotal uses Grammarly Business to communicate with confidence.",
      "author": "Grammarly",
      "publish_date_show": "May 15th, 2020",
      "team_type": [
        "customer-experience",
        "miscellaneous"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "2mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/pivotal-customer-story",
      "open_newtab": "no",
      "file_info": "2 minutes"
    },
    {
      "id": "1",
      "img": "assets/images/How-Frost-and-Sullivan_Case study.jpg",
      "title": "Case Study: Frost & Sullivan",
      "date": "2020-05-14",
      "content_show": "Learn how a strategy consulting firm increased its writing team’s productivity.",
      "author": "Grammarly",
      "publish_date_show": "May 14th, 2020",
      "team_type": [
        "miscellaneous"
      ],
      "content_type": [
        "case-studies"
      ],
      "content_info": "3mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/frostandsullivan-case-study",
      "open_newtab": "no",
      "file_info": "3 minutes"
    },
    {
      "id": "12",
      "img": "assets/images/The-Secret-to-Earning-Customer-Trust.jpg",
      "title": "Blog: The Secret to Earning Customer Trust Is Consistency",
      "date": "2020-05-14",
      "content_show": "Learn how consistent communication amplifies these four trust-building practices.",
      "author": "Grammarly",
      "publish_date_show": "May 14th, 2020",
      "team_type": [
        "marketing"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "4mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/customer-trust-consistency",
      "open_newtab": "no",
      "file_info": "4 minutes"
    },
    {
      "id": "9",
      "img": "assets/images/Is-Technology-Helping-or-Hurting-CX.jpg",
      "title": "Blog: Technology CX Goals",
      "date": "2020-04-28",
      "content_show": "Implement these three tactics to ensure technology is supporting your customer experience goals.",
      "author": "Grammarly",
      "publish_date_show": "April 28th, 2020",
      "team_type": [
        "customer-experience"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "2mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/technology-cx-goals",
      "open_newtab": "no",
      "file_info": "2 minutes"
    },
    {
      "id": "11",
      "img": "assets/images/Supportive-Resources-for-Leading.jpg",
      "title": "Blog: Empathetic Leadership Resource",
      "date": "2020-03-15",
      "content_show": "Ten resources that may help as you guide your team through new communication norms.",
      "author": "Grammarly",
      "publish_date_show": "March 15th, 2020",
      "team_type": [
        "hr",
        "marketing",
        "sales",
        "customer-experience",
        "miscellaneous"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "2mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/empathetic-leadership-resources",
      "open_newtab": "no",
      "file_info": "2 minutes"
    },
    {
      "id": "10",
      "img": "assets/images/Reduce-the-cost-of-communication.jpg",
      "title": "Blog: Here's How to Avoid Miscommunication with Customers",
      "date": "2020-03-13",
      "content_show": "Three ways Grammarly Business can help your customer experience teams avoid miscommunication.",
      "author": "Grammarly",
      "publish_date_show": "March 13th, 2020",
      "team_type": [
        "customer-experience"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "2mins Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/reduce-miscommunication-cost-features",
      "open_newtab": "no",
      "file_info": "2 minutes"
    },
    {
      "id": "8",
      "img": "assets/images/ebook cust experience_How-To-Thrive.jpg",
      "title": "Blog: eBook Customer Experience",
      "date": "2020-02-27",
      "content_show": "A new ebook explores the evolving CX landscape and offers smart strategies for functional leaders.",
      "author": "Grammarly",
      "publish_date_show": "February 27th, 2020",
      "team_type": [
        "customer-experience"
      ],
      "content_type": [
        "blog"
      ],
      "content_info": "1min Read",
      "cta_text": "READ MORE",
      "cta_link": "/blog/ebook-customer-experience",
      "open_newtab": "no",
      "file_info": "1 minute"
    },
    {
      "id": "6",
      "img": "assets/images/ebook cust experience_How-To-Thrive.jpg",
      "title": "eBook: Customer Experience",
      "date": "2020-02-03",
      "content_show": "Get smart strategies to help functional leaders deliver industry-leading experiences.",
      "author": "Grammarly",
      "publish_date_show": "February 3rd, 2020",
      "team_type": [
        "customer-experience"
      ],
      "content_type": [
        "ebook",
        "pdf"
      ],
      "content_info": "PDF | 6MB",
      "cta_text": "READ MORE",
      "cta_link": "/customer-experience-ebook",
      "open_newtab": "yes",
      "file_info": "6 MB"
    },
    {
      "id": "3",
      "img": "assets/images/resource-img-1.png",
      "title": "PDF: State of Workplace Email",
      "date": "2019-06-29",
      "content_show": "A recent survey reveals how today’s workforce feels about email communication.",
      "author": "Grammarly",
      "publish_date_show": "June 29th, 2019",
      "team_type": [
        "miscellaneous"
      ],
      "content_type": [
        "pdf"
      ],
      "content_info": "PDF | 1MB",
      "cta_text": "READ MORE",
      "cta_link": "https://www.grammarly.com/press/research/docs/2019-state-of-workplace-email.pdf",
      "cta_element_type": "a-tag",
      "open_newtab": "yes",
      "file_info": "1 MB"
    },
    {
      "id": "5",
      "img": "assets/images/eBook_FrontCover.png",
      "title": "eBook: Workforce Success",
      "date": "2019-06-29",
      "content_show": "Learn why written communication is more critical than ever for organizations and teams.",
      "author": "Grammarly",
      "publish_date_show": "June 29th, 2019",
      "team_type": [
        "miscellaneous"
      ],
      "content_type": [
        "ebook",
        "pdf"
      ],
      "content_info": "PDF | 6MB",
      "cta_text": "READ MORE",
      "cta_link": "/ebook",
      "open_newtab": "yes",
      "file_info": "6 MB"
    }
];

export default blogLists;