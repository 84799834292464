import React from 'react';
import { Link } from "react-router-dom";

const ContentBoxCustom = (props) => {
    const dataContent = props.dataContent;

    const setShowYoutubeVids = props.setShowYoutubeVids;
    const setShowYoutubeVidId = props.setShowYoutubeVidId;

    const setShowSelfHostedVidsUrl = props.setShowSelfHostedVidsUrl;
    const setShowSelfHostedVids = props.setShowSelfHostedVids;

    const processShowYoutubevid = (youtubeVideoLink) => {
        setShowYoutubeVidId(youtubeVideoLink);
        setShowYoutubeVids(true);
    }

    const processShowSelfHostedvid = (vidUrl) => {
        setShowSelfHostedVidsUrl(vidUrl);
        setShowSelfHostedVids(true);    
    }
        

    let ctaLinkContent = dataContent.cta_text;
    let ctaLink = "";
    let targetBlankOption = "";
    let imgResource = <img className="bb-img img-fluid" src={dataContent.img} alt=""/>;
    let imgLink = imgResource;

    if( dataContent.cta_img ){
        ctaLinkContent = <img className="download-icon" src={dataContent.cta_img} alt="download-icon"/>;
    }

    if( dataContent.open_newtab ){
        if( dataContent.open_newtab == "yes" ){
            targetBlankOption = '_blank';
        }      
    }

    if( dataContent.youtube_video_link ){
        ctaLink = <button onClick={ () => processShowYoutubevid(dataContent.youtube_video_link) } className="txt-green cta-link">{ctaLinkContent}</button>;
    }
    else if( dataContent.selfhosted_video_link ){
        ctaLink = <button onClick={ () => processShowSelfHostedvid(dataContent.selfhosted_video_link) } className="txt-green cta-link">{ctaLinkContent}</button>;
    }
    else{

        ctaLink = <Link to={dataContent.cta_link} className="txt-green cta-link">{ctaLinkContent}</Link>;
        imgLink = <Link to={dataContent.cta_link} className="">{imgResource}</Link>;
        if( dataContent.cta_element_type ){
            if( dataContent.cta_element_type == "a-tag" ){
                ctaLink = <a href={dataContent.cta_link} target={targetBlankOption} className="txt-green cta-link">{ctaLinkContent}</a>;
                imgLink = <a href={dataContent.cta_link} target={targetBlankOption} >{imgResource}</a>;
            } 
        }
    }

    return (  
    <div className="content-box-custom">
        <div>
            {/* <a href={dataContent.cta_link}><img className="bb-img img-fluid" src={dataContent.img} alt=""/></a> */}
            {imgLink}
            {/* <div className="bb-img-container" style={{ backgroundImage: 'url(' + dataContent.img + ')' }}></div> */}
            <h3 className="title">{dataContent.title}</h3>
            <p className="content">{dataContent.content_show.slice(0,100)}{dataContent.content_show.length>100?"(...)":""}</p>
        </div>
        <div>
            <p className="author">by <span className="author-name">{dataContent.author}</span></p>
            <p className="publish-info">Published on <span className="publish-date">{dataContent.publish_date_show}</span></p>
            <div className="row align-items-center">
                <div className="col">
                    <p className="content-info">{dataContent.content_info}</p>    
                </div>
                <div className="col text-right">
                    { ctaLink }
                </div>
            </div>
        </div>
    </div>    
    );
}
 
export default ContentBoxCustom;