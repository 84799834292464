import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class EbookCustomerExperience extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>
                  Grammarly Business Ebook: <em>How To Thrive in the Age of Customer Experience.</em>
                </h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/Ebook-customer-experience/GB-Blog-banner---How-To-Thrive----Desktop-1X.jpg")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>February 27, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <h2 className="mb-4">
                  eBook – <em>How to thrive in the age of customer experience.</em>
                </h2>

                <p className="IntroPara">
                  Today more than two-thirds of companies say they compete on customer experience (CX), up from just 36% in 2010 <sup>1</sup>. And with nearly 70% of consumers willing to pay more to interact with brands who offer great experiences, savvy CX leaders are looking to seize the
                  opportunity not only for competitive differentiation but additional revenue as well <sup>2</sup>.
                </p>

                <p>At the same time, leaders are faced with several customer-facing channels to manage, increasingly complex technology, and a workforce skill gap. Solving for quality control and cost reduction has never been more challenging. </p>

                <p>
                  In our new eBook, <em>How to Thrive in the Age of Customer Experience</em>, we explore these aspects of the evolving CX landscape and offer strategies to help functional leaders deliver industry-leading experiences.
                </p>

                <div className="container-fluid px-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <img className="mt-4 mb-5 img-fluid" src={require("../../assets/images/blog/Ebook-customer-experience/how_to_thrive_in_the_age_of_customer_experience.png")} alt="" />
                    </div>
                  </div>
                </div>

                <Link to="/customer-experience-ebook?utm_source=content&utm_medium=ebook_blog&utm_campaign=customer_experience)">
                  <button className="ghost-btn mt-2 d-flex mx-auto mb-5">Read the ebook &gt;</button>
                </Link>

                <p className="mb-5 sources-text">
                  Sources: <br />
                  <ol className="px-md-2 ml-md-1 mt-2">
                    <li>Gartner, Customer Experience Survey 2017.</li>
                    <li>Salesforce, State of the Connected Customer.</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>

                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EbookCustomerExperience;
