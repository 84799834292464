import React from "react";
import CallToAction from "../Button";
import $ from "jquery";

import CountryListSelect from '../CountryListSelect';
import CSFChallengeSelect from './CSFChallengeSelect';
import CSFFacultySelect from './CSFFacultySelect';
import ReCAPTCHAExecComponent from './ReCAPTCHAExecComponent';

import { registerEventToGA } from "../GAHelperCustom";
import emailExcludedDomainList from "../../data/emailExcludedDomainList";
import { validateEmail, checkExcludedEmailDomainExist } from "../../helpers/utils-custom";

class CompanySelectForm extends React.Component {
    constructor() {
        super()
        this.UTMSource = ""
        this.UTMSourceFlag = false
        this.getURLQuery();

        this.state = {
            page: 1,
            companySizeOpenUrl1: "https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy",
            interestMyselfLink: "https://www.grammarly.com/signup",
            interestStudentLink: "https://www.grammarly.com/signup",
            interestNonProfitsNgosLink: "https://www.grammarly.com/nonprofits-ngos",
            retUrl: "https://grammarly-enterprise.com/thankyou",
        };
        
    }

    showErrorMessageForm(){
        $(".connect-form").removeClass("d-none"); 
    }

    hideErrorMessageForm(){
        $(".connect-form").addClass("d-none"); 
    }

    submitForm(e){
        // e.preventDefault();
        if( this.props.addDataToUrl == "yes" ){
            var leadData = JSON.parse(JSON.stringify($("#pardot-form").serializeArray()));
            // console.log(leadData);

            var leadObject = {};
            for (let i = 0; i < leadData.length; i++) {
                leadObject[leadData[i].name] = leadData[i].value;
            }
            // console.log(leadObject);

            let retUrl = "https://grammarly-enterprise.com/thankyou?Firstname=" +
            leadObject.first_name +
            "&Lastname=" +
            leadObject.last_name +
            "&Email=" +
            leadObject.email +
            "&Phone=" +
            leadObject.phone +
            "&Company_Size__c=" +
            leadObject['00N3c000007ui19'] +
            "&Title=" +
            leadObject.title +
            "&Company=" +
            leadObject.company +
            "&Country=" +
            leadObject.country +
            "&sales_interest__c=" +
            leadObject['00N3c000006wQGx'];
            
            if(leadObject['00N3c000006wQH2']){
                retUrl = retUrl + "&EduFaculty=" + leadObject['00N3c000006wQH2'];
            }

            this.setState({
                retUrl: retUrl
            });

            // console.log( retUrl );
        }
    }

    processSubmitBtnLink(event){
        
        if( this.state.page == 1 ){
            this.processFormPage1();
        }

        if( this.state.page == 2 ){
            this.processFormPage2();
        }    
    }

    processFormPage1(){
        
        this.hideErrorMessageForm();
        const csfChallengeVal = $(".form-container input[id='00N3c000006wQGx']").val();
        const csfFacultyVal = $(".form-container input[id='00N3c000006wQH2']").val();

        if( this.isForm1Valid() ){

            registerEventToGA( csfChallengeVal, "Form challenge option select", csfChallengeVal );
            if( csfChallengeVal == "I'm interested in Grammarly for myself" ){
                window.location = this.state.interestMyselfLink;
            }
            else if( csfChallengeVal == "I’m evaluating Grammarly for a non-profit organization" ){
                window.location = this.state.interestNonProfitsNgosLink;
            }
            else if( csfChallengeVal == "I’m evaluating Grammarly for an educational institution" && csfFacultyVal == "Student" ){
                window.location = this.state.interestStudentLink;
            }
            else{
                $(".form-container .csf-challenge-section-container").addClass("d-none");
                $(".form-container .company-size-section").removeClass("d-none");
                this.setState({
                    page: 2
                });
            }
            this.processSelectCompanyLabel();
        }
        else{
            this.showErrorMessageForm();
        }
    }

    isForm1Valid(){
        let retVal = false;
        const csfChallengeVal = $(".form-container input[id='00N3c000006wQGx']").val();
        const csfFacultyVal = $(".form-container input[id='00N3c000006wQH2']").val();

        if( csfChallengeVal != "" ){
            if( csfChallengeVal == "I’m evaluating Grammarly for an educational institution" ){
                if( csfFacultyVal != "" ){
                    retVal = true;    
                }
            }else{
                retVal = true;
            } 
        }

        return retVal;
    }

    processFormPage2(){

        this.hideErrorMessageForm();
        const companySizeEl = $(".form-container input[name='00N3c000007ui19']:checked");

        if( companySizeEl.length > 0 ){
            if( companySizeEl.val() == "1-100" ){
                window.location = this.state.companySizeOpenUrl1;
            }
            else{
                $(".form-container .initialSubmitBtn").addClass("d-none");
                $(".form-container .company-size-section").addClass("d-none");
                $(".form-container .form-elements").show();
                this.setState({
                    page: 3
                });
            }
        }  
        else{
            this.showErrorMessageForm();
        } 
    }

    getURLQuery() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.has('utm_source')){
          this.UTMSource = urlParams.get('utm_source');
          this.UTMSourceFlag = true;
          console.log(this.UTMSource);
        }
        else{
          this.UTMSource = "";
        }
    }

    processSelectCompanyLabel(){

        const csfChallengeVal = $(".form-container input[id='00N3c000006wQGx']").val();
        const csfFacultyVal = $(".form-container input[id='00N3c000006wQH2']").val();
        if( csfChallengeVal == "I’m evaluating Grammarly for an educational institution" && csfFacultyVal == "Faculty"){
            $(".form-container .scs-input-label").html("Select Institution Size:");    
        }
        else{
            $(".form-container .scs-input-label").html("Select Company Size:");    
        }
            
    }

    handleEmailInputChange(){
        
        let formValid = true;

        let emailData = $(".form-container input#email").val();
        
        if( !validateEmail(emailData) ){
            formValid = false;
            $(".email-validation-label").html("The email address is not valid.");  
            $(".email-validation-label").removeClass("d-none");
        }
        else{
            $(".email-validation-label").addClass("d-none");  
        }

        if( validateEmail(emailData) ){
            if( checkExcludedEmailDomainExist(emailExcludedDomainList, emailData) ){
                formValid = false;
                $(".email-validation-label").html("The email domain can not be submitted.");  
                $(".email-validation-label").removeClass("d-none");
                
            }
            else{
                $(".email-validation-label").addClass("d-none");    
            }     
        }

        if(formValid){
            $(".captcha-block").removeClass("d-none");   
        }
        else{
            $(".captcha-block").addClass("d-none");   
        }
        
        
        return formValid;
    }

    render() { 
        return (  
            <div className="">
                <form 
                className="px-4 pt-1 pb-3" 
                id="pardot-form" 
                action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" 
                method="POST"
                >
                    <h4>Stronger writing means better business results.</h4>
                    
                    <div className="csf-challenge-section-container">
                        <div className="csf-challenge-section" >
                            <CSFChallengeSelect />
                        </div>

                        <div className="csf-faculty-section d-none" >
                            <CSFFacultySelect  />
                        </div>
                    </div>

                    <div className="company-size-section d-none">
                        <p className="mx-0 mt-3 d-none">Leave your information and we'll get back to you shortly.</p>
                        <label className="my-3 scs-input-label">Select Company Size:</label>
                        <div className="row pl-md-3">
                            <span className="col-6 col-md px-0 default-option text-center text-md-left">
                                <input type="radio" className="customRadioBtn" name="00N3c000007ui19" id="companyOne" value="1-100" />
                                <label htmlFor="companyOne">1-100</label>
                            </span>
                            <span className="col-6 col-md px-0 default-option text-center text-md-left">
                                <input type="radio" id="companyTwo" className="customRadioBtn"  name="00N3c000007ui19" value="101-750" />
                                <label htmlFor="companyTwo">101-750</label>
                            </span>
                            <span className="col-6 col-md px-0 default-option text-center text-md-left">
                                <input type="radio" id="companyThree" className="customRadioBtn"   name="00N3c000007ui19" value="751-2500" />
                                <label htmlFor="companyThree">751-2500</label>
                            </span>
                            <span className="col-6 col-md px-0 default-option text-center text-md-left">
                                <input type="radio" id="companyFour" className="customRadioBtn"   name="00N3c000007ui19" value="2500+" />
                                <label htmlFor="companyFour">2500+</label>
                            </span>
                        </div>
                    </div>

                    <p className="text-danger connect-form mb-0 d-none" >Please make a selection</p>
                    <br />
                    <input type={"hidden"} name="oid" value="00D80000000d8Lr" />
                    <input type={"hidden"} name="retURL" value={this.state.retUrl} />
                    {/* <input type={"hidden"} name="lead_source" value="grammarly-enterprise" /> */}
                    <input type={"hidden"} name="lead_source" value={this.props.leadSourceInputData?this.props.leadSourceInputData:"grammarly-enterprise"} />
                    <input type={"hidden"} id="00N80000003lP66" name="00N80000003lP66" title="License Type" value="Volume License"/>
                    <input type={"hidden"} id="00N3c000006cghu" name="00N3c000006cghu" title="utmSource" value={this.UTMSource}></input>
                    <input type={"hidden"} id="00N3c000006wQW2" maxLength="255" name="00N3c000006wQW2" size="20"  />

                    <div className="form-elements">
                        <input id="first_name" maxLength="40" name="first_name" placeholder="First Name" size="20" type="text" required />
                        <br />

                        <input id="last_name" maxLength="80" name="last_name" placeholder="Last Name" size="20" type="text" required />
                        <br />

                        <input id="title" maxLength="40" name="title" placeholder="Job Title" size="20" type="text" required />
                        <br />

                        <input id="company" maxLength="40" name="company" placeholder="Company Name" size="20" type="text" required />
                        <br />

                        <input id="email" maxLength="80" name="email" placeholder="Work Email" size="20" type="email" required
                        onChange={()=>{this.handleEmailInputChange()}}
                        />
                        <p className="text-danger mb-0 d-none email-validation-label" ></p>
                        <br />

                        <CountryListSelect />
                    
                        <input id="phone" maxLength="40" name="phone" placeholder="Phone Number (Optional)" size="20" type="text" />
                        <br />

                        <textarea id="00NC0000007FZ9t" name="00NC0000007FZ9t" placeholder="Which challenges are you looking to overcome with Grammarly?" type="text" wrap="soft"></textarea>
                        <br />
                        <div className="captcha-block d-none">
                            <ReCAPTCHAExecComponent />
                        </div>
                        <br />

                        <input 
                        id="FormSubmitBtn" 
                        className="d-none" 
                        type="submit" 
                        name="submit" 
                        onClick={(e) => this.submitForm(e)}
                        value="Submit" />
                    </div>
                </form>
                    
                    
                <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-2 pt-3 initialSubmitBtn">
                        <a href="#" className="btn btn-primary btn-talk green-btn mt-0 mb-2"
                        onClick={ () => this.processSubmitBtnLink() }>Submit</a>
                    </div>
                    
                    <div className="w-100 text-center pb-2 pt-3 initialBtn d-none">
                        <a href="#" className="btn btn-primary btn-talk green-btn mt-0 mb-2"
                        onClick={ () => this.showConnectForm() }>Learn More</a>
                    </div>

                    <div className="w-100 text-center pb-2 pt-3 selectedBtn">
                        <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="green-btn mt-0 mb-2">
                            Submit
                        </CallToAction>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default CompanySelectForm;