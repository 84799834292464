const emailExcludedDomainList = [
    "@chmail.ir",
    "@cinci.rr.com",
    "@columbus.rr.com",
    "@comcast.net",
    "@cox.net",
    "@dc.rr.com",
    "@earthlink.net",
    "@ec.rr.net",
    "@email.com",
    "@email.it",
    "@126.com",
    "@aim.com",
    "@antihotmail.com",
    "@aol.com",
    "@example.com",
    "@fastmail.fm",
    "@foxmail.com",
    "@free.fr",
    "@freemail.hu",
    "@gmai.com",
    "@gmail.com",
    "@gmx.at",
    "@gmx.be",
    "@asdf.com",
    "@att.net",
    "@austin.rr.com",
    "@bell.net",
    "@bellsouth.net",
    "@bk.ru",
    "@charter.net",
    "@gmx.ca",
    "@gmx.ch",
    "@gmx.co.uk",
    "@gmx.com",
    "@gmx.de",
    "@gmx.es",
    "@gmx.fr",
    "@gmx.li",
    "@netzero.net",
    "@nj.rr.com",
    "@nyc.rr.com",
    "@nycap.rr.com",
    "@o2.pl",
    "@online.de",
    "@online.no",
    "@opayq.com",
    "@openmailbox.org",
    "@optonline.net",
    "@orange.fr",
    "@outlook.be",
    "@outlook.com",
    "@outlook.es",
    "@outlook.fr",
    "@phishme.com",
    "@pobox.com",
    "@pokemail.net",
    "@protonmail.ch",
    "@protonmail.com",
    "@q.com",
    "@rambler.ru",
    "@rcn.com",
    "@rediffmail.com",
    "@riseup.net",
    "@rmqkr.net",
    "@roadrunner.com",
    "@rochester.rr.com",
    "@rocketmail.com",
    "@rogers.com",
    "@san.rr.com",
    "@satx.rr.com",
    "@sbcglobal.net",
    "@sc.rr.com",
    "@shotmail.ru",
    "@social.rr.com",
    "@spam4.me",
    "@sqrrl.com",
    "@stny.rr.com",
    "@suremail.info",
    "@talktalk.net",
    "@terra.com.br",
    "@test.com",
    "@t-online.de",
    "@trash-mail.com",
    "@triad.rr.com",
    "@twcny.rr.com",
    "@twmi.rr.com",
    "@tx.rr.com",
    "@verizon.net",
    "@wanadoo.fr",
    "@web.de",
    "@wi.rr.com",
    "@windowslive.com",
    "@woh.rr.com",
    "@xs4all.nl",
    "@y7mail.com",
    "@ya.ru",
    "@yahoo.be",
    "@yahoo.ca",
    "@yahoo.cn",
    "@yahoo.co",
    "@yahoo.co.id",
    "@yahoo.co.in",
    "@gmx.net",
    "@gmx.org",
    "@gmx.us",
    "@googlemail.com",
    "@grr.la",
    "@hanmail.net",
    "@hmamail.com",
    "@hot.rr.com",
    "@hotmail.be",
    "@hotmail.ca",
    "@yahoo.co.jp",
    "@yahoo.co.kr",
    "@yahoo.co.nz",
    "@yahoo.co.th",
    "@yahoo.co.uk",
    "@yahoo.co.za",
    "@yahoo.com",
    "@yahoo.com.ar",
    "@yahoo.com.au",
    "@yahoo.com.br",
    "@hotmail.co",
    "@hotmail.co.jp",
    "@hotmail.co.kr",
    "@hotmail.co.nz",
    "@hotmail.co.th",
    "@hotmail.co.uk",
    "@hotmail.co.za",
    "@hotmail.com",
    "@hotmail.com.au",
    "@hotmail.com.br",
    "@yahoo.com.cn",
    "@yahoo.com.com",
    "@yahoo.com.hk",
    "@yahoo.com.mx",
    "@yahoo.com.my",
    "@yahoo.com.ph",
    "@yahoo.com.sg",
    "@yahoo.com.tr",
    "@yahoo.com.tw",
    "@yahoo.com.vn",
    "@hotmail.com.sa",
    "@hotmail.com.tr",
    "@hotmail.cz",
    "@hotmail.de",
    "@hotmail.es",
    "@hotmail.fr",
    "@hotmail.fr.nf",
    "@hotmail.gr",
    "@hotmail.hu",
    "@hotmail.it",
    "@yahoo.de",
    "@yahoo.dk",
    "@yahoo.es",
    "@yahoo.fr",
    "@yahoo.gr",
    "@yahoo.ie",
    "@hotmail.lv",
    "@hotmail.nl",
    "@hotmail.rw",
    "@hotmail.se",
    "@hotmail.sg",
    "@hush.com",
    "@hushmail.com",
    "@hvc.rr.com",
    "@inbox.com",
    "@inbox.lv",
    "@yahoo.in",
    "@yahoo.it",
    "@yahoo.no",
    "@yahoo.om",
    "@yahoo.pl",
    "@yahoo.ro",
    "@yahoo.se",
    "@yandex.ru",
    "@yeah.net",
    "@ymail.com",
    "@inbox.ru",
    "@indy.rr.com",
    "@insight.rr.com",
    "@juno.com",
    "@kc.rr.com",
    "@laposte.net",
    "@list.ru",
    "@live.at",
    "@live.be",
    "@live.ca",
    "@yopmail.com",
    "@icloud.com",
    "@163.com",
    "@gmail.co",
    "@shaw.ca",
    "@cfl.rr.com",
    "@snet.net",
    "@sympatico.ca",
    "@pacbell.net",
    "@windstream.net",
    "@live.cn",
    "@live.co.uk",
    "@live.co.za",
    "@live.com",
    "@live.com.ar",
    "@live.com.au",
    "@live.com.mx",
    "@live.com.pt",
    "@hawaii.rr.com",
    "@cogeco.ca",
    "@telus.net",
    "@tampabay.rr.com",
    "@frontiernet.net",
    "@digcommunications.com",
    "@live.com.sg",
    "@live.de",
    "@live.dk",
    "@life.fr",
    "@life.hk",
    "@life.ie",
    "@life.in",
    "@life.it",
    "@life.jp",
    "@life.nl",
    "@life.no",
    "@life.ru",
    "@life.se",
    "@mac.com",
    "@mail.com",
    "@mail.ru",
    "@mail.yahoo.com",
    "@mailbox.org",
    "@maildrop.cc",
    "@mailinator.com",
    "@me.com",
    "@msn.com",
    "@mvrht.com",
    "@mvrht.net",
    "@mynet.com",
    "@naver.com",
    "@nc.rr.com",
    "@neo.rr.com",
    "@netscape.net",
    "@netvision.net.il"
];

export default emailExcludedDomainList;