import React from "react";
import Layout from "../components/Layout";
import CallToAction from "../components/Button";
import ebookFile from "../assets/pdf/Hacking CX eBook.pdf";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";


class HackingCXeBookNoForm extends React.Component {
  constructor() {
      super()
      this.UTMSource = ""
      this.UTMSourceFlag = false
      this.getURLQuery();

      this.state = {
          retUrl: "https://grammarly-enterprise.com/thankyou-ebook",
      };
  }

  getURLQuery() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('utm_source')){
      this.UTMSource = urlParams.get('utm_source');
      this.UTMSourceFlag = true;
      console.log(this.UTMSource);
    }
    else{
      this.UTMSource = "";
    }
  }

  verifyCaptcha(value) {
    if (value != "") {
      $("#FormSubmit").removeClass("d-none");
      $("#FormSubmit").addClass("d-flex");
    } else {
      $("#FormSubmit").removeClass("d-flex");
      $("#FormSubmit").addClass("d-none");
    }
  }

  submitForm(e) {
    saveAs(ebookFile,"Hacking CX eBook.pdf");
    // e.preventDefault();
  }

  scrollToRef(xPos, yPos){
    window.scrollTo(xPos, yPos);
  }

  render() {
    return (
      <Layout className="ebook-landing-page hackingcx2-ebook-landing-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
              <section className="py-4 pt-lg-5">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 col-lg-6 pr-lg-0">
                      <h1 className="font-family-circular font-weight-bold heading-txt">Hacking Customer Experience: 
The New Standard for Customer Communication</h1>
                      <p>Download our latest ebook for customer-facing teams.</p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section-grey py-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-6 text-center d-none d-md-block">
                      <div className="py-3 py-lg-0 text-center text-lg-left">
                        <CallToAction
                          to={ebookFile}
                          eType="Download"
                          className="py-3 px-5"
                          eName="Customer Experience ebook"
                          eLabel="customer-experience-ebook-download"
                          noRouter
                          addGAEvent="yes"
                        >
                          Download Now
                        </CallToAction>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 text-center">
                      <img src={require('../assets/images/hacking-cx-ebook-img2.png')} className="img-fluid rounded hero-cover ml-0 ml-lg-3" alt="Grammarly Ebook"/>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section-grey mb-6 pb-5">
                <div className="container">
                  <h2 className="mb-3">Get Grammarly’s blueprint on better customer communication</h2>

                  <div className="row">
                    <div className="col-12 col-lg-3 pb-lg-0">
                      <ul className="pl-4"><li><p>Grammarly users were 75% less optimistic in their writing at the start of the pandemic. Learn how you can build resilient customer-facing teams in the face of pressing challenges.</p></li></ul>
                    </div>
                    <div className="col-12 col-lg-3 pb-lg-0">
                      <ul className="pl-4"><li><p>Did you know that personalized experiences convert 44% of consumers and push 40% to spend more money? Learn how communication can personalize experiences and strengthen brand loyalty.</p></li></ul>
                    </div>
                    <div className="col-12 col-lg-3 pb-lg-0">
                      <ul className="pl-4"><li><p>Only 7% of leaders believe their company can upskill its workers successfully. Learn how technology can help you target skills gaps and increase productivity.</p></li></ul>
                    </div>
                    <div className="col-12 col-lg-3 mb-4">
                      <ul className="pl-4"><li><p>Read about enterprise customer support teams who are boosting productivity, brand, and customer satisfaction by using Grammarly Business.</p></li></ul>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-12">
                      <div className="col-12 text-center mt-4">
                          <a href="#"  
                            className="btn btn-primary btn-talk green-btn mb-4 d-none d-md-inline-block"
                            onClick={ (e) => {e.preventDefault();this.scrollToRef(0, 270);} } >Sign up</a>
                      </div>
                    </div>
                  </div> */}

                  <blockquote className="blockquote mb-5">
                    <img className="inverted-comma" src={require('../assets/images/double-inverted-comma.png')} alt="" />
                    <p className="blockquote-body">In today's business environment, you often only get one shot to be understood, especially with CEOs and Fortune 500 customers. Using Grammarly Business has helped our team create the best first impression with our communication to those customers, which has led to us having more impact and credibility when resolving critical issues."
                    </p>
                    <footer className="blockquote-footer-1">
                      <cite>
                        <span className="author mb-2">Sean Scanlon</span>
                        <span className="position">Global Escalation Manager at Pivotal</span>
                      </cite>
                    </footer>
                  </blockquote>

                  <h2>About</h2>
                  <p>Grammarly is a digital writing assistant that helps more than 30 million people and 10,000 teams write more clearly and effectively every day. Grammarly Business empowers teams to communicate better with each other and with their customers while staying on-brand. The AI-powered solution goes beyond spelling and grammar with real-time suggestions for improving readability, word choice, writing style, and tone. Learn more about <Link to="/yourteam/customer-experience">Grammarly Business</Link>.</p>
                </div>
              </section>
            </div>

            <div className="col-12 sticky-bottom px-0 d-md-none z-index-99">
              <section className="section-grey">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center">
                      <div className="py-3 text-center">
                        <CallToAction to={ebookFile} download eType="Download" className="py-3 px-5" eName="Customer Experience ebook" eLabel="customer-experience-ebook-download" noRouter addGAEvent="yes">
                          Download Now
                        </CallToAction> 
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div> 

          </div>
        </div>
      </Layout>
    );
  }
}

export default HackingCXeBookNoForm;
