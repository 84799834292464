import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class GrowCommunicationsAtScale extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Ops Leaders, Learn How to Upskill Your Teams at Scale</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/grow-communications-at-scale/desktop-banner.png")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/grow-communications-at-scale/mobile-banner.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>August 11, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">As businesses adjust to the new normal, ops leaders particularly are placed in a precarious position. Between <a href="https://www.wsj.com/articles/companies-turn-to-zero-based-budgeting-to-cut-costs-during-the-pandemic-11592431029" target="_blank">shrinking budgets</a>, global and distributed teams, championing the customer experience, and providing support for their teams, bandwidth is short while targets are high. Add <a href="https://www.mckinsey.com/business-functions/organization/our-insights/to-emerge-stronger-from-the-covid-19-crisis-companies-should-start-reskilling-their-workforces-now" target="_blank">training and development</a> to the list, and their charter becomes even more daunting. Still, skill-building within existing teams is one of the biggest levers leaders can pull when achieving business objectives is nonnegotiable.</p>

                <p>While all of the above may be true, it’s time to throw away the myth of doing everything on your own. As an ops leader, you can’t be everything to everyone all the time. So how can leaders create a sustainable and supportive learning environment for their teams? The answer lies in tech.</p>

                <p>Today, <a href="https://www.mckinsey.com/business-functions/organization/our-insights/to-emerge-stronger-from-the-covid-19-crisis-companies-should-start-reskilling-their-workforces-now" target="_blank">developing your team’s digital and cognitive capabilities is critical</a>. Machine learning and AI enable leaders to leverage cost-effective product offerings to help their teams learn and perform at the optimum level. Because technology impacts many aspects of operational workflows, it can accelerate your strategy in multiple ways and reduce the need for full-blown learning and development programs. This is especially helpful since ops leaders balance so many competing priorities. When leaders adopt the right technology, customer operations teams learn new skills, and your customers reap the benefits of an elevated customer experience.</p>

                <h2 className="my-4">Leveraging the right tools</h2>

                <p>
                Developing your team’s customer operations skills and processes requires tools that can strengthen their communication. Teams spend a significant portion of their time communicating and tech can make a substantial impact. The beauty of artificial intelligence is that AI-powered tools can provide guidance, especially when it comes to the written word. Communication tools can help teams level up their performance and communication skills while allowing leaders to support them without micromanaging. As you build your toolkit, keep in mind that the ease of implementation will help realize value. Here are a few things that will set you (and your team) up for successful implementation.
                </p>

                <ol>
                  <li className="my-2">
                    <p>
                      <b>Make it easy to adopt</b> <br />
                      Change is tough, especially when it comes to adding new technology into the mix. Opt for tools that easily integrate with your existing technology. Selecting tools with a low barrier to entry enables teams to have more success with use. When deploying new technologies—especially for training and reskilling purposes—give your team some time to become familiar with it. Tools like Grammarly Business offer a seamless user experience that allows teams to learn intuitively over time. By creating a pleasant and engaging experience, your team will be eager to use the new technology or solution.
                    </p>
                  </li>
                  <li className="my-2">
                    <p>
                      <b>Develop clear standards</b> <br />
                      Building out a content strategy, having company style guides, and documenting brand standards is ideal. Clear and consistent rules and guidelines are the key component to achieving a united front. That said, the organization needs to adopt these guidelines across the board or it could lead to a disjointed customer experience. Leveraging tools like <a href="https://grammarly-enterprise.com/styleguide-cs" target="_blank">Grammarly Business style guides</a> gives you the ability to create rules and guidelines for your team to implement with a simple click of a button. Once the custom style guide suggestions are applied, your team can interact with customers confidently, knowing that each message meets the company-wide standards.
                    </p>
                  </li>
                  <li className="my-2">
                    <p>
                      <b>Think global</b> <br />
                      Having a global team is an amazing experience of working with a diverse group of people. When working with people from all over the world, it’s important to remember that everyone has their own understanding of communication. Tone and style <a href="https://www.cmswire.com/customer-experience/how-cultural-differences-impact-customer-experience/" target="_blank">vary between cultures and languages</a>, so what may seem friendly to one person may come off as curt to another. Utilizing tools that instantly check for tone can create shared context across culturally diverse teams.
                    </p>
                  </li>
                </ol>

                <h2 className="my-4">Leading while empowering</h2>
                <p>
                This new normal is here to stay, but it doesn’t mean that ops leaders are against the odds. Developing critical interpersonal skills in customer operations is challenging even in the best of circumstances. With AI-based tools, you can empower your team with new skills for better performance. Better yet, these tools allow you to support, touch base with, and mentor your team without having to be everywhere at once. It’s all about finding the right fit. Plus, with the additional time savings, imagine all of the goals you can accomplish. Strategic communication tools like Grammarly Business enable endless possibilities.
                </p>

              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default GrowCommunicationsAtScale;
