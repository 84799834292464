import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";
import { Link } from "react-router-dom";

class ReorganizeYourWorkBench extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Reorganize your workbench to unlock agent effectiveness and engagement</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/reorganize-your-workbench/reorganize-your-workbench-desktop.png")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/reorganize-your-workbench/reorganize-your-workbench-mobile.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>January 20, 2021</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
              
                <p className="IntroPara">Despite the reality that customers <a href="https://www.accenture.com/_acnmedia/pdf-43/accenture-strategy-gcpr-customer-loyalty.pdf" target="_blank">spend more</a> with brands to which they are loyal, nearly a third of customer-facing leaders <a href="https://f.hubspotusercontent00.net/hubfs/53/2020%20State%20of%20Service%20Report.pdf?submissionGuid=3d642108-c042-4987-8857-103f13bb816d" target="_blank">perceive</a> customer support as an expense and lack clear frameworks for understanding the return on their investment. Considering that American companies <a href="https://www.vonage.com/resources/articles/the-62-billion-customer-service-scared-away-infographic/" target="_blank">lost</a> $62 billion in revenue due to poor customer experiences, it’s critical for leaders to understand the vast and cumulative impact of empowering their teams to be the rockstars of the brand.</p>

                <p>At a time when organizational leaders have to achieve more with less, the good news is that most companies already have the key components in place to deliver stellar customer experiences. The crux of the matter is all about organization and execution.</p>

                <h2 className="my-4">Get your workbench in order</h2>
                <p>Think of your company as a workbench, and its tools are your people and your framework.  On the other hand, a disorganized workbench may have duplicate tools in separate places, leading you to invest in new gadgets unnecessarily. Not to mention the frustration of trying to find tools in the clutter of an unorganized workbench, which leads to a loss in time and money. An organized workbench is efficient, it knows what tools it has and how to use them. As a leader, you've dedicated yourself to acquiring the right tools—you've hired the right talent and developed the right playbook to execute your business objectives. But when is the last time you reorganized or cleared the clutter to ensure your workbench is working as you'd envisioned?</p>

                <h2 className="my-4">Maximize your existing investments by removing barriers</h2>
                <p>Poor customer experiences are driven by a lack of customer data, slow resolution times, and inconsistent experiences with the customer support team. These bottlenecks create the groundwork for a negative brand reputation, stifling a company’s opportunity to build customer loyalty. Fortunately, in your customer support team and internal playbooks, you have the right tools ready to go to mitigate these negative outcomes. As a leader, your charter is to empower them to do what they do best by removing blockers to their success.  Specifically, take a look at reorganizing along these three dimensions :</p>

                <ul>
                  <li>
                    <p><strong>Customer Insights</strong>. A major driver for delivering a great customer experience is understanding the customer. When your agents have easy, on-demand access to insights like previous order history, location, search history, for example, this enables them to offer up a tailored experience that makes the customer feel understood. move toward identifying and deploying a solution.</p>
                  </li>
                  <li>
                    <p><strong>Speed of Service</strong>. When customers are in need of support, time is of the essence. When agents are able to have access to these insights in real-time, they can focus on identifying and deploying the solution versus spending time tracking down information expediting the road to resolution. By unlocking speed, agents can repeat the process.</p>
                  </li>
                  <li>
                    <p><strong>Consistency</strong>. True customer loyalty is unleashed when your customers can trust that your company will provide the same high-level customer experience time after time. Develop and communicate a framework that ensures consistency around friendliness, information, and quick resolution</p>
                  </li>    
                </ul>

                <p>Delivering an exceptional customer experience is contingent on agent effectiveness. When leaders organize their workbench, they can expect a greater return on their investments and less waste in spending.</p>

                <h2 className="my-4">Scale your organization by implementing strategic tech</h2>
                <p>When it comes down to investing in technology, it should enable your agents to deliver consistent customer experiences that will keep your customers coming back again and again.  Keep in mind that effective tools should complement the existing framework, not override them. Remember that technology is a mechanism that up-levels your existing people and framework. Empower your agents with tools that enable them to consistently perform at the highest level by:</p>

                <ul>
                  <li><p className="mb-1">Shortening the time to understand the customer’s needs.</p></li>
                  <li><p className="mb-1">Equipping agents with prompts directed to empathize and focus on solutions.</p></li>
                  <li><p>Solving the problem by deploying quick and efficient real-time solutions.</p></li>    
                </ul>

                <h2 className="my-4">Why it matters</h2>
                <p>Technology will never replace the human element required to drive customer experiences. However, it can remove bottlenecks within the support process to enable your support agents to perform consistently and with high-quality results. This first starts when leaders organize and assess their current workbench. By understanding and leveraging your existing efforts, leaders can strategically implement complementary solutions that maximize their return on investments in their team, ultimately achieving a better bottom line.</p>

              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ReorganizeYourWorkBench;
