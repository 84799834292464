import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class MeetGrammarlyBusinessVideo extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page blog-video-page">
        <div className="container-fluid bvp-section">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2>Better communication is good business.</h2>
                <p className="pb-3">Grammarly Business helps organizations elevate their communication with smart, one-click suggestions to improve word choice, readability, tone, and more. The AI-powered writing assistant integrates with existing workflows and works seamlessly across platforms. Administrators can create style guides, track performance, and manage billing in one central place. Watch the demo video to learn more.</p>
                <div className="iframe-youtube-section">
                  
                  <div className="share-icon-float-container d-none d-md-flex flex-column">
                    <div className="share-icon share-icon-fb">
                      <FacebookShareButton url={this.pageURL}>
                        <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                      </FacebookShareButton>
                    </div>
                    <div className="share-icon share-icon-linkedin">
                      <LinkedinShareButton url={this.pageURL}>
                        <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                      </LinkedinShareButton>
                    </div>
                    <div className="share-icon share-icon-twitter">
                      <TwitterShareButton url={this.pageURL}>
                        <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                      </TwitterShareButton>
                    </div>
                    <div className="share-icon share-icon-mail">
                      <EmailShareButton url={this.pageURL}>
                        <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                      </EmailShareButton>
                    </div>
                  </div>

                  <iframe width="100%" height="550" src="https://www.youtube.com/embed/ekLEuayXv5o" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default MeetGrammarlyBusinessVideo;
