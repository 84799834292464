import React, { useState } from 'react';
import ContentBoxCustom from "../ContentBoxCustom";
import BlogResourcesYoutubeModal from "./BlogResourcesYoutubeModal";
import BlogResourcesSHVidsModal from "./BlogResourcesSHVidsModal";
import $ from "jquery";

const  BlogResourceContent = (props) => {
    
    let blogLists = props.blogLists;
    const blogListsLength = blogLists.length;
    const numItems = props.numItems;
    const startIndex = props.startIndex;
    const changeSortBy = props.changeSortBy;

    const [ sortByLabel, setSortByLabel ] = useState("Sort by");

    const [ showYoutubeVids, setShowYoutubeVids ] = useState(false);
    const [ youtubeVidId, setShowYoutubeVidId ] = useState("");

    const [ showSelfHostedVids, setShowSelfHostedVids ] = useState(false);
    const [ showSelfHostedVidsUrl, setShowSelfHostedVidsUrl ] = useState("");
    
    const toggleSortBy = (sortByLabel) => {
        $(".dropdown-menu-sort-by").toggle(); 
        setSortByLabel(sortByLabel);
    }

    return (  
    <>
    <div className="sort-section d-none d-md-block">
        <div className="row align-items-center">
            <div className="col-sm-6">
                <p className="txt-blue m-0" >Showing {(blogListsLength<numItems)?blogListsLength:numItems} of {blogListsLength} results</p>    
            </div>
            <div className="col-sm-6 sort-option-section text-right">
                
                <div className="dropdown dropdown-grey d-inline-block">
                    <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                    onClick={() => toggleSortBy("Sort by")} >
                        {sortByLabel}
                    </button>
                    
                    <div className="dropdown-menu dropdown-menu-sort-by" aria-labelledby="dropdownMenuButton" >
                        <a className="dropdown-item" onClick={() => {changeSortBy("new-old");toggleSortBy("New to old");} } >New to old</a>
                        <a className="dropdown-item" onClick={() => {changeSortBy("old-new");toggleSortBy("Old to new");} } >Old to new</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div className="resource-list-section">
        <div className="row">
            {(blogLists.length > 0 )?null:<div class="col-sm-12"><p>No blogs yet. Please check again later.</p></div>}
            {blogLists.slice(startIndex, startIndex+numItems).map((item, index) => {
                return <div className="col-sm-6 content-box-custom-container" key={index}  >
                    <ContentBoxCustom 
                        dataContent={item} 
                        setShowYoutubeVids = { setShowYoutubeVids } 
                        setShowYoutubeVidId = { setShowYoutubeVidId }
                        setShowSelfHostedVids = { setShowSelfHostedVids } 
                        setShowSelfHostedVidsUrl = { setShowSelfHostedVidsUrl }
                        /></div>
            })}
        </div>
    </div>
    
    {/* pop up */}
    {(showYoutubeVids)?<BlogResourcesYoutubeModal 
        setShowYoutubeVids = { setShowYoutubeVids } 
        youtubeVidId = { youtubeVidId }
        />:""}
    {(showSelfHostedVids)?<BlogResourcesSHVidsModal 
        setShowSelfHostedVids = { setShowSelfHostedVids } 
        showSelfHostedVidsUrl = { showSelfHostedVidsUrl }
        />:""}
    

    </>
    );
}
 
export default BlogResourceContent;