import React from 'react'
import { Link } from 'react-router-dom'
import getFirebase from '../modules/firebase';
import PropTypes from "prop-types"

// export const FormErrors = ({formErrors}) => (
//   <div className='formErrors'>
//     {Object.keys(formErrors).map((fieldName, i) => {
//       if(formErrors[fieldName].length > 0){
//         return (
//           <div className="alert alert-danger" key={i}>{formErrors[fieldName]}</div>
//         )
//       } else {
//         return '';
//       }
//     })}
//   </div>
// );

const Thanks = () => (
  <>
    <div className="row">
      <div className="col-10">
        <h1 className="card-title heading-type-1-second m-0">Thank you for your message.</h1>
      </div>
    </div>
    <div className="row">
      <div className="col col-md-11 mt-4">
        <p className="p-type-3 mt-2">No matter where you are in the world, you’ll hear from
          us ASAP. In the meantime, check out our resources on the
          Grammarly blog.</p>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <Link to="https://www.grammarly.com/blog/category/product" target="_blank">
          <button type="button" className="btn btn-primary btn-cta thankyou-page-btn my-3 mt-md-4 mx-2 mx-md-0">Blog</button>
        </Link>
        <Link to="/" className="btn btn-primary btn-cta btn-ghost-green thankyou-page-btn my-3 mt-md-4 mx-2 mx-md-0 ml-md-4">Back Home</Link>
      </div>
    </div>
  </>
);

class ContactFrom extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      isLoading: false,
      thanks: false,
      formErrors: {error: '', name: '', email: '', message: ''},
      emailValid: false,
      nameValid: false,
      messageValid: false,
      formValid: false
    };
  }

  handleSubmit = (event) => {
    const { name, email, message, formValid } = this.state;
    const { type } = this.props;
    event.preventDefault();
    if (formValid) {
      console.log(type === 'talk');
      this.setState({isLoading: true});
      const date = new Date().toISOString().split('T');
      //Submit the form to firebase
      const lazyApp = import('firebase/app');
      const lazyDatabase = import('firebase/database');
      Promise.all([lazyApp, lazyDatabase]).then(([firebase]) => {
        const database = getFirebase(firebase).database();
        let collectionRef = null;
        let newcollectionRef = null;
        if (type === 'talk'){
          collectionRef = database.ref('letsTalk');
          newcollectionRef = collectionRef.push();
          this.submitForm(name, email, message, date, newcollectionRef);
        }
        else{
          collectionRef = database.ref('requestDemo');
          newcollectionRef = collectionRef.push();
          this.submitForm(name, email, message, date, newcollectionRef);
        }
        
      })
    }
  };

  submitForm(name, email, message, date, newcollectionRef){
    console.log('REF', newcollectionRef);
        newcollectionRef.set({
          name,
          email,
          message,
          date: date[0]
        }).then((docRef) => {
          console.log('RESPONSE', docRef);
          this.setState({
            name: '',
            email: '',
            message: '',
            isLoading: false,
            thanks: true
          });
          this.props.history.push("/")
        }).catch(() => {
          let fieldValidationErrors = this.state.formErrors;
          fieldValidationErrors.error = 'Error while submitting, please try again.';
          this.setState({formErrors: fieldValidationErrors});
        });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let nameValid = this.state.nameValid;
    let messageValid = this.state.messageValid;

    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        break;
      case 'name':
        nameValid = value.length >= 3;
        fieldValidationErrors.name = nameValid ? '': ' is too short';
        break;
      case 'message':
        messageValid = value.length >= 10;
        fieldValidationErrors.message = messageValid ? '': ' is too short';
        break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
      emailValid: emailValid,
      nameValid: nameValid,
      messageValid: messageValid,
    }, this.validateForm);
  }

  validateForm() {
    const { nameValid, emailValid, messageValid } = this.state;
    this.setState({formValid: nameValid && emailValid && messageValid});
  }

  handleChange (e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value}, () => { this.validateField(name, value) });
  }

  render() {
    const {
      name,
      email,
      message,
      thanks,
      isLoading,
      formValid,
      formErrors
    } = this.state;
    const { type } = this.props;

    if (thanks)
      return <Thanks />;
    else {
      return (
        <form className="px-1 px-md-0" id="letsTalkForm" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col text-center text-md-left">
              <Link to="https://grammarly.com/contact/sales?source=enterprise" target="_blank">
                <button type="button" className={`btn btn-${type === 'demo' ? 'blue' : 'ghost'}`}>Get in touch</button>
              </Link>
              <Link to="https://grammarly.com/contact/sales?source=enterprise" target="_blank">
                <button type="button" className={`btn ml-3 btn-${type === 'demo' ? 'ghost' : 'blue'}`}>Let's Talk</button>
              </Link>
            </div>
          </div>
          {formErrors.error && (
            <div className="alert alert-danger">{formErrors.error}</div>
          )}
          <div className="form-group pt-4 pt-md-0 px-2 px-md-0 name-section">
            <label htmlFor="Name">Your Name</label>
            {formErrors.name && (
              <span className="error-msg mt-md-1 float-md-right mb-1 mb-md-0 d-block">{formErrors.name}</span>
            )}
            <input type="text" className="form-control" id="name" name="name" value={name} placeholder="" onChange={(e) => this.handleChange(e)}/>
          </div>
          <div className="form-group pt-2 px-2 px-md-0 email-section">
            <label htmlFor="Email">Your Email at work</label>
            {formErrors.email && (
              <span className="error-msg mt-md-1 float-md-right mb-1 mb-md-0 d-block">{formErrors.email}</span>
            )}
            <input type="email" name="email" className="form-control" id="email" value={email}  aria-describedby="emailHelp" placeholder="" onChange={(e) => this.handleChange(e)}/>
          </div>
          <div className="form-group pt-2 px-2 px-md-0 how-help-section">
            <label htmlFor="HowCanWeHelp">How can we help?</label>
            {formErrors.message && (
              <span className="error-msg mt-md-1 float-md-right mb-1 mb-md-0 d-block">{formErrors.message}</span>
            )}
            <textarea className="form-control" id="HowCanWeHelp" name="message" rows="6" placeholder="Tell us more about your role, your goals, and any questions we can help answer."  onChange={(e) => this.handleChange(e)} value={message} />
          </div>
          <button type="submit" disabled={isLoading || !formValid} className="btn btn-primary btn-cta my-3 my-md-0 mt-md-3 mx-2 mx-md-0">Submit</button>
        </form>
      );
    }
  }
}

ContactFrom.defaultProps = {
  type: `talk`,
};

ContactFrom.propTypes = {
  type: PropTypes.string
};

export default ContactFrom;
