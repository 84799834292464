import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import ServiceForm from "../../components/Form";
import ReactGA from "react-ga";
import {Link} from "react-router-dom";

class FrostSullivanLandingPage extends React.Component {
  state = {};

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="frost-and-sullivan-landing-page ">
        <div className="container-fluid px-0">
          <div className="headerContainer">
            <div className="container px-lg-0">
              <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center txt-section">
                  <img className="img-fluid logo-img" src={require("../../assets/images/landing-page/frost-and-sullivan-lp/frost-sullivan-logo.png")} alt="" />
                  <h1 className="pr-md-1">
                    Grammarly for
                    <br class="d-none d-lg-block" /> Customer
                    <br class="d-none d-lg-block" /> Experience
                  </h1>
                  <p className="">
                    Get started with a three-month
                    <br class="d-none d-lg-block" /> Grammarly Business trial for your team.
                  </p>
                  <a className="btn btn-primary btn-talk btn-cta" href="#getStarted-form-section">Try for 3 Months</a>
                </div>
                <div className="col-lg-6 img-section">
                  <div className="h-100 d-flex justify-content-left justify-cintent-lg-center align-items-center ">
                    <img className="img-fluid banner-hero-image" src={require("../../assets/images/landing-page/frost-and-sullivan-lp/banner-img.png")} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="container-fluid py-65p-custom py-sm-30p-custom form-section" id="getStarted-form-section">
            <div className="container">
              <div className="row ">
                <div className="col-sm-6 d-flex flex-column justify-content-center">
                  <h2 className="heading-type-2 heading-type-2-44p">Get Started.</h2>
                  <ul className="green-tick">
                    <li>
                      <p className="para-type-2">Sign up to redeem a 3-month trial</p>
                    </li>
                    <li>
                      <p className="para-type-2">Detect over 50 tones in writing</p>
                    </li>
                    <li>
                      <p className="para-type-2">Edit full sentences with one-click suggestions</p>
                    </li>
                    <li>
                      <p className="para-type-2">Create a custom style guide</p>
                    </li>
                    <li>
                      <p className="para-type-2">Track your team’s usage and performance</p>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <div className="form-section-wrapper">
                    <iframe src="https://go.grammarly.com/l/894581/2021-01-20/4bkr" width="100%" height="500" type="text/html" frameborder="0" allowTransparency="true" style={{border: '0'}}></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container-fluid hacking-book-section light-blue-container py-50p-custom py-sm-30p-custom">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 text-center img-section">
                  <img className="img-fluid mb-4 " src={require("../../assets/images/landing-page/frost-and-sullivan-lp/cx_ebook_img.png")} alt="" />
                </div>
                <div className="col-sm-6 txt-section d-flex flex-column justify-content-center">
                  <h2 className="heading-type-2 heading-type-2-32p pr-lg-5">Get the Ebook: Hacking Customer Experience</h2>
                  <p class="w-xl-75per-custom pr-4 pr-md-3 mb-8p-custom">Explore core customer experience challenges and how intelligent solutions can improve team operations.</p>
                  <ReactGA.OutboundLink className="anchor-type-1" to="#">
                    Download
                  </ReactGA.OutboundLink>
                </div>
              </div>
            </div>
          </section>

          <div className="container-fluid light-grey-container py-65p-custom py-sm-30p-custom  related-resources-section">
            <div className="container">
              <div className="row text-center">
                <div className="col-12">
                  <h2 className="heading-type-2-32p mb-xl-0">Related Resources</h2>
                </div>
              </div>

              <div className="row pt-65p-custom rrs-block-section">
                <div className="col-md-4 rrs-block-tile">
                  <img src={require("../../assets/images/landing-page/frost-and-sullivan-lp/resources-1.png")} alt="" />
                  <p className="resource-title">BLOG</p>
                  <h3 className="heading-type-3 pr-xl-1">Reorganize your workbench to unlock agent effectiveness and engagement</h3>
                  <div className="content">
                    <p className="m-0 pr-xl-3">Despite the reality that customers spend more with brands to which they are loyal, nearly a third of customer-facing leaders perceive customer support as an expense.</p>
                  </div>
                  <div className="cta-link-section">
                    <Link to="/reorganize-your-workbench">Read more</Link>
                  </div>
                </div>

                <div className="col-md-4 rrs-block-tile">
                  <img src={require("../../assets/images/landing-page/frost-and-sullivan-lp/resources-2.png")} alt="" />
                  <p className="resource-title">BLOG</p>
                  <h3 className="heading-type-3">Deliver a robust channel experience with data and insights</h3>
                  <div className="content">
                    <p className="m-0 pr-xl-10px-custom">A seamless journey is the hallmark of an excellent customer experience. While many companies look to their channel strategy to deliver that seamless experience, the key actually lies in customer data.</p>
                  </div>
                  <div className="cta-link-section">
                    <Link to="/deliver-robust-channel-experience">Read more</Link>
                  </div>
                </div>

                <div className="col-md-4 rrs-block-tile">
                  <img src={require("../../assets/images/landing-page/frost-and-sullivan-lp/resources-3.png")} alt="" />
                  <p className="resource-title">BLOG</p>
                  <h3 className="heading-type-3 pr-xl-3">Three Steps to Combat the Cost of Ineffective Communication</h3>
                  <div className="content">
                    <p className="m-0 pr-xl-10px-custom">Communication is often an afterthought when leaders are thinking about their bottom line. However, when it comes to effective communication between employees and customers, it is mission-critical for a high-performing customer support organization.</p>
                  </div>
                  <div className="cta-link-section">
                    <Link to="/three-steps-combat-ineffective-communication">Read more</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </Layout>
    );
  }
}

export default FrostSullivanLandingPage;
