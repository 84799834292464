import React, { useState } from "react";
import $ from "jquery";

const MobileStickySortOption = (props) => {
    const setShowSidebarClass = props.setShowSidebarClass;
    const changeSortBy = props.changeSortBy;

    const [ sortByLabel, setSortByLabel ] = useState("Sort by");

    const toggleSortBy = (sortByLabel) => {
        $(".dropdown-menu-sort-by").toggle(); 
        setSortByLabel(sortByLabel);
    }

    return (  
    <div className="mobile-sticky-sort-option-container w-100">
        <div className="row">
            <div className="col-6">
                <a href="/#"
                onClick={(e) => { e.preventDefault(); setShowSidebarClass("");} }
                >Filter</a>
            </div>
            <div className="col-6">
                <a href="/#"
                onClick={(e) => { e.preventDefault(); toggleSortBy("Sort by");} }
                >{sortByLabel}</a>
            </div>

            {/* Sort by otpions */}
            <div className="dropdown-menu dropdown-menu-sort-by" aria-labelledby="dropdownMenuButton" >
                <a className="dropdown-item" onClick={() => {changeSortBy("new-old");toggleSortBy("New to old");} } >New to old</a>
                <a className="dropdown-item" onClick={() => {changeSortBy("old-new");toggleSortBy("Old to new");} } >Old to new</a>
            </div>

        </div>
    </div>    
    );
}
 
export default MobileStickySortOption;