import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import ServiceForm from "../../components/Form";

class QuonticCaseStudy extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="zapier-case-study-page quontic-case-study-page ">
        <div className="container-fluid px-0">
          
          <div className="header-container-mobile">
            <div className="container d-lg-none">
              <div className="row justify-content-end">
                <div className="col-4 pt-5 pb-4">
                  <img className="img-fluid " src={require("../../assets/images/case-study/quontic-case-study/logo.png")} alt="" />
                </div>
              </div>
            </div>
          </div>
          
          <div className="headerContainer">
            <div className="container px-lg-0">
              <div className="row">
                <div className="col-lg-9 px-lg-0">
                  <h6 className="pre-header">CASE STUDY</h6>
                  <h1 className="pr-md-1">How Quontic uses communication<br className="d-none- d-lg-block" /> to bridge talent, innovation, and<br className="d-none d-lg-block" /> culture gaps</h1>
                  <p className="py-md-3 pr-md-5">Quontic leveraged Grammarly Business to bring their unique<br className="d-none d-lg-block" /> culture to the forefront and engage the right candidates.</p>
                </div>
                <div className="col-lg-3 d-flex justify-content-center flex-column pr-lg-0">
                  <img className=" header-desktop-img img-fluid d-none d-lg-block " src={require("../../assets/images/case-study/quontic-case-study/logo.png")} alt="" />  
                </div>
              </div>
            </div>
          </div>
          
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="IntroPara">
                  <a href="https://www.quonticbank.com/" target="_blank">Quontic</a> — the disruptive bank — takes innovation and financial empowerment seriously. To effectively deliver on those promises to their global community, the company knew a transformation would be integral to its strategy.
                </p>
                <p>
                  In a highly regulated industry, however, transitioning from a traditional brick and mortar institution into a fully operable digital bank is no small feat. To help with the transition, Quontic’s Chief Innovation Officer, Patrick Sells, understood that talent was going to play a major role. To ensure Quontic was positioned to compete for the right talent, Sells took an innovative approach. He decided on a total overhaul of Quontic’s company culture, and he knew he’d need the communication tools in place to direct his strategy.
                </p>
              </div>
            </div>
            <div className="row my-5 tilesContainer">
              <div className="col-md-4 mb-5 mb-md-0">
                <div className="row h-100">
                  <div className="col-12">
                    <div className="green-container container-cr rounded d-flex">
                      <div className="h-100 d-flex flex-column justify-content-between">
                        <h1>15%</h1>
                        <h3 className="pt-4">increase in <br className="d-none- d-xl-block" />headcount</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <div className="row h-100">
                  <div className="col-12">
                    <div className="blue-container container-cr rounded d-flex">
                      <div className="h-100 d-flex flex-column justify-content-between">
                        <h1>10x</h1>
                        <h3 className="pt-4">the national banking average for employee increase</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row h-100">
                  <div className="col-12">
                    <div className="purple-container container-cr rounded d-flex">
                      <div className="h-100 d-flex flex-column justify-content-between">
                        <h1>50%</h1>
                        <h3 className="pt-4">reduction in recruiter spend</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 mt-md-5 pt-3 pt-md-5">
              <div className="col-12">
                <h3>The Challenge</h3>
                <p className="pt-4">
                  Acquiring top talent in the banking industry is a struggle across the board — <a href="https://www.pwc.com/gx/en/ceo-survey/2019/Theme-assets/reports/banking-capital-markets-trends-2019-report.pdf" target="_blank">60% of banking CEOs believe</a> it’s become more difficult to hire workers in their industry. In addition, Sells didn’t want to recruit just any employee. He set out to define an identity and culture that would set the bar for new and existing talent. To get buy-in and adoption from the current team, he needed a mechanism to help facilitate and reinforce this monumental workplace evolution over time. Sells knew that communication plays a major role in achieving success. 
                </p>
              </div>
            </div>
          </div>
          <div className="QuoteContainer">
            <div className="container px-lg-0">
              <div className="row">
                <div className="col-lg-7 col-md-6">
                  <h3>The banking industry has a stale reputation. We needed to overcome that in order to achieve the digital bank that we wanted. Our goal is to break the system for financial empowerment. Our culture needed to reflect that."</h3>
                  <h6 className="quote-author mt-5 mr-2 mr-md-0 pr-4 pr-md-0">
                    Patrick Sells
                    <br />
                    Chief Innovation Officer
                    <br />
                    Quontic  
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-3 mb-md-5 pb-4 pb-md-5">
            <div className="row mt-3">
              <div className="col-12">
                <h3>The Solution</h3>
              </div>
            </div>
            <div className="row mt-4 mb-3 mb-md-4 pb-3 pb-md-5">
              <div className="col-12">
                <p>
                Quontic’s new culture is driven by continuous progress and experimentation. They brought in Grammarly Business to make these principles tangible for the team and give them the tools to put them into practice. Seamlessly integrating across devices and platforms, the AI-powered digital writing assistant works in the background, providing customized suggestions to improve user communication. With 140 employees that speak 14 different languages, the product supported fluency in written communication and insurance for clear and accurate customer-facing communications. The tone and clarity features helped strengthen communication and relationships among employees. With their culture, mission, and vision clearly articulated, Quontic team members at all levels of the organization were able to share it effectively and consistently with prospective candidates. Soon, applications began rolling in.
                </p>
              </div>
            </div>
            <div className="row justify-content-center mt-3 mt-md-4">
              <div className="col-md-8">
                <h3>
                  <span className="green-highlight">We wanted employees to send high-quality communications,</span> create better customer experiences, and reinforce the culture. Grammarly does just that.
                </h3>
              </div>
            </div>
          </div>
          
          <div className="green-container my-3 my-md-5 py-3 py-md-5">
            <div className="container py-5">
              <div className="row">
                <div className="col-12">
                  <h3>The Results</h3>
                  <p className="mt-3">
                    Since implementing Grammarly Business, Quontic has seen correlated increases in hiring and employee satisfaction. Following the realization of their cultural evolution, and aided by their partnership with Grammarly Business, they saw an employee increase 10x the industry average, a reduction in recruiter spend, and a reputation of being the premier digital bank to work for. Considering that the number of people employed in the Commercial Banking industry in the US <a href="https://www.ibisworld.com/industry-statistics/employment/commercial-banking-united-states/" target="_blank">increased</a> a sparse 0.3% on average over the last five years between 2015 and 2020, Quontic is certainly headed in the right direction. 
                  </p>
                </div>
              </div>
              <div className="row justify-content-center mt-3 mt-md-4 pt-md-5">
                <div className="col-md-8">
                  <h3>
                    “Grammarly helped us achieve our goals. The culture side has been phenomenal. <span className="green-highlight">Talented people across the country have applied because they want to be a part of our story.”</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="container my-3 my-md-5 py-4 py-md-5 three-tiles">
            <div className="row text-center">
              <div className="col-12">
                <h3>Keys to Success</h3>
              </div>
            </div>
            <div className="row mt-3 mt-md-5">
              <div className="col-md">
                <img src={require("../../assets/images/case-study/quontic-case-study/img-1.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Set clear expectations.</span>
                </div>
                <p className="mt-4">To achieve a goal as significant as a culture change, a roadmap to success is vital. In order for the change to take effect, Patrick had to make the goals specific, measurable, and attainable. When they stopped to define their identity, Quontic’s productivity exploded. Communication was an integral part of shaping identity as it functioned as the compass to guide Quontic on their culture change journey.</p>
              </div>
              <div className="col-md mt-4 mt-md-0">
                <img src={require("../../assets/images/case-study/quontic-case-study/img-2.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Adopt tech tools to reinforce workplace transformation.</span>
                </div>
                <p className="mt-4">
                  High-performing cultures don’t occur overnight. It’s an ecosystem that requires measuring, reinforcing, and adopting. Grammarly Business helped Quontic reinforce their culture shift by providing the communication tools to deliver better communication between colleagues, internal communication, and external written communication. With the added confidence in the correspondences sent out, Quontic was able to focus on creating a digital banking experience for their customers.
                </p>
              </div>
              <div className="col-md mt-4 mt-md-0">
                <img className="" src={require("../../assets/images/case-study/quontic-case-study/img-3.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Build the employer value proposition by enhancing communication.</span>
                </div>
                <p className="mt-4">It takes talent to progress business. Thanks to Quontic’s culture shift and notable success, sourcing top talent is more attainable than ever. They achieved this by creating a favorable employee experience anchored in effective communication—both internally and externally more effectively both externally and internally and doubling-down on bridging gaps with technology in their product offerings. Their efforts garnered Quontic a valuable reputation of being the ideal place to work.</p>
              </div>
            </div>
          </div>
          <div className="blue-container pb-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      {/* <a href="#"  
                      className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction> */}
                      <CallToAction to="https://www.grammarly.com/contact/sales?source=enterprise" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto">
                        Get in touch
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default QuonticCaseStudy;
