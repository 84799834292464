import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";
import { Link } from "react-router-dom";

class ThreeStepsCombatIneffectiveCommunication extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Three Steps to Combat the Cost of Ineffective Communication</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/three-steps-combat-ineffective-communication/desktop-banner.png")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/three-steps-combat-ineffective-communication/mobile-banner.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>February 2, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                
                <p className="IntroPara">Communication is often an afterthought when leaders are thinking about their bottom line. However, when it comes to effective communication between employees and customers, it is mission-critical for a high-performing customer support organization.</p>
                
                <p>Add to the fact that employee turnover, increasing workplace diversity, and a growing number of platforms and systems have long put pressure on maintaining quality while improving efficiency.  The pandemic has only increased these challenges with remote work and increasingly demanding customers. Many leaders feel they are trapped in an unrelenting trade-off between cost, consistency, and quality.</p>

                <p>Consider this, ineffective communication between a company and its customers is like a game of telephone. Well-intentioned companies that struggle with consistent communication believe they are speaking in ‘blue.’ However, their customers are receiving the message in ‘magenta,’ leaving them feeling confused and frustrated. When leaders look to deliver effective and meaningful customer relationships, unpacking internal drivers is vital to clearing up ineffective communication. As communication is integral to all facets of an organization, leaders need to develop and implement a communication strategy to achieve success on a consistent basis.</p>

                <h2 className="my-4">1. Identify your goals</h2>
                <p>In order to communicate effectively at scale, an organization needs to have goals in place. These goals should align with your business outcomes, so it’s important to perform in-depth research. What is the standard for your customer experience? How would you like the organization to be perceived? Once you define these goals, you can then start building out the framework to support achieving your targets.</p>

                <h2 className="my-4">2. Build your framework</h2>
                <p>Now that your goals are established, design the company framework for your communication strategy. A communication framework is a tool for planning communication with your employees and customers. It serves as the internal and external identity and what employees and customers alike can expect when interacting with the organization. Leverage tools like a <a href="https://www.grammarly.com/blog/style-guide/" target="_blank">style guide</a> to act as the compass for your framework. By defining the framework, companies can focus on maintaining consistency in the customer experience at scale.</p>

                <h2 className="my-4">3. Focus on consistency</h2>
                <p>In customer experience, consistency is everything. It’s the mechanism that facilitates positive experiences, turning customers into loyal followers of your organization. In this fast-paced environment, maintaining consistency is more important than ever. For strategic companies, the smart play is to automate communication processes to enable consistency at scale. With the help of AI tools like Grammarly Business, companies can empower customer support teams to focus on the human elements of customer support, elevating the customer experience overall.</p>

                <h2 className="my-4">Improve the line item that flies under the radar</h2>
                <p>The state of the world has posed additional challenges to organizational leaders. Now more than ever, leaders have to be strategic with their bottom line and how they invest in their organization. Communication is such a dynamic financial indicator, that is—if organizations recognize its value. In order to move the needle, create a communication strategy by identifying your goals, building a framework, and executing with consistency. When companies put these elements into play, they begin to align with their customers, sustaining growth, satisfaction, and retention long term.</p>
                
                <p>If you’d like to see how Grammarly Business can help your customer-facing teams stay consistent to deliver on a communication strategy, <Link to="/ccvirtual">sign up for a free three-month trial</Link> today.</p>
                
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ThreeStepsCombatIneffectiveCommunication;
