import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";
import { Link } from "react-router-dom";

class TheValueofLiveAgents extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>AI and CX: The Value of Live Agents</h1>
              </div>
            </div>
          </div>
          <div className="mt-4 header-section-content">
            <div className="col-12 px-0">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/the-value-of-live-agents/the-value-of-live-agents-desktop.png")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/the-value-of-live-agents/the-value-of-live-agents-mobile.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>October 20, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                
                <p className="IntroPara">From automated phone services to online support features and, most recently, social media, there’s no doubt that technology has expanded customer support teams’ reach and capacity. But in an industry dominated by customer experience and sentiment, human-to-human interactions are invaluable.</p>
                <img className="img-fluid w-100 mb-3" src={require("../../assets/images/blog/the-value-of-live-agents/the-value-of-live-agents-In-line-image.png")} alt="" />

                <h2 className="my-4">The human touch</h2>
                <p>According to Forrester, <a href="https://www.customerserv.com/blog/why-ai-wont-replace-humans-call-center" target="_blank">83 percent</a> of consumers say they prefer speaking to human agents over chatbots because they can better understand their needs and can answer multiple questions at once. Chatbots enable a company to respond to customers at all hours of the night and are useful for answering frequently asked questions that don’t require much investigation. However, bots cannot fulfill complex requests. They fail to personalize the experience and cannot comprehend, let alone de-escalate, confusion, or frustration.</p>

                <h2 className="my-4">Live agents can think on the fly</h2>
                <p>AI can streamline the process for a contact center agent and solve the most common customer requests; however, it lacks the intuition and resourcefulness that only humans can provide.</p>

                <h2 className="my-4">Live agents can solve new problems that arise</h2>
                <p>AI works based on a set of patterns or algorithms and is therefore limited to solving problems that it recognizes or occur in the same way. AI is at a disadvantage when there are no exact solutions and scenarios to learn from, and that’s where humans fill in the gaps.</p>
                <p>Brands with successful customer experience programs recognize the value of human-powered service paired with AI solutions. Combining human agents’ resourcefulness and authenticity with bot solutions’ efficiency is a savvy way to deliver a five-star experience.</p>

                <div className="container-fluid my-5 px-0">
                  <div className="green-container">
                    <div className="row no-gutters">
                      <div className="col-12 p-3">
                        <h3>“Because this period is challenging for most people, I've noticed that customers tend to share more about the struggles they're having. Human touch and empathy are becoming even more important than before the pandemic, so our team has been working on further polishing our responses to make sure they come across as sympathetic and considerate.”</h3>
                        <p><em>– Cristina Khrapach, Grammarly Customer Support Lead</em></p>
                      </div>
                    </div>
                  </div>
                </div>

                <h2 className="my-4">Translating human interaction to digital channels</h2>
                <p>Customer operations leaders who want to surpass the competition invest in the latest customer support technology to improve their CX. <a href="https://lumoa.me/customer-experience-stats" target="_blank">Twenty-six percent</a> of the top companies use CX technologies compared to just 7 percent of laggards.</p>
                <p>These teams leverage software platforms that can integrate sophisticated customer support tools and encourage engagement from customers.</p>
                <p>The complexity of customer interactions has increased with a rise in the number of communication channels. <a href="https://lumoa.me/customer-experience-stats" target="_blank">Nine out of every 10 consumers</a> expect a seamless omnichannel experience when moving from phone to text or chat to phone. Companies are increasingly investing in multi-channel experiences, and they must also invest in tools that can create consistency across every channel and device.</p>

                <h2 className="my-4">Live chat</h2>
                <p>Live chat is the new standard—<a href="https://www.smartinsights.com/user-experience/customer-experience-management-cxm/improve-customer-experience-intelligent-technology/" target="_blank">63 percent</a> of consumers are more likely to return to a website that offers live chat. When integrated with other support technologies, live chat can improve call resolution by 18 percent and decrease call handling time by 14 percent.</p>
                <p>Live chat features offer customers a chance to speak with someone who can provide them with complex answers and recommendations, humanizing the experience. Live chat also allows for quick responses, compared to emailing or calling a customer support line.</p>

                <h2 className="my-4">AI-powered customer communication</h2>
                <p>CX teams need sharp communication skills to engage customers positively with the significant value placed on live chat and digital omnichannel communication.</p>
                <p>Customer support teams can leverage <Link to="/yourteam/customer-experience">Grammarly Business</Link> to improve their communication beyond spelling and grammar. With features that detect over 40 different tones and provide full sentence rewrites, clear and professional communication is just a click away.</p>
                <p>Today, customer support teams are also increasingly distributed, and AI-powered communication tools can help those looking to improve their English writing skills. By correcting misused words and phrases in real-time, CX teams can gradually <Link to="/blog/upskill-more-efficient-cx-teams">upskill</Link> their English fluency.</p>
                <p>Delivering kind, well-written customer resolution tickets can go a long way toward increasing customer satisfaction. When CX teams use <Link to="/blog/ensure-consistent-branding-with-style-guides">consistent language</Link> that engages customers and is free of internal jargon, they can create an authentic, brand-forward experience across channels.</p>
                
                <div class="container-fluid my-5 px-0">
                  <div class="green-container">
                    <div class="row no-gutters">
                      <div class="col-12 p-3">
                        <p>Check out our latest ebook, Hacking CX: The New Standard for Customer Communication, to learn more about customer support teams’ challenges and how quality communication can improve productivity and customer satisfaction. <Link to="/hacking-cx-ebook">Download now!</Link></p>
                      </div>
                    </div>
                  </div>
                </div>
                
                <h2 className="my-4">Boosting productivity</h2>
                <p>Salesforce reports that <a href="https://www.forbes.com/sites/forbestechcouncil/2020/10/15/five-real-ways-artificial-intelligence-is-upleveling-customer-service/#3be6fee82db7" target="_blank">24 percent</a> of customer service teams are already using AI, and 56 percent seek AI opportunities. Here are some of the following ways that AI-powered tools can boost your productivity and brand.</p>

                <h2 className="my-4">Aggregating feedback</h2>
                <p>Manually processing and reviewing the feedback that customers submit via social media and email is time-consuming in a way that few teams can afford. AI can analyze text and sentiment, turning qualitative feedback into actionable insights. AI can categorize customer comments and complaints into specific issues. These insights can help customer support teams understand their level of service better.</p>

                <h2 className="my-4">Brand governance</h2>
                <p>Breaking down silos between individuals and teams and cutting down on unnecessary back-and-forth saves time. Features such as Grammarly Business <Link to="/styleguide-cs">style guides</Link> can ensure frontline agents use company-approved language and branded words. One enterprise customer-support team caught over 300 errors per day using the Grammarly style guide feature.</p>

                <h2 className="my-4">Cutting contact resolution time</h2>
                <p>Among teams using AI for customer service, <a href="https://www.forbes.com/sites/forbestechcouncil/2020/10/15/five-real-ways-artificial-intelligence-is-upleveling-customer-service/#3be6fee82db7" target="_blank">82 percent</a> report an increase in first contact resolution (FCR) rates. AI solutions can help triage support tickets, queuing up the most urgent or high-priority issues as they appear. Secondly, AI tools that focus on clarity and conciseness while offering to edit in real-time can decrease writing time per ticket. One enterprise customer operation team reduced contact per ticket by 12% with Grammarly Business.</p>
                
                <h2 className="my-4">More time for high value work</h2>
                <p>When your customer support agents save time on aggregating feedback, aligning with company standards, and responding to resolution tickets, they have more time to work on higher-touch tasks. They feel less overwhelmed and more prepared to interact with customers and do their best work.</p>
                <p>We can’t train bots to interact with customers on multiple channels yet, but we can adopt solutions that make our support teams <Link to="/blog/upskill-more-efficient-cx-teams">more efficient</Link> and engaging. If you’d like to learn more about Grammarly Business for CX teams, <Link to="/yourteam/customer-experience">visit our website</Link> or <a href="https://www.grammarly.com/contact/sales?source=enterprise" target="_blank">request a demo</a> to see it in action.</p>

              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TheValueofLiveAgents;
