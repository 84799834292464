import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

function Meta({ description, lang, title }) {
  const defaultMeta = {
    title: 'Grammarly Business',
    description: 'Grammarly for business',
    meta: '',
    lang: 'en',
    author: '@grammarly'
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || defaultMeta.lang,
      }}
      title={title || defaultMeta.title}
      // titleTemplate={`%s | ${title || defaultMeta.title}`}
      titleTemplate={title || defaultMeta.title}
    >
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description || defaultMeta.description} />

      <meta property="og:title" content={title || defaultMeta.title} />
      <meta property="og:description" content={description || defaultMeta.description} />
      <meta property="og:image" content={require('../assets/images/eBook_FrontCover.png')} />
      <meta property="og:image:type" content="image/png" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title || defaultMeta.title} />
      <meta name="twitter:description" content={description || defaultMeta.description} />
      <meta name="twitter:image:src" content={require('../assets/images/eBook_FrontCover.png')} />
      <meta name="twitter:creator" content={defaultMeta.author} />

      <link rel="apple-touch-icon" sizes="57x57" href={require('../assets/images/favicons/apple-icon-57x57.png')} />
      <link rel="apple-touch-icon" sizes="60x60" href={require('../assets/images/favicons/apple-icon-60x60.png')} />
      <link rel="apple-touch-icon" sizes="72x72" href={require('../assets/images/favicons/apple-icon-72x72.png')} />
      <link rel="apple-touch-icon" sizes="76x76" href={require('../assets/images/favicons/apple-icon-76x76.png')} />
      <link rel="apple-touch-icon" sizes="114x114" href={require('../assets/images/favicons/apple-icon-114x114.png')} />
      <link rel="apple-touch-icon" sizes="120x120" href={require('../assets/images/favicons/apple-icon-120x120.png')} />
      <link rel="apple-touch-icon" sizes="144x144" href={require('../assets/images/favicons/apple-icon-144x144.png')} />
      <link rel="apple-touch-icon" sizes="152x152" href={require('../assets/images/favicons/apple-icon-152x152.png')} />
      <link rel="apple-touch-icon" sizes="180x180" href={require('../assets/images/favicons/apple-icon-180x180.png')} />
      <link rel="icon" href={require('../assets/images/favicons/favicon-32x32.png')} />
      <link rel="icon" type="image/png" sizes="192x192" href={require('../assets/images/favicons/android-icon-192x192.png')} />
      <link rel="icon" type="image/png" sizes="32x32" href={require('../assets/images/favicons/favicon-32x32.png')} />
      <link rel="icon" type="image/png" sizes="96x96" href={require('../assets/images/favicons/favicon-96x96.png')} />
      <link rel="icon" type="image/png" sizes="16x16" href={require('../assets/images/favicons/favicon-16x16.png')} />
      <meta name="msapplication-TileColor" content="#15C39A" />
      <meta name="msapplication-TileImage" content={require('../assets/images/Grammarly-Business-Logo.svg')} />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  )
}

Meta.defaultProps = {
  lang: `en`,
  description: ``
};

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string
};

export default Meta
