import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class EnsureConsistentBrandingWithStyleGuides extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Ensure Consistent Branding with Style Guides</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/ensure-consistent-branding-with-style-guides/545_Desktop-Hero-image_2x.jpg")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>June 28, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">
                  Customers often choose brands based on need, but they stay with brands based on trust. <Link to="/blog/customer-trust-consistency" > Consistent communication
                  </Link> is at the core of how brands earn that trust.
                </p>

                <p>
                  But like every leader of a growing marketing organization knows, creating and maintaining that consistency is anything but straightforward. With a growing number of communication channels and <a href="/blog/technology-cx-goals" target="_blank">
                    technologies to wrangle
                  </a>, time zones to coordinate, and internal documents to reconcile, overseeing brand-building language is a challenge.
                </p>

                <p>
                  To help alleviate some of this complexity, and facilitate more consistent brand stewardship across teams and departments, Grammarly Business <a href="/blog/grammarly-business-style-guide" target="_blank">
                    now offers style guide suggestions
                  </a> that brand leaders can tailor for their organizations.
                </p>

                <p>Here we share three types of branding to consider when setting up your company style guide:</p>

                <ol>
                  <li className="my-2">
                    <b>Product and feature names:</b> Your product is one of the most common, and arguably, most important ways your customers will experience your brand. Ensuring that written communications always reflect the correct spelling and capitalization helps customers transition seamlessly from reading product-focused content to working within the user interface. Repetition also builds associations between names and the product experience, helping to differentiate from other similar or competitive offerings. 
                    <div className="container-fluid px-0">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <img className="mt-4 mb-5 img-fluid" src={require("../../assets/images/blog/ensure-consistent-branding-with-style-guides/545_Desktop Inline image 1.jpg")} alt="" />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="my-2">
                    <b>Culture reflective language:</b> Think about intentional choices you’ve made around the language used internally. For example, how do you refer to employees, customers, long-term goals, and current market position? These choices communicate your brand values, and when used consistently, can help bridge your internal culture with external perception. For example, at Grammarly, we refer to employees as team members to connote more than just a contractual relationship—we share a sense of mission, commitment to shared goals, and empathy toward one another. Using this language consistently enables us to reinforce these values. 
                    <div className="container-fluid px-0">
                      <div className="row no-gutters">
                        <div className="col-12">
                          <img className="mt-4 mb-5 img-fluid" src={require("../../assets/images/blog/ensure-consistent-branding-with-style-guides/545_Desktop Inline image 2.png")} alt="" />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="my-2">
                    <b>Branded terms:</b> There are certain terms that just always need to be right—period. Misspellings or inconsistencies in the company name, hosted event titles, or executive team members not only look unprofessional but also can quickly erode the customer trust that you’ve worked so hard to earn.
                  </li>
                </ol>

                <p>As a leader, you can’t be everywhere to ensure your teams are using brand language appropriately and consistently. However, you can rest easier knowing your team is empowered with Grammarly Business’ real-time writing suggestions to turn every communication into a brand-building interaction.</p>

              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a
                        href="#"
                        className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                        onClick={(e) => {
                          e.preventDefault();
                          this.showConnectForm();
                        }}
                      >
                        Learn More
                      </a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EnsureConsistentBrandingWithStyleGuides;
