import React from "react";
import CallToAction from "../../components/Button";
import $ from "jquery";
import Layout from "../../components/Layout";

import CompanySelectForm from '../../components/form-component/CompanySelectForm';

class Newhome extends React.Component {
  state = {
    url: "",
  };

  constructor() {
    super();

    this.UTMSource = "";
    this.UTMSourceFlag = false;
    this.getURLQuery();

    // this.submitForm = this.submitForm.bind(this);
  }

  getURLQuery() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("utm_source")) {
      this.UTMSource = urlParams.get("utm_source");
      this.UTMSourceFlag = true;
      console.log(this.UTMSource);
    } else {
      this.UTMSource = "";
    }
  }

  showForm(event) {
    if (event.target.value != "1-100") {
      $(".Form-Intro-Container").hide();
      $(".form-container .form-elements").show();
    } else if (event.target.value == "1-100") {
      $(".form-container .initialBtn").hide();
      $(".form-container .selectedBtn").show();
      $(".form-container .form-elements").hide();
      $(".Form-Intro-Container").show();
    } else {
      $(".form-container .initialBtn").show();
      $(".form-container .selectedBtn").hide();
      $(".form-container .form-elements").hide();
      $(".Form-Intro-Container").show();
    }

    $(".connect-form").hide(); 
  }

  // submitForm() {
  //   if ($("#pardot-form")[0].checkValidity()) {
  //     var leadData = JSON.parse(JSON.stringify($("#pardot-form").serializeArray()));
  //     var leadObject = {};
  //     for (let i = 0; i < leadData.length; i++) {
  //       leadObject[leadData[i].name] = leadData[i].value;
  //     }

  //     this.setState({
  //       url:
  //         "https://grammarly-enterprise.com/thankyou?Firstname=" +
  //         leadObject.first_name +
  //         "&Lastname=" +
  //         leadObject.last_name +
  //         "&Email=" +
  //         leadObject.email +
  //         "&Phone=" +
  //         leadObject.phone +
  //         "&Company_Size__c=" +
  //         leadObject.companySize +
  //         "&Title=" +
  //         leadObject.title +
  //         "&Company=" +
  //         leadObject.company +
  //         "",
  //     });
  //   } else {
  //     $("#pardot-form")[0].reportValidity();
  //     console.log("Invalid");
  //   }
  // }

  showConnectForm(){
    $(".connect-form").show(); 
  }

  render() {
    return (
      <Layout className="new-home-landing-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row mt-5 pt-md-5">
              <div className="col-md-5 offset-md-1">
                <h1>A strong brand needs consistent writing</h1>
                <p className="py-3 pr-md-5">Help your whole team capture your company’s unique voice and style.</p>
                {/* <CallToAction to="https://www.grammarly.com/business/" className="green-btn mt-4" useTargetClass="yes">Learn More</CallToAction> */}
              </div>
              <div className="col-md-6 mt-0 pb-3 form-container">
                <CompanySelectForm />
              </div>
            </div>
            <div className="row sponsor-section-container justify-content-center pb-3">
              <div className="col-12 col-md offset-md-1 sponsor-lable text-center text-md-left mb-4 mb-md-0">
                <span className="pt-0 pt-md-2 sponsor-section-title">Join our 10,000+ customers:</span>
              </div>
              <div className="col-4 col-md">
                <img src={require("../../assets/images/newHome/Cisco@2x.png")} className="sponsor-logos" />
              </div>
              <div className="col-4 col-md d-none d-md-block">
                <img src={require("../../assets/images/newHome/A.png")} className="sponsor-logos" />
              </div>
              <div className="col-4 col-md">
                <img src={require("../../assets/images/newHome/Dell@2x.png")} className="sponsor-logos" />
              </div>
              <div className="col-4 col-md mt-md-0">
                <img src={require("../../assets/images/newHome/Expedia@2x.png")} className="sponsor-logos" />
              </div>
              <div className="col-12 col-md mt-3 mt-md-0 text-center">
                <img src={require("../../assets/images/newHome/Expensify@2x.png")} className="sponsor-logos" />
              </div>
            </div>
          </div>
          <div className="grey-container sponsor-section-container mt-4">
            <div className="container">
              <div className="row justify-content-center pb-5">
                <div className="col-md-10 text-center">
                  <h2 className="heading-type-2">Get Better Results, Everywhere They Write</h2>
                  <p className="px-md-5 pt-3">Quality writing is more than just mechanics. Grammarly Business goes deeper with real-time suggestions for readability, word choice, and tone.</p>
                </div>
              </div>
              <div className="row justify-content-around pt-md-5">
                <div className="col-md text-center text-md-left pr-5">
                  <img src={require("../../assets/images/newHome/Marketing@2x.png")} alt="" className="image-text-block" />
                  <h4 className="service-title mt-2">Marketing</h4>
                  <h5 className="service-text d-none d-md-block">Craft messaging that excites and engages your customers.</h5>
                  <div className="service-sub-title pt-3">Works with:</div>
                  <div className="row justify-content-around pt-3 pt-md-0">
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/Medium@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">Medium</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/Facebook 1@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">Facebook</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/Twitter 1@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">Twitter</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/Wordpress@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">Wordpress</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                  </div>
                </div>
                <div className="col-md mt-5 mt-md-0 pt-4 pt-md-0 text-center text-md-left pr-5">
                  <img src={require("../../assets/images/newHome/Sales@2x.png")} alt="" className="image-text-block" />
                  <h4 className="service-title mt-2">Sales</h4>
                  <h5 className="service-text d-none d-md-block">Write polished, compelling emails to close your deals.</h5>
                  <div className="service-sub-title pt-3">Works with:</div>
                  <div className="row justify-content-around pt-3 pt-md-0">
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/Gmail@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">Gmail</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/LinkedIn@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">LinkedIn</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/salesforce-1@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">Salesforce</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/GDocs@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">Google Docs</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                  </div>
                </div>
                <div className="col-md mt-5 mt-md-0 pt-4 pt-md-0 text-center text-md-left pr-5">
                  <img src={require("../../assets/images/newHome/Support@2x.png")} alt="" className="image-text-block" />
                  <h4 className="service-title mt-2">Support</h4>
                  <h5 className="service-text d-none d-md-block">Respond quickly with clear, polite, and friendly resolutions.</h5>
                  <div className="service-sub-title pt-3">Works with:</div>
                  <div className="row justify-content-around pt-3 pt-md-0">
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/zendesk-1@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">Zendesk</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/Freshdesk-1@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">freshdesk</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 d-md-none"></div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/Slack@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">Slack</div>
                    </div>
                    <div className="col-3 col-md-3 text-center">
                      <img className="services-sponsor-images" src={require("../../assets/images/newHome/Combined Shape@2x.png")} alt="" />
                      <div className="services-sponsor-image-text">Zoho Desk</div>
                    </div>
                    <div className="col-3 d-md-none"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row py-5">
              <div className="col-md-4 pt-4">
                <img className="section-icon mb-5" src={require("../../assets/images/newHome/Icon 01@2x.png")} alt="" />
                <h4>
                  <span className="green-text">Company dictionary</span>
                  <br />
                  Keep your team aligned with a shared library of words, terms, and phrases specific to your organization.
                </h4>
              </div>
              <div className="col-md-6 offset-md-2 align-self-center">
                <img src={require("../../assets/images/newHome/Graphic 1@2x.png")} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
          <div className="grey-container">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-6 align-self-center">
                  <img src={require("../../assets/images/newHome/Graphic 2@2x.png")} className="img-fluid" alt="" />
                </div>
                <div className="col-md-4 offset-md-2 pt-4">
                  <img className="section-icon mb-5" src={require("../../assets/images/newHome/Icon 02@2x.png")} alt="" />
                  <h4>
                    <span className="green-text">Names matter</span>
                    <br />
                    Make sure your company, product, and trademark names are properly spelled and capitalized.
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row py-5">
              <div className="col-md-4">
                <img className="section-icon mb-5" src={require("../../assets/images/newHome/Icon 03@2x.png")} alt="" />
                <div className="coming-soon-container mb-3">COMING SOON</div>
                <h4>
                  <span className="green-text">Brand voice and tone</span>
                  <br />
                  Convey your brand tone everywhere.
                </h4>
                <ul className="mb-0">
                  <li>Your website</li>
                  <li>Press releases</li>
                  <li>Sales emails</li>
                  <li>Support tickets</li>
                </ul>
              </div>
              <div className="col-md-6 offset-md-2 align-self-center">
                <img src={require("../../assets/images/newHome/Graphic 3@2x.png")} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
          <div className="grey-container">
            <div className="container">
              <div className="row pt-5">
                <div className="col-md-6 align-self-center">
                  <img src={require("../../assets/images/newHome/Graphic 4@2x.png")} className="img-fluid" alt="" />
                </div>
                <div className="col-md-4 offset-md-2 pt-4">
                  <img className="section-icon mb-5" src={require("../../assets/images/newHome/Icon 04@2x.png")} alt="" />
                  <div className="administration-tools-container mb-3">ADMINISTRATION TOOLS</div>
                  <h4>
                    <span className="green-text">Custom rules</span>
                    <br />
                    Rules are easy to create and update, so keeping your style guide up-to-date is a snap.
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="container pt-5">
            <div className="row py-5">
              <div className="col-md-4 offset-md-1 pt-4">
                <img className="section-icon mb-5" src={require("../../assets/images/newHome/Icon 05@2x.png")} alt="" />
                <div className="administration-tools-container mb-3">ADMINISTRATION TOOLS</div>
                <h4>
                  <span className="green-text">Reporting and statistics</span>
                  <br />
                  Get continuous feedback and make adjustments painlessly to fit your team’s needs.
                </h4>
              </div>
              <div className="col-md-5 offset-md-2 align-self-center">
                <img src={require("../../assets/images/newHome/graphic 5@2x.png")} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="row d-flex mt-5 justify-content-center">
              <div className="order-3 order-md-1 col-md-2 align-self-end text-left d-none d-md-flex">
                <img className="bottom-lines-left" src={require("../../assets/images/newHome/lines 1@2x.png")} alt="" />
              </div>
              <div className="order-2 order-md-2 col-md-8 mt-n5 pt-5 align-self-center text-center">
                <h2>Help Your Team Do Better Work</h2>
                <p className="mt-4">Get started today and instantly elevate the communication skills of your entire team.</p>
              </div>
              <div className="order-1 order-md-3 mr-n5 col-md-2 align-self-start text-right">
                <img className="bottom-lines-right" src={require("../../assets/images/newHome/lines 2@2x.png")} alt="" />
                <img className="bottom-dots-right mt-4 mr-5" src={require("../../assets/images/newHome/dots@2x.png")} alt="" />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col text-center">
                <CallToAction to="https://www.grammarly.com/business/" className="green-btn mt-4 ml-md-5">
                  Get Grammarly Business
                </CallToAction>
              </div>
              <div className="col-12 mt-4 ml-n5 text-left d-md-none">
                <img className="bottom-lines-left" src={require("../../assets/images/newHome/lines 1@2x.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Newhome;
