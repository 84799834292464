import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import ServiceForm from "../../components/Form";

class ZapierCaseStudy extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="zapier-case-study-page">
        <div className="container-fluid px-0">
          <img className="img-fluid d-lg-none" src={require("../../assets/images/case-study/zapier-case-study/Hero-4.png")} alt="" />
          <div className="headerContainer">
            <div className="container px-lg-0">
              <div className="row">
                <div className="col-lg-9 pr-lg-5">
                  <h6 className="pre-header">CASE STUDY</h6>
                  <h1 className="pr-md-1">How Zapier built a style guide in Grammarly Business to achieve consistent, on-brand writing</h1>
                  <p className="py-md-3 pr-md-5">Zapier used Grammarly Business to deliver real-time writing suggestions for branded terms, partner names, and other important words.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="IntroPara">
                  When the written word is the primary way your company communicates, it’s essential to ensure consistency. In order to seamlessly work across time zones, grow relationships with partners, take care of customers, and build a solid brand, individual team members need to act as one.
                </p>
                <p>
                  The team at <a href="https://zapier.com/home" target="_blank">
                    Zapier
                  </a>, which helps customers connect thousands of apps through customized, automated processes, used a number of documents that stored names, phrases, and terms to help them create a consistent experience for both customers and partners. These documents served as references for team
                  members when creating internal materials or external marketing assets.
                </p>
                <p>As the organization grew, the number of documents increased, resulting in multiple style guides being used across teams. Team members found it challenging to know which information was accurate and updated.</p>
                <p>
                  Janine Anderson, Managing Editor at Zapier, turned to Grammarly Business to solve these challenges. She was not only able to add relevant branded terms to a dynamic style guide, but she was also able to consolidate style guides across the organization. The result? More consistent
                  and on-brand writing across Zapier’s teams.
                </p>
              </div>
            </div>
            <div className="row my-5 tilesContainer">
              <div className="col-md-4 mb-5 mb-md-0">
                <div className="row">
                  <div className="col-12">
                    <div className="green-container rounded d-flex">
                      <div className="align-self-end px-4 pb-4">
                        <h1>159</h1>
                        <h3 className="pt-4">Custom rules set up in the style guide</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5 mb-md-0">
                <div className="row">
                  <div className="col-12">
                    <div className="blue-container rounded d-flex">
                      <div className="align-self-end px-4 pb-4">
                        <h1>30+</h1>
                        <h3 className="pt-4">Pieces of content published per month</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-12">
                    <div className="purple-container rounded d-flex">
                      <div className="align-self-end px-4 pb-4">
                        <h1>92%</h1>
                        <h3 className="pt-4">Of early style guide users wished to retain access</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 mt-md-5 pt-3 pt-md-5">
              <div className="col-12">
                <h3>The Challenge</h3>
                <p className="pt-4">
                  Zapier has a number of terms that always need to be right.For example, the word Zap is used to describe a specific type of automated workflow that customers can create using Zapier. Marketers need to be consistent in how they describe this feature, so users are prepared when they move from content about the product to working within it. Support team members need to make sure they're using the same terms users see in the product.
                </p>
                <p>
                Ensuring that branded terms like Zap or the names of partner apps are used consistently—and correctly capitalized—requires a robust writing and editing process. Zapier team members relied on a number of internal resources but found it challenging to maintain a single source of truth.
                </p>
              </div>
            </div>
          </div>
          <div className="QuoteContainer">
            <div className="container px-lg-0">
              <div className="row">
                <div className="col-md-6">
                  <h3>"We had a lot of style guides at Zapier for teams to use for specific purposes. We tried to keep them updated and consistent, but it was a lot to wrangle."</h3>
                  <h6 className="quote-author mt-5 mr-2 mr-md-0 pr-4 pr-md-0">
                    Janine Anderson,
                    <br />
                    Managing Editor,
                    <br />
                    Zapier
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-3 mb-md-5 pb-4 pb-md-5">
            <div className="row">
              <div className="col-12">
                <p>
                  During the editing process, team members often had to take time to fix errors. Partner names were especially important to review as mistakes stood to diminish trust, deteriorate relationships, and create additional editing work for the team if they were not addressed before
                  publishing.
                </p>
                <p>Janine knew there had to be a better way. The style guide capability of Grammarly Business was an important addition that supports her team, as well as the company.</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <h3>The Solution</h3>
              </div>
            </div>
            <div className="row mt-4 mb-3 mb-md-4 pb-3 pb-md-5">
              <div className="col-12">
                <p>
                  Within the administrative panel of their Grammarly Business account, Janine created a company style guide, which consisted of custom rules. These rules ensured that team members received writing suggestions based on branded terms, partner names, and stylistic choices specific to
                  Zapier guidelines.
                </p>
              </div>
              <div className="col-12">
                <p>Zapier had an existing style guide for the blog, a playbook for the support team, and a larger company-wide resource for writing. As her first order of business, Janine moved quickly to consolidate these resources into one place.</p>
              </div>
            </div>
            <div className="row justify-content-center mt-3 mt-md-4">
              <div className="col-md-8">
                <h3>
                  <span className="green-highlight gh-d-mob">"Grammarly Business helped us consolidate all of our existing guides into one place,</span><span className="gh-d-mob"> which included</span>
                  <span className="green-highlight gh-d-ipad">"Grammarly Business helped us consolidate</span> <span className="green-highlight gh-d-ipad"> all of our existingguides into one place,</span><span className="gh-d-ipad"> which included</span>
                  <span className="green-highlight gh-d-laptop">"Grammarly Business helped us consolidate all of our existing</span> <span className="green-highlight gh-d-laptop">guides into one place,</span><span className="gh-d-laptop"> which included suggestions on how to spell and use our branded terms, and partner names."</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="row mb-3 mb-md-5 pb-4 pb-md-5">
            <div className="col-12">
              <img className="w-100" src={require("../../assets/images/case-study/zapier-case-study/OrlandoAllTeamPhoto_web-1.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row mt-3 mt-md-5">
              <div className="col-12">
                <p>
                  Zapier now has a living and dynamic style guide that provides suggestions in real time. Team members no longer need to track down the right terms– these terms appear instantly in the tools they use to write. It’s also easy for Janine to make updates and adjustments to their style
                  guide rules when necessary. This ensures the style guide and suggestions that team members receive are always relevant and never outdated.
                </p>
              </div>
            </div>
            <div className="row justify-content-center my-3 my-md-4 py-md-5">
              <div className="col-md-8">
                <h3>
                  <span className="gh-d-mob">"If a company’s branding changes or we no longer want to use certain terms, we can go</span>
                  <span className="gh-d-ipad">"If a company’s branding changes or we no</span> <span className="gh-d-ipad">longer want to use certain terms, we can go</span>
                  <span className="gh-d-lg-ipad">"If a company’s branding changes or we no longer want to</span> <span className="gh-d-lg-ipad">use certain terms, we can go</span>
                  <span className="gh-d-large-screen">"If a company’s branding changes or we no longer want to use</span> <span className="gh-d-large-screen">certain terms, we can go</span> into Grammarly and fix it right away. <span className="green-highlight gh-d-large-screen">The update is live instantaneously for
                  everyone on our team."</span>
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>Grammarly Business also offered seamless integration with the core tools used by Janine’s team, serving up suggestions in Google Docs, Gmail, and Quip. Janine saw the inherent value and knew that other teams at Zapier would benefit as well.</p>
                <p>For example, it was particularly nice that Grammarly Business worked in Help Scout, Zapier’s customer support solution. Support reps need to have access to product terms and partner names in real time, and Grammarly Business provided these style guide suggestions instantly.</p>
              </div>
            </div>
          </div>

          <div className="green-container my-3 my-md-5 py-3 py-md-5">
            <div className="container py-5">
              <div className="row">
                <div className="col-12">
                  <h3>The Results</h3>
                  <p className="mt-3">
                    Implementing a style guide from Grammarly Business has helped Janine improve consistency in written communication across the Zapier team. Knowing that the team is receiving real-time writing suggestions, she can rest assured that their messaging will help the brand look good and
                    build confidence with its customers and partners.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center my-3 my-md-4 py-md-5">
                <div className="col-md-8">
                  <h3>
                    "It's easy to customize the suggestions our team receives with the words and styles that are important to us. <span className="green-highlight gh-d-mob">The style guide ensures that our message is always on brand and coming across the right way, no matter the channel</span>
                    <span className="green-highlight gh-d-ipad">The style guide ensures</span> <span className="green-highlight gh-d-ipad">that our message is always on brand and</span> <span className="green-highlight gh-d-ipad">coming across the right way, no matter the</span>
                    <span className="green-highlight gh-d-ipad">channel</span>
                    <span className="green-highlight gh-d-lg-ipad">The style guide ensures that our message is always on brand and coming</span> <span className="green-highlight gh-d-lg-ipad">across the right way, no matter the channel</span>
                    <span className="green-highlight gh-d-large-screen">The style guide ensures that our message is always on brand and coming across</span> <span className="green-highlight  gh-d-large-screen">the right way, no matter the channel</span> we use to communicate."
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p>The real-time suggestions from Grammarly Business have also saved time for editors who can rely on it to meticulously check every term.</p>
                  <p>Following the team's first use of the style guide feature, 92% said they wanted to retain access. Janine and her team have continued using the style guide suggestions since. Now, content creators across multiple teams are using Grammarly Business.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container my-3 my-md-5 py-4 py-md-5">
            <div className="row text-center">
              <div className="col-12">
                <h3>Keys to Success</h3>
              </div>
            </div>
            <div className="row mt-3 mt-md-5">
              <div className="col-md">
                <img src={require("../../assets/images/case-study/zapier-case-study/Consolidate_icon.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Consolidate existing materials.</span>
                </div>
                <p className="mt-4">Before Grammarly Business, Zapier had a collection of different style guides across the organization. Janine’s first order of business was to consolidate these different resources into one style guide. Grammarly Business made it easy to do that.</p>
              </div>
              <div className="col-md mt-4 mt-md-0">
                <img src={require("../../assets/images/case-study/zapier-case-study/Empower_icon.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Empower your team in real time.</span>
                </div>
                <p className="mt-4">
                  The dynamic nature of the style guide feature ensured that Zapier team members received suggestions the moment they needed them. Wherever they were writing – Google Docs, Gmail, or Quip – team members could rely on Grammarly for accurate, up-to-date information about branded terms,
                  partner names, and other important terms.
                </p>
              </div>
              <div className="col-md mt-4 mt-md-0">
                <img className="zcs-promote-icon" src={require("../../assets/images/case-study/zapier-case-study/Promote_icon.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Promote your program across teams.</span>
                </div>
                <p className="mt-4">Janine invited many Zapier team members to use Grammarly, not just people on her own team. This ensured that everyone, no matter where they worked in the organization, was consistent with how they used terms.</p>
              </div>
            </div>
          </div>
          <div className="blue-container pb-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      {/* <a href="#"  
                      className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction> */}
                      <CallToAction to="https://www.grammarly.com/contact/sales?source=enterprise" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto">
                        Get in touch
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ZapierCaseStudy;
