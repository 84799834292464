import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";
import { Link } from "react-router-dom";

class BestPracticesCustomerCommunication extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Best Practices for Customer Communication Based on 2020 Trends</h1>
              </div>
            </div>
          </div>
          <div className="mt-4 header-section-content">
            <div className="col-12 px-0">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/best-practices-customer-communication/header-img.png")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/best-practices-customer-communication/header-img.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-12 text-left text-md-center">
                            <span>December 22, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                
                {/* <p className="IntroPara">What This Year’s Communication Trends Tell Us About Customers How to Reach Customers According to 2020 Communication Trends</p>
                <img className="img-fluid w-100 mb-3" src={require("../../assets/images/blog/best-practices-customer-communication/inline-img-1.jpg")} alt="" /> */}

                <p className="IntroPara">The COVID-19 global pandemic completely disrupted environments, operations, and business models across industries, forcing leaders to adopt new strategies at record speed. As organizations quickly adapted to remote work models, the role of digital communication became critical. According to McKinsey, <a href="https://www.mckinsey.com/featured-insights/future-of-work/what-800-executives-envision-for-the-postpandemic-workforce" target="_blank">85 percent</a> of executives accelerated digitization efforts this year. As a result, digital adoption increased at the rate of <a href="https://www.mckinsey.com/business-functions/mckinsey-digital/our-insights/the-covid-19-recovery-will-be-digital-a-plan-for-the-first-90-days" target="_blank">five years in just two months</a> as businesses and consumers alike turned to messaging apps, video conferencing, and other communication tools.</p>

                <p>As business leaders prepare for the coming year, they realize that the shift to digital productivity may have been a reaction to the pandemic, but it is here to stay. As consumer behavior continues to shift, intentional and professional communication is key to building customer relationships and keeping teams connected.</p>

                <p>Grammarly analyzed aggregate data across a base of 30 million daily active users to examine how the events of 2020 affected the way people communicate with one another. Grammarly’s product, which can detect over 50 different tones, discovered <a href="https://www.grammarly.com/blog/how-we-communicated-in-2020/" target="_blank">unique trends across 11 particular tones</a>. Grammarly is turning these trends into actionable insights for how businesses can employ effective communication to maintain and deepen customer relationships. Strengthening existing customer relationships through communication is not only more cost-effective than acquiring new customers, but will help build brand equity during times of uncertainty. Here are some of the ways leaders can move beyond reacting and adapting to thriving in 2021.</p>

                <h2 className="my-4">Focus on delivery and engagement</h2>
                <p><b>Grammarly users care about more than grammar and spelling or clarity of writing during complex times.</b> People used Grammarly’s engagement and delivery features more in 2020, reflected by a 77 percent increase in interacting with engagement suggestions and a 98 percent increase in delivery suggestions—almost double the rate of 2019. For business teams, this trend shows that customers need more than proper spelling and grammar. Customers care about how things are said and want engaging messages that capture and keep their attention. The delivery of communication conveys more information beyond the literal meaning of the words used, which is especially important outside of in-person interactions where we can rely on facial expressions and voice.</p>

                <h2 className="my-4">Practice empathy and personalize communication</h2>
                <p><b>Formal writing decreased in 2020, while informal writing increased.</b> Grammarly users wrote more casually in the fall, as formal writing decreased 92 percent compared to January. This drop in formality reflects how connection and <Link to="/the-value-of-live-agents">a human touch</Link> became important factors for customer-facing teams. <a href="https://www.salesforce.com/resources/research-reports/state-of-the-connected-customer/" target="_blank">Sixty-eight percent</a> of customers expect companies to demonstrate empathy, and that was especially true this year. However, only 34 percent of that same group believe that companies deliver that empathy. There’s an opportunity for customer-facing teams to <Link to="/hacking-cx-ebook">practice empathy and personalize the customer experience</Link> to connect with customers and strengthen brand loyalty. Grammarly Business can deliver highly customized suggestions through a style guide feature. A company-wide style guide can help customer teams avoid both internal jargon that customers won’t understand and overly formal or generic language that feels impersonal.</p>

                <div className="container-fluid my-5 px-0">
                  <div className="purple-container p-3">
                    <div className="row no-gutters">
                      <div className="col-lg-12 text-center text-lg-left">
                        <img className="img-fluid w-25 mb-3" src={require("../../assets/images/blog/best-practices-customer-communication/zapier-logo-reversed.png")} alt="" />
                        <p>Grammarly Business customer Zapier publishes more than 30 pieces of content each month and shares that “the style guide ensures that our message is always on brand and coming across the right way, no matter the channel.”</p>
                      </div>
                    </div>
                  </div>
                </div>

                <h2 className="my-4">Offer reassurance and solutions</h2>
                <p><b>Grammarly users expressed a general loss of confidence over the year.</b> By November, aggregate data showed a 43 percent decrease in the monthly average use of confident tone than in January. Between an economic downturn, uncertainty around the pandemic, not to mention a contentious U.S. election, it comes as no surprise that Grammarly users experienced a drop in confidence when communicating with others. With high unpredictability across personal, work, and academic life, Grammarly offered users suggestions to remove <a href="https://www.grammarly.com/blog/hedging-language/" target="_blank">hedging language</a>. Customer-facing teams that want to reassure customers and sound more confident can lean on Grammarly to check tone when responding to emails, social media comments, and customer resolutions.</p>
                
                <h2 className="my-4">Other notable trends for customer-facing teams</h2>
                <p>2020 was a year marked by influential global events, and the data indicated that writing sentiment was affected by these major events. In the early days of the pandemic, writing became more informative and less optimistic.</p>
                <img className="img-fluid w-100 mb-3" src={require("../../assets/images/blog/best-practices-customer-communication/inline-img-1.jpg")} alt="" />
                
                <p>Grammarly’s product also observed shifts in several emotional tones in writing as the Black Lives Matter movement sparked protests at the start of summer. While data indicated an astounding 1286 percent increase in the anxious tone and a 258 percent increase in the gloomy tone, there was also a 210 percent increase in the curious tone, 241 percent increase in the optimistic tone, and a 628 percent increase in the empathetic tone during this time.</p>

                <p>In the fall, with the U.S. election and a “second wave” of COVID-19 cases looming, Grammarly data correlated with further shifts in tone—capturing a 50 percent increase in direct tone, a 47 percent increase curious tone, and a 66 percent increase in empathetic tone.</p>

                <p>Businesses should consider recent customer sentiment and how it’s been impacted by major events. Teams looking to connect with customers as we head into another uncertain year should focus on customer feedback, conduct research where possible, and seek a greater understanding of their customers’ needs. Companies leveraging Grammarly Business in their customer communication channels are able to increase customer satisfaction by up to 17 percent and resolve issues more quickly, as much as 12 percent reduction in contact per ticket.</p>

                <h2 className="my-4">Learn More</h2>
                <p>Today, <a href="https://www.salesforce.com/resources/research-reports/state-of-the-connected-customer/" target="_blank">two-thirds</a> of customers expect companies to understand their unique needs and challenges, yet only about one-third say that companies generally treat customers as unique individuals. This year showed businesses how important it is to be adaptable and compassionate. Companies who adjusted quickly, offering support in the early days of the pandemic—extended free trials, longer return windows, or free ad credits—demonstrated values that positively influenced customers. There's still hope for companies taking longer to adjust and respond to customer needs during a volatile year. Grammarly believes thoughtful, clear communication that strikes the right tone can support companies in achieving business outcomes that depend on customer trust and brand loyalty. Continue reading on our blog to learn more about <a href="https://www.grammarly.com/blog/how-we-communicated-in-2020/" target="_blank">Grammarly’s end of year insights</a>. If you’re interested in learning more about what businesses can do to take action on these insights, <Link to="/hacking-cx-ebook">download our latest ebook on Hacking Customer Experience and Customer Communication.</Link></p>
                

              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default BestPracticesCustomerCommunication;
