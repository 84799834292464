import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class FourCommunicationTacticsOpsPlaybook extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>The 4 Communication Tactics Every Ops Leader Needs In Their Playbook</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/four-communication-tactics-ops-playbook/634_Desktop_ Four_Ways_to_Accelerate_Your_Customer_Operations_Through_Communication_1X.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>August 5, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">High-performing customer operations all have one thing in common: they put the customer front and center. <a href="https://www.pwc.com/us/en/advisory-services/publications/consumer-intelligence-series/pwc-consumer-intelligence-series-customer-experience.pdf#page=8" target="_blank">Sixty-five percent</a> of consumers find a positive experience with a brand to be more influential than great advertising. And there’s a reason for that.</p>

                <p>Focusing on the customer enables companies to dial in on each customer’s needs from the first interaction and throughout their lifecycle journey. The key is understanding the vital role that communication plays in all touchpoints of customer operations. More importantly, ops leaders must build the nuances of communication into their playbook to deliver positive, differentiated experiences for their customers.</p>

                <p>Communication is the lifeblood of the customer experience, and getting it right matters. Seventy percent of customers would <a href="https://www.acquia.com/about-us/newsroom/press-releases/personalize-or-else-customers-demand-convenience-recognition" target="_blank">stop doing business</a> with a brand they loved after one bad experience. Yes, seventy percent. A customer’s communication needs also vary depending on where they are in the customer life cycle. What doesn’t change is the architecture of excellent communication. For teams to display customer-centric communication, they must understand the different dimensions of communication.</p>

                <h2 className="my-4">Three dimensions of communication</h2>

                <p>
                  Achieving great communication requires <a href="https://www.psychologytoday.com/us/blog/the-middle-ground/201509/the-3-dimensions-communication" target="_blank">three separate yet interconnected dimensions</a>. When customer-facing teams adopt these dimensions successfully, they enable a positive and productive dialogue with their customers. Here’s a breakdown of the dimensions:
                </p>

                <ol>
                  <li className="my-2">
                    <p>
                      <b>Clarity and completeness of the message</b> <br />
                      People communicate from their frame of reference. This framework can create an unconscious shorthand, leaving out key details that may lead to misunderstandings and mistrust. Ensure that your teams communicate the full context of their message to get their point across more effectively and resolve issues more quickly.
                    </p>
                  </li>
                  <li className="my-2">
                    <p>
                      <b>Attitude</b> <br />
                      Communication—both verbal and written—carries a tone that affects how we perceive the interaction. In written communication, the tone can be friendly, professional, neutral, negative; the list goes on. Tone can make or break the customer relationship. Providing clear guidance on brand-appropriate tones can help create interactions that are friendly, optimistic, and memorable.
                    </p>
                  </li>
                  <li className="my-2">
                    <p>
                      <b>Intentionality</b> <br />
                      If a customer is calling with an issue, there may be some frustration on their end. This is where mindfulness and intentionality come into play. Instead of reacting to the frustration, enable your team to communicate their intention of providing a solution to the problem. When customers understand that you’re there to solve their problem, you can deescalate the situation and turn it into a win.
                    </p>
                  </li>
                </ol>

                <p>
                Ops leaders can set the standard by adopting customer-centric behaviors within their ops playbook. If you’re looking for meaningful ways to implement the three dimensions of communication into your customer operations, you’re in luck. Here are four tangible ways to step up your team’s communication with customers.
                </p>

                <h2 className="my-4">Keys ways to differentiate your customer operations</h2>

                <ol>
                  <li className="my-2">
                    <p>
                      <b>Practice active listening</b> <br />
                      Listening works in two ways—listening to respond and listening to understand. <a href="https://www.mindtools.com/CommSkll/ActiveListening.htm" target="_blank">Active listening</a> is the act of listening to comprehend another’s message. In this case, the customer in a conversation. Coach your team to focus on the whole message instead of the granular details. By understanding a customer’s point of view, you can better assist in finding a solution while ensuring they feel heard.
                    </p>
                  </li>
                  <li className="my-2">
                    <p>
                      <b>Mirror the communication</b> <br />
                      Meet customers where they’re at in the conversation. <a href="https://www.wsj.com/articles/use-mirroring-to-connect-with-others-1474394329" target="_blank">Mirroring</a>, also known as reflective listening, is when the receiver reflects the sender’s message. Encourage your team to adopt the language the customer is using to understand and convey the message in a way they’ll be sure to appreciate.
                    </p>
                  </li>
                  <li className="my-2">
                    <p>
                      <b>Adopt a curious mindset</b> <br />
                      Providing excellent customer service is all about understanding the customer. Coupled with active listening and mirroring, a curious mindset helps <a href="https://hbr.org/2018/09/curiosity" target="_blank">reduce group conflict and minimize decision-making</a> errors. Whether in sales, customer support, onboarding, and so on, encourage your team members to be optimistically inquisitive.
                    </p>
                  </li>
                  <li className="my-2">
                    <p>
                      <b>Leverage technology to fill gaps</b> <br />
                      Written communication is a skill that needs nurturing. Thanks to machine learning and AI, top-performing operations teams are now <a href="https://grammarly-enterprise.com/" target="_blank">relying on tools</a> to ensure complete and clear communication and monitor tone. Teams leveraging Grammarly Business can reduce mistakes up to 74 percent while maintaining focus on giving customers a five-star experience.
                    </p>
                  </li>
                </ol>

                <h2 className="my-4">Optimize customer operations with strategic communication</h2>

                <p>Providing an <a href="https://grammarly-enterprise.com/blog/3-cx-trends-to-achieve-business-goals" target="_blank">excellent customer experience</a> requires companies to be on top of their customer operations strategy. Today’s customers are savvy, and competition is fierce. Take a look at your communication style and how it evolves throughout the customer journey. Leveraging the three dimensions of communication is a continuous effort that rewards successful companies with loyal customers. Add these four tactics to your strategy to boost your team’s communication process and maximize the customer experience.</p>

              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default FourCommunicationTacticsOpsPlaybook;
