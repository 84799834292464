import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import ServiceForm from "../../components/Form";

class CPanelCaseStudy extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="zapier-case-study-page blind-case-study-page zourna-case-study-page cpanel-case-study-page">
        <div className="container-fluid px-0">
          
          <div className="headerContainer">
            <div className="container px-lg-0">
              <div className="row">
                <div className="col-lg-9  order-2 order-lg-1">
                  <h6 className="pre-header">CASE STUDY</h6>
                  <h1 className="pr-md-2">How a leading web hosting platform leveraged tech to upskill their customer support team</h1>
                  <p className="py-md-3 pr-md-5 mw-xl-63-custom">Using Grammarly Business, a web hosting platform up-leveled communication skills across the customer support team and helped reduce employee burnout.</p>
                </div>
                <div className="col-lg-3 p-lg-0 order-1 order-lg-2">
                  <div className="h-100 d-flex justify-content-left justify-cintent-lg-center align-items-center ">
                    <img className="img-fluid mb-4 mb-lg-0 banner-hero-image" src={require("../../assets/images/case-study/cpanel-case-study/hero-image.png")} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="container mt-0 mt-lg-4">
            <div className="row mt-3 pt-3 ">
              <div className="col-12">
                <h3 class="mt-4">The Challenge</h3>
                <p className="pt-4">
                  Struggling with confusing support interactions, a  popular web hosting platform sought out to improve the experience for their customers. The company noticed that while their customer support team had extensive knowledge of the product, that knowledge didn’t always come across clearly in written responses. Seeing the connection between grammatical issues in support tickets and low customer satisfaction (CSAT) scores. leadership began to heavily rely on team members with solid writing skills. Eventually, this led to high-performers being overburdening and other team members missing out on opportunities to improve.  To help balance the workload and create a positive and productive experience across their team, the web hosting platform looked to Grammarly for support. 
                </p>
                
              </div>
            </div>
          </div>

          <div className="container mt-3 mt-md-5 mb-3 mb-md-5 pb-4">
            <div className="row mt-3">
              <div className="col-12">
                <h3>The Solution</h3>
              </div>
            </div>
            <div className="row mt-0">
              <div className="col-12">
                <p className="pt-4">
                  The company brought in Grammarly Business to help up-level the writing process across the entire customer support team. Each member created an account that integrated seamlessly with their workstations, enabling them to accept suggestions for grammar, clarity, and more in real-time. For example, if a customer support team member incorrectly used ‘there’ instead of ‘their’ Grammarly Business would underline the word, suggesting the correct usage. This real-time communication coaching empowered every team member to produce higher quality writing which led to clear and consistent interactions with customers. Even better, the company could be confident in the fact that at any given time, their customers would receive excellent customer service with minimal oversight or reliance on high performing team members.
                </p>
                
              </div>
              
            </div>
            

          </div>
          
          <div className="green-container my-3 my-md-5 py-3 py-md-5">
            <div className="container py-4">
              <div className="row pr-xs-15p-custom">
                <div className="col-12">
                  <h3>The Results</h3>

                  <div className="tiles-section tiles-section-type-2-custom">
                    {/* addition of box   */}
                    <div className="row mt-5 tilesContainer">
                      
                      <div className="col-md-4 mb-5 mb-md-0 pr-xl-0 mw-md-270p-custom">
                        <div className="row h-100">
                          <div className="col-12">
                            
                            <div className="green-container container-type-2-custom rounded d-flex h-100">
                              <div className="txt-section">
                                <h1>30%</h1>
                                <div className="txt-copy ">
                                  <h3 className="">Increase in overall customer satisfaction score</h3>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 mb-5 mb-md-0 pr-xl-0 mw-md-270p-custom">
                        <div className="row h-100">
                          <div className="col-12">
                            
                            <div className="green-container container-type-2-custom rounded d-flex h-100">
                              <div className="txt-section">
                                <h1>62%</h1>
                                <div className="txt-copy ">
                                  <h3 className="">Organizational adoption of Grammarly Business</h3>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 mb-5 mb-md-0 pr-xl-0 mw-md-270p-custom">
                        <div className="row h-100">
                          <div className="col-12">
                            
                            <div className="green-container container-type-2-custom rounded d-flex h-100">
                              <div className="txt-section">
                                <h1>50M+</h1>
                                <div className="txt-copy ">
                                  <h3 className="">Words checked by Grammarly Business</h3>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      
                    </div>
                    {/* addition of box   */}
                  </div>

                  <p className="pt-4 mt-4">
                    With support from Grammarly Business, the web hosting platform has seen major improvements in both the employee and customer experience. From an internal perspective, the company had found a sustainable solution to up-level their customer support teams’ writing skills at scale, which helped alleviate burnout among high-performers and mitigate disengagement among other team members. With a high adoption rate of Grammarly Business, the company has noticed that support responses are more consistent, leaving customers with a lasting good impression. In fact, customer satisfaction scores are now higher than ever, with the company seeing a 30% increase in CSAT scores since introducing Grammarly Business.  
                  </p>
                </div>
              </div>
            
            </div>
          </div>
          <div className="container my-3 my-md-5 py-4  key-to-success-section">
            <div className="row text-center">
              <div className="col-12">
                <h3>Keys to Success</h3>
              </div>
            </div>
            <div className="row mt-3 mt-md-5">
              <div className="col-md-4">
                <img src={require("../../assets/images/case-study/cpanel-case-study/icon-1.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Check the pulse of your team.</span>
                </div>
                <p className="mt-4 pr-xl-3 mr-xl-4">The company places a high value on technical proficiency but realized they were placing added responsibility on team members with excellent writing skills. Instead of ignoring the opportunity to adjust, the company implemented Grammarly Business to more evenly spread workflow. In order for that to happen, they first had to gauge the pulse of their team.</p>
              </div>
              <div className="col-md-4 mt-4 mt-md-0 pl-xl-0">
                <img src={require("../../assets/images/case-study/cpanel-case-study/icon-2.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Integrate vs adding on.</span>
                </div>
                <p className="mt-4 pr-xl-5 ">
                  Empower your employees with tools that fit into existing processes rather than creating new ones. Grammarly Business is a digital writing assistant that works in the background, seamlessly improving users’ writing abilities. Employees not only received immediate assistance, but they easily integrated the use of Grammarly Business in their daily activities.
                </p>
              </div>
              <div className="col-md-4 mt-4 mt-md-0">
                <img className="" src={require("../../assets/images/case-study/cpanel-case-study/icon-3.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Engaged employees create amazing customer experiences.</span>
                </div>
                <p className="mt-4 pr-xl-3 mr-xl-4">Employee satisfaction and customer experience go hand in hand. By providing Grammarly Business to their customer support team, employees received the support and training they needed to focus on the customer. This created a winning formula for the web hosting platform.</p>
              </div>
            </div>
          </div>
          <div className="blue-container pb-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      {/* <a href="#"  
                      className=""
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a> */}
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction> */}
                      <CallToAction to="https://www.grammarly.com/contact/sales?source=enterprise" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto">
                        Get in touch
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CPanelCaseStudy;
