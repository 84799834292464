import React from "react"
import Layout from "../../components/Layout"
import CallToAction from '../../components/Button'

const Sales = () => (
  <Layout className="sales-landing-page">
    <section className="banner-section">
      <div className="banner">
        <div className="banner-items">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="card-title heading-type-1">Grammarly for Sales Teams</h1>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="card-text p-type-1">Bring Your A-Game to Every Email</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CallToAction
                  to="https://grammarly.com/contact/sales?source=enterprise"
                  eType="See-Demo"
                  eName="sales-team"
                  eLabel="enterprise-sales-team-see-demo"
                  className="sal1seedem"
                >
                  Get in touch
                </CallToAction>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-grey testimonial-1">
      <div className="container">
        <div className="d-flex flex-row flex-sm-column flex-column flex-md-row flex-xl-row">
          <div className="p-2 fb-img-block">
            <img src={require('../../assets/images/Module2_UI_sales.gif')}  alt="" />
          </div>
          <div className="p-2 align-self-stretch mt-4 text-center text-md-left">
            <blockquote className="blockquote">
              <img className="inverted-comma" src={require('../../assets/images/double-inverted-comma.png')} alt="" />
                <p className="blockquote-body">We have exceptional sales and technical staff out in the field
                  who might not excel at written communication. Grammarly makes them sound professional.”
                </p>
                <footer className="blockquote-footer-1">
                  <cite>
                    <span className="author mb-2">Lewis Wilkinson</span>
                    <span className="position">Senior Project Manager, InterDev</span>
                  </cite>
                </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>

    <div className="sticky-container-holder">
      <section className="help-your-team">
        <div className="container">
          <div className="row text-center hyt-img-header">
            <div className="col-md-12 hyt-img-section">
              <img src={require('../../assets/images/Sales_PopOut_Icon.png')}   alt="" />
                <h2 className="heading-type-4">Prospects receive more than 90
                  emails a day. How do you stand out?</h2>
            </div>
          </div>

          <div className="row">
            <div className="w-100 hr-line" />
          </div>

          <div className="row text-left text-md-center hyt-sub-header">
            <div className="col-md help-team mt-3 mt-md-0 ">
              <h2 className="heading-type-2">Grammarly Business can help your team:</h2>
            </div>
          </div>

          <div className="row help-team">
            <div className="col-md">
              <h3>Cut through the noise</h3>
              <p>Brightening messages with engaging language captures attention more quickly.</p>
            </div>

            <div className="col-md">
              <h3>Open doors</h3>
              <p>A professional tone builds credibility with potential customers. </p>
            </div>

            <div className="col-md">
              <h3>Reduce friction</h3>
              <p>Clear and consistent communication is more likely to be convincing and to turn an
                opportunity into a deal.</p>
            </div>

            <div className="col-md">
              <h3>Scale across platforms</h3>
              <p>Grammarly Business works with Gmail, Salesforce, Outreach.io, and hundreds of other major
                sales platforms wherever you work-on desktop, laptop, or mobile.</p>
            </div>
          </div>

          <div className="row text-center d-none d-md-block">
            <div className="col-sm">
              <CallToAction
                to="https://grammarly.com/contact/sales?source=enterprise"
                eType="Lets-talk"
                eName="sales-team"
                eLabel="enterprise-sales-team-lets-talk"
                className="sal2letstalk"
              >
                Let's Talk
              </CallToAction>
            </div>
          </div>

        </div>
      </section>

      <section className="section-grey testimonial-2">
        <div className="container continer-show-dots">
          <div className="row">
            <div className="col-md">
              <blockquote className="blockquote text-center">
                <img className="inverted-comma" src={require('../../assets/images/double-inverted-comma.png')} alt="" />
                  <p className="blockquote-body">With Grammarly, our salespeople now always sound credible and
                    professional, and are perceived as strategic partners to our customers.”</p>
                  <footer className="blockquote-footer-1">
                    <cite>
                      <span className="author mb-2">Lewis Wilkinson</span>
                      <span className="position">Senior Project Manager, InterDev</span>
                    </cite>
                  </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section className="why-service-team">
        <div className="container container-circle">
          <div className="row">
            <div className="col text-left text-md-center">
              <h2 className="heading-type-3">Three Keys to Sales Success</h2>
            </div>
          </div>
          <div className="row card-row">
            <div className="col-md">
              <img src={require('../../assets/images/Sales_1_Professional.png')}  alt="" className="img-fluid"/>
              <h3>Credible</h3>
              <p>Grammarly makes sure that every message is polished and professional.</p>
            </div>
            <div className="col-md">
              <img src={require('../../assets/images/Sales_2_Clear.png')}  alt=""
                   className="img-fluid"/>
              <h3>Clear</h3>
              <p>Grammarly simplifies sentences and encourages salespeople to write in the active voice.
              </p>
            </div>
            <div className="col-md">
              <img src={require('../../assets/images/Sales_3_Engaging.png')}  alt=""
                   className="img-fluid"/>
              <h3>Engaging</h3>
              <p>Grammarly brightens communications by suggesting synonyms that capture a reader&#8217;s
                attention.</p>
            </div>
          </div>
          <div className="row d-none d-md-block">
            <div className="col text-center">
              <CallToAction
                to="https://grammarly.com/contact/sales?source=enterprise"
                eType="Lets-talk"
                eName="sales-team"
                eLabel="enterprise-sales-team-lets-talk"
                className="sal3letstalk"
              >
                Let's Talk
              </CallToAction>
            </div>
          </div>
        </div>
      </section>

      <div className="col-12 px-0 sticky-bottom d-md-none sb-custom">
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <CallToAction
                  to="https://grammarly.com/contact/sales?source=enterprise"
                  className="d-md-none sal4letstalk"
                  eType="Lets-talk"
                  eName="sales-team"
                  eLabel="enterprise-sales-team-lets-talk"
                >
                  Let's Talk
                </CallToAction>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
);

export default Sales;
