import React, {useState} from "react"
import Layout from "../../components/Layout"
import Sidebar from "../../components/Sidebar";
import BlogResourceContent from "../../components/blog-resources/BlogResourceContent";
import MobileStickySortOption from "../../components/blog-resources/MobileStickySortOption";
import Pagination from "../../components/Pagination";
import blogListData from "../../data/blog-lists";

const BlogResources = () => {
  
  let blogLists = blogListData;
  const increaseNumItems = 2;
  const [numItems, setNumItems] = useState(4);
  
  //index: current page number: starts from 0 cause array starts from zero
  const [ index, setIndex] = useState(0);
  
  /*
    startIndex: start of list ot show: if numItems=8: 
    if current pageNum:0, then 0*8=0, shows 0-7 content of array
    if current pageNum:1, then 1*8=8, shows 8-15 content of array
  */
  const [startIndex, setStartIndex] = useState(0);
  
  // sortBy options: new-old, old-new
  const [ sortBy, setSortBy ] = useState("new-old");
  const [ teamType, setTeamType ] = useState([]);
  const [ contentType, setContentType ] = useState([]);
  const [ showSidebarClass, setShowSidebarClass ] = useState(" d-none d-md-block ");

  const resetPagination = () => {
    setStartIndex(0);
    setIndex(0);
  }

  const changePaginate = (itemIndex) => {  
    setStartIndex(itemIndex*numItems);
    setIndex(itemIndex);
  }

  const changeSortBy = (changeSortData) => {
    setSortBy(changeSortData);
    resetPagination();
  }

  const changeTeamType = (teamData, isSelected) => {
    
    let teamTypeTemp = teamType.filter( x => x!=teamData );
    
    if(isSelected){
      setTeamType([...teamTypeTemp, teamData]);
    }
    else{
      setTeamType(teamTypeTemp); 
    }
    resetPagination();
  }

  const changeContentType = (contentData, isSelected) => {
    
    let contentTypeTemp = contentType.filter( x => x!=contentData );
    
    if(isSelected){
      setContentType([...contentTypeTemp, contentData]);
    }
    else{
      setContentType(contentTypeTemp); 
    }
    resetPagination();
  }

  const resetCheckBoxOption = () => {
    setContentType([]);   
    setTeamType([]);
  }

  const processBlogData = () => {
    
    let blogListsTemp = blogListData;

    //show data according to team and content type
    if( teamType.length > 0 || contentType.length > 0 ){
      blogListsTemp = blogListsTemp.filter( item => {
        let teamTypeInclude = item.team_type.some(r=> teamType.includes(r));
        let contentTypeInclude = item.content_type.some(r=> contentType.includes(r))
        
        if(teamTypeInclude || contentTypeInclude){
          return item;
        }
      })
    }
    
    //sorting according to thepublish date
    if( sortBy == "old-new" ){
      blogListsTemp = blogListsTemp.slice().reverse();
    }
    
    blogLists = blogListsTemp;
  }
  processBlogData();
  let paginateNum = [ ...Array(Math.ceil(blogLists.length/numItems)).keys()];

  return(
  <Layout className="blog-resources-container">
    
    <section className="banner-section">
      <div className="banner">
        <div className="banner-items">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="card-title heading-type-1">Resources from Grammarly Business</h1>
              </div>
            </div>
            <div className="row">
              <div className="col">
               
                <p className="card-text p-type-1">Empower Your Team To Do<br className="d-none d-md-block" /> Their Best Work With Insights, Tips,<br className="d-none d-md-block" /> Success Stories, and More.</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>

    {/* <!-- main component --> */}
    <section className="main-content">
      <div className="container">
        
        <div className="row">
          
          {/* <!-- sidebar section --> */}
          <div className={"col-sm-4 sidebar-section "+ showSidebarClass} >
            <Sidebar 
              changeContentType={ changeContentType }
              changeTeamType={ changeTeamType }
              resetCheckBoxOption = { resetCheckBoxOption }
              setShowSidebarClass = { setShowSidebarClass }
            />  
          </div>
          
          {/* <!-- main content section--> */}
          <div className="col-sm-8" id="blog-resource-content-section">
            <BlogResourceContent 
              numItems={numItems}
              blogLists={blogLists}
              startIndex={startIndex}
              changeSortBy={changeSortBy}
              />
            
            {/* pagination section */}
            <div className="pagination-container d-none d-md-block">
              <Pagination 
                paginateNum={paginateNum}
                changePaginate={changePaginate}
                index={index}
              />
            </div>
            
            {/* Load more section */}
            {( numItems < blogLists.length )?
            <div className="w-100 d-flex justify-content-center lm-container d-block d-md-none">
              <a href="#" 
              className="btn btn-primary btn-talk btn-cta mx-auto"
              onClick={ (e) => { e.preventDefault(); setNumItems(numItems+increaseNumItems); } }>Load more</a>
            </div>:""}

          </div>

        </div>
      </div>
      <div className="d-md-none">
        <MobileStickySortOption 
          setShowSidebarClass = { setShowSidebarClass }
          changeSortBy = { changeSortBy }
        />
      </div>
      
    </section>

  </Layout>
  );

}

export default BlogResources;
