import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class CustomersDontNeedAnotherWay extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Your Customers Don’t Want Another Way to Get in Touch—Here’s Why</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/customers-dont-need-another-way/desktop-banner.png")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/customers-dont-need-another-way/mobile-banner.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>August 13, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">For brands, the stakes have never been higher when it comes to customer experience. As a leader, you spend a significant amount of time thinking about how to build customer loyalty, engage with customers authentically, and craft a unique and identifiable brand. Thanks to your concerted efforts, your brand stands tall—stronger than ever. Like many businesses, you may be setting your sights on expanding coverage, to make it possible for customers to engage in more ways. </p>

                <p>But customers don’t actually want more ways to communicate with brands—they want better communication. Customers are looking for interactions that provide solutions, support, and inclusion that makes way for a positive experience with your company’s product or service. And if brands miss the mark, they can derail the enormous efforts they poured into building up brand loyalty.</p>

                <p>It’s time to approach customer-centric communication with a quality over quantity mindset. Here are three ways ops leaders can facilitate better customer communication within their existing channels and workflows.</p>

                <h2 className="my-4">Drive personalization</h2>

                <p>
                In 2020, customers are smart, research-driven, and have high expectations for companies to deliver white-glove customer experiences. So when customers reach out with questions or seeking help, brands must be equipped to tailor the interaction to best meet their needs. When personalization leads the customer interaction, it evolves into customer loyalty, positive association with the brand, and increased revenue, making it one of <a href="https://grammarly-enterprise.com/blog/3-cx-trends-to-achieve-business-goals" target="_blank">the top CX trends</a> of 2020. Forty-four percent of customers say they are more likely to become <a href="http://grow.segment.com/Segment-2017-Personalization-Report.pdf" target="_blank">repeat customers</a> after a personalized experience with a brand.
                </p>

                <h2 className="my-4">Emphasize empathy and EQ</h2>
                <p>
                Customer experience is a key performance driver, yet <a href="https://f.hubspotusercontent00.net/hubfs/53/2020%20State%20of%20Service%20Report.pdf?submissionGuid=cd60ad27-c52b-46cc-aacd-4fdbf14e4867" target="_blank">31% of companies see it as an expense</a>. Empowering teams to lean into more empathetic communication ensures that the customer experience with your brand stands out. With capabilities to identify over 40 tones, an AI-powered tool like Grammarly Business can help. Team members can see how customer-facing messages will come across before hitting send, delivering brand-appropriate tone and language with speed and efficiency. Quality beats quantity every time, especially in the customer experience.
                </p>

                <h2 className="my-4">Focus on real-time alignment</h2>
                <p>As a leader, you want your brand to become synonymous with these high communication standards and work hard to motivate your team around them. But those standards lose their impact if they’re not reinforced consistently. Don’t let your hard work go to waste. Deliver a standardized experience by implementing an AI-supported <a href="https://grammarly-enterprise.com/styleguide-custom-rules.pdf" target="_blank">style guide</a>. Your team will reap the benefits of a streamlined workflow while ensuring that every customer receives the brand treatment they know and love.</p>
                <p>When it comes to communication, less is more, and quality is everything. Customers interact with companies because they’re most likely looking for solutions or assistance with product offerings. Clear-cut communication not only demonstrates knowledge but gets to the heart of the customer’s needs, and quickly. By investing in a brand-centric communication initiative, you’re championing the experience without oversaturating the customer. Happy customer, happy brand.</p>
                
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CustomersDontNeedAnotherWay;
