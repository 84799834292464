import React, { useState } from 'react';
import $ from "jquery";

const  Sidebar = (props) => {

    const changeTeamType = props.changeTeamType;
    const changeContentType = props.changeContentType;
    const resetCheckBoxOption = props.resetCheckBoxOption;
    const setShowSidebarClass = props.setShowSidebarClass;

    const [ isHRChecked, setHRChecked ] = useState(false);
    const [ isMarketingChecked, setMarketingChecked ] = useState(false);
    const [ isSalesChecked, setSalesChecked ] = useState(false);
    const [ isCustomerExperienceChecked, setCustomerExperienceChecked ] = useState(false);
    const [ isMiscChecked, setMiscChecked ] = useState(false);

    const [ isBlogChecked, setBlogChecked ] = useState(false);
    const [ isCaseChecked, setCaseChecked ] = useState(false);
    const [ isPdfChecked, setPdfChecked ] = useState(false);
    const [ isSlideShareChecked, setSlideShareChecked ] = useState(false);
    const [ isEBookChecked, setEBookChecked ] = useState(false);
    const [ isVideoChecked, setVideoChecked ] = useState(false);

    const manageCheckboxOption = (optionType) =>{

        //team type
        if( optionType === "hr" ){
            changeTeamType(optionType, !isHRChecked);
            setHRChecked(!isHRChecked);   
        }

        if( optionType === "marketing" ){
            changeTeamType(optionType, !isMarketingChecked);
            setMarketingChecked(!isMarketingChecked);   
        }

        if( optionType === "sales" ){
            changeTeamType(optionType, !isSalesChecked);
            setSalesChecked(!isSalesChecked);   
        }

        if( optionType === "customer-experience" ){
            changeTeamType(optionType, !isCustomerExperienceChecked);
            setCustomerExperienceChecked(!isCustomerExperienceChecked);   
        }

        if( optionType === "miscellaneous" ){
            changeTeamType(optionType, !isMiscChecked);
            setMiscChecked(!isMiscChecked);   
        }

        //content type
        if( optionType === "blog" ){
            changeContentType(optionType, !isBlogChecked);
            setBlogChecked(!isBlogChecked);   
        }  

        if( optionType === "case-studies" ){
            changeContentType(optionType, !isCaseChecked);
            setCaseChecked(!isCaseChecked);   
        }   

        if( optionType === "pdf" ){
            changeContentType(optionType, !isPdfChecked);
            setPdfChecked(!isPdfChecked);   
        }

        if( optionType === "slide-share" ){
            changeContentType(optionType, !isSlideShareChecked);
            setSlideShareChecked(!isSlideShareChecked);  
        }

        if( optionType === "ebook" ){
            changeContentType(optionType, !isEBookChecked);
            setEBookChecked(!isEBookChecked);  
        }

        if( optionType === "video" ){
            changeContentType(optionType, !isVideoChecked);
            setVideoChecked(!isVideoChecked);  
        }
    }

    const resetCheckBoxOptionData = () => {
        
        setHRChecked(false);   
        setMarketingChecked(false);   
        setSalesChecked(false);   
        setCustomerExperienceChecked(false);   
        setMiscChecked(false);   
        setCaseChecked(false);   
        setPdfChecked(false);   
        setSlideShareChecked(false);  
        setEBookChecked(false);  
        setVideoChecked(false);  
        setBlogChecked(false);   

        resetCheckBoxOption();
    }

    const processSeeResults = (e) => {
        e.preventDefault();
        setShowSidebarClass(" d-none d-md-block");
        $('html, body').animate({
            scrollTop: $("#blog-resource-content-section").offset().top - 20
        }, 500);
    }

    return (  
    <div className="sidebar-container option-sidebar-container green-blue-circle">
              
        {/* <!-- siderbar title --> */}
        <div className="row filter-title-section align-items-center">
          <div className="col-6">
            <h3 className="sidebar-title m-0">Filter</h3>
          </div>
          <div className="col-6 text-right">
            <a href="/#" className="txt-green txt-reset d-none d-md-block" 
            onClick={ (e) => { e.preventDefault(); resetCheckBoxOptionData(); } }
            >Reset</a>
            <a href="/#" className="d-md-none" 
            onClick={(e) => { e.preventDefault(); setShowSidebarClass(" d-none d-md-block")} }
            ><img className="green-cross-icon" src="/assets/images/green-cross-icon.png" alt=""/></a>
          </div>
        </div>
        
        {/* <!-- filter section 1 --> */}
        <div className="row filter-section filter-section-1">
          <div className="col-sm-12">
            <h4 className="fs-title"  >Select team</h4>

            <div className="form-check p-0">
                <label className="form-check-label input-checkbox-custom" htmlFor="hr-input">
                    <input name="hr-input-field" type="checkbox" className="form-check-input" id="hr-input"
                    onChange={ () => { manageCheckboxOption("hr") } }
                    checked={isHRChecked} /> <span>HR</span></label>
            </div>

            <div className="form-check p-0">
                <label className="form-check-label input-checkbox-custom" htmlFor="marketing-input">
                    <input type="checkbox" className="form-check-input" id="marketing-input"
                        onChange={ () => { manageCheckboxOption("marketing") } }
                        checked={isMarketingChecked} /> <span>Marketing</span></label>
            </div>

            <div className="form-check p-0">
                <label className="form-check-label input-checkbox-custom" htmlFor="miscellaneous-input">
                    <input type="checkbox" className="form-check-input" id="miscellaneous-input"
                        onChange={ () => { manageCheckboxOption("miscellaneous") } }
                        checked={isMiscChecked} /> <span>Miscellaneous</span></label>
            </div>

            <div className="form-check p-0"> 
                <label className="form-check-label input-checkbox-custom" htmlFor="sales-input">
                    <input type="checkbox" className="form-check-input" id="sales-input" 
                        onChange={ () => { manageCheckboxOption("sales") } }
                        checked={isSalesChecked} /> <span>Sales</span></label>
            </div>

            <div className="form-check p-0">
                <label className="form-check-label input-checkbox-custom" htmlFor="customer-experience-input">
                    <input type="checkbox" className="form-check-input " id="customer-experience-input"
                        onChange={ () => { manageCheckboxOption("customer-experience") } }
                        checked={isCustomerExperienceChecked} /> <span>Customer Experience</span></label>
            </div>

          </div>
        </div>

        {/* <!-- filter section 2 --> */}
        <div className="row filter-section filter-section-2">
          <div className="col-sm-12">
            <h4 className="fs-title">Select content</h4>

            <div className="form-check p-0">
                <label className="form-check-label input-checkbox-custom" htmlFor="blog-input">
                    <input type="checkbox" className="form-check-input" id="blog-input" 
                    onChange={ () => { manageCheckboxOption("blog") } }
                    checked={isBlogChecked} /> <span>Blog</span></label>
            </div>

            <div className="form-check p-0">
                <label className="form-check-label input-checkbox-custom" htmlFor="case-input">
                    <input type="checkbox" className="form-check-input" id="case-input" 
                    onChange={ () => { manageCheckboxOption("case-studies") } }
                    checked={isCaseChecked} /> <span>Case studies</span></label>
            </div>

            <div className="form-check p-0">
                <label className="form-check-label input-checkbox-custom" htmlFor="ebook-input">
                    <input type="checkbox" className="form-check-input" id="ebook-input" 
                    onChange={ () => { manageCheckboxOption("ebook") } }
                    checked={isEBookChecked} /> <span>eBook</span></label>
            </div>

            <div className="form-check p-0">
                <label className="form-check-label input-checkbox-custom" htmlFor="pdf-input">
                    <input type="checkbox" className="form-check-input" id="pdf-input" 
                    onChange={ () => { manageCheckboxOption("pdf") } }
                    checked={isPdfChecked} /> <span>PDFs</span></label>
            </div>

            {/* <div className="form-check p-0">
                <label className="form-check-label input-checkbox-custom" htmlFor="slideshare-input">
                    <input type="checkbox" className="form-check-input" id="slideshare-input" 
                    onChange={ () => { manageCheckboxOption("slide-share") } }
                    checked={isSlideShareChecked} /> <span>SlideShare</span></label>
            </div> */}

            

            <div className="form-check p-0">
                <label className="form-check-label input-checkbox-custom" htmlFor="video-input">
                    <input type="checkbox" className="form-check-input" id="video-input" onChange={ () => { manageCheckboxOption("video") } }
                    checked={isVideoChecked} /> <span>Video</span></label>
            </div>

          </div>
        </div>

        <div className="row mob-reset-control-section align-items-center d-md-none">
            <div className="col-4">
                <a href="/#" className="txt-green txt-reset" 
                    onClick={ (e) => { e.preventDefault(); resetCheckBoxOptionData(); } }
                    >Reset</a>    
            </div>
            <div className="col-8 d-flex justify-content-end">
                <a  href="/#"
                    className="btn btn-primary btn-cta"
                    onClick={(e) => processSeeResults(e) } 
                    >See results</a>
            </div>
        </div>

    </div>
    );
}
 
export default Sidebar;