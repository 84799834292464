import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class ReduceMiscommunicationCostFeatures extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Here's How to Avoid Miscommunication with Customers</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/reduce-miscommunication-cost-feature/GB-Blog-banner---Reduce-the-Cost---Desktop-1X.jpg")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>March 13, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">
                Can you recall the last time you had a phenomenal customer experience? What about a bad one? Unfortunately, the less-than-satisfactory experiences tend to stand out far too well. 
                </p>
                
                <p>
                Businesses today are at the whim of the consumer. Consumers expect brands to cater to them, from tailored messaging to on-demand everything. And it’s not their fault—this behavior has been shaped by technology, access, and competition, making the customer experience more critical than ever before.
                </p>
                
                <p>
                  More than <a href="https://www.acquia.com/resources/ebooks/deliver-cx-they-expect-customer-experience-trends-report" target="_blank">70% of consumers</a> who consider themselves loyal to brands will move on after just one bad experience. And these sub-par experiences can be costly. A study by NewVoiceMedia, reports that U.S. businesses are losing $62 billion a year on poor customer experiences—up more than $20 billion since 2013.
                </p>

                <p>
                  With such high stakes associated with each brand interaction, business leaders are smart to invest in delivering customer experiences that delight. Whether investments aim at improving responsiveness, consistency, convenience, or personalization—all hallmarks of superior customer experience—the common thread lies in communication. And with more than 50% of consumers sharing that unclear communication is their biggest barrier to having a positive customer experience, there’s still plenty of room for brands to improve. 
                </p>

                <p>
                  When it comes to communication, effectiveness can often vary depending on the circumstance. But in the case of customer experience, the answer might lie in ensuring customer-facing messages come across as intended the first time. A recent <a href="https://www.accenture.com/t00010101T000000__w__/es-es/_acnmedia/Accenture/Conversion-Assets/DotCom/Documents/Global/PDF/Dualpub_6/Accenture-Customer-2020-Future-Ready-Reliving-Past.pdf" target="_blank">Accenture study</a> found that eight out of ten customers who stopped buying from a brand due to a poor experience could have been retained if their issue had been resolved on the first attempt. Can you afford to live with that sort of regret?
                </p>

                <p>Here are three ways that Grammarly Business can help your customer experience teams avoid miscommunication and get their messages across as they intend:</p>

                <h2 className="my-4">Convey credibility and competence</h2>

                <div className="container-fluid px-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <img className="mt-4 mb-5 img-fluid" src={require("../../assets/images/blog/reduce-miscommunication-cost-feature/example1.png")} alt="" />
                    </div>
                  </div>
                </div>

                <p>
                  Effective communication is about much more than spelling, grammar, and punctuation. But there’s no denying that an inadvertent misspelling could convey sarcasm rather than service. Grammarly Business offers real-time suggestions for <a href="https://www.grammarly.com/blog/correctness-dimension/" target="_blank">
                    correctness
                  </a> that ensure your team members present their most credible, competent selves from the first contact.
                </p>

                <h2 className="my-4">Create clarity, not confusion</h2>

                <div className="container-fluid px-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <img className="mt-4 mb-5 img-fluid" src={require("../../assets/images/blog/reduce-miscommunication-cost-feature/example2.png")} alt="" />
                    </div>
                  </div>
                </div>

                <p>
                  Overly wordy sentences—even if intended to be helpful—can be hard to read and leave customers guessing about how their problem will be resolved. With suggestions to <a href="https://www.grammarly.com/blog/what-is-concise-writing/" target="_blank">
                    make communications more concise
                  </a>, Grammarly Business can help team members answer customer questions the first time, cutting down on costly back-and-forth.
                </p>

                <h2 className="my-4">Correspond with consistency</h2>

                <div className="container-fluid px-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <img className="mt-4 mb-5 img-fluid" src={require("../../assets/images/blog/reduce-miscommunication-cost-feature/example3.png")} alt="" />
                    </div>
                  </div>
                </div>

                <p>
                  By definition, jargon is exclusive to a particular group. So when your team members use it in customer-facing communications, it can be confusing at best and alienating at worst. Through a <a href="https://www.grammarly.com/business/styleguide" target="_blank">Grammarly Business style guides</a>, you can create tailored suggestions that flag terms as internal jargon and recommend preferred terminology. These tailored suggestions apply to all team members to ensure terms are being used (or excluded) consistently.
                </p>

                <h2 className="my-4">The $700M Upside to Customer Experience</h2>

                <p>
                  According to Adobe’s 2019 Digital Trends report, B2B respondents ranked customer experience as the <span className="font-italic">single</span> most exciting opportunity for 2020 — beating out content marketing, video marketing, and social.
                </p>

                <p>
                  Nearly <a href="https://www.salesforce.com/research/customer-expectations/#" target="_blank">70% of consumers</a> stated that their standards for good experiences are the highest they’ve ever been, it’s more challenging than ever for brands to meet customer expectations. Faced with the threat of losing customers to competitors who provide better experiences, business leaders must consider the realities of today’s landscape when making decisions about their CX strategy.
                </p>

                <p>
                  <span className="font-weight-bold">Download our ebook, <span className="font-italic">Four Strategies to Maximize Customer Value in 2020 & Beyond</span></span> to learn how your team can improve its customer experience practices.
                </p>

                {/* <a className="ghost-btn mt-2 d-block mx-auto mb-5 text-center" href="https://grammarly-enterprise.com/customer-experience-ebook?utm_source=content&utm_medium=features_blog&utm_campaign=customer_experience" target="_blank">Download Now</a> */}

                <Link to="/customer-experience-ebook?utm_source=content&utm_medium=features_blog&utm_campaign=customer_experience">
                  <button className="ghost-btn mt-2 d-flex mx-auto mb-5">Download Now</button>
                </Link>

                
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ReduceMiscommunicationCostFeatures;
