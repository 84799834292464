import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class EmpatheticLeadershipResources extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Supportive Resources for Leading with Empathy and Care</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/empathetic-leadership-resources/GB-Blog-banner---Supportive-Resources---Desktop-1X.jpg")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>March 15, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">
                  The last few weeks have undoubtedly been an unusual and chaotic time for all of us—both professionally and personally. The social measures we need to take to address the COVID-19 pandemic mean adjusting our routines in drastic ways. For organizations, this often means entirely
                  changing the way we work—whether we were fully prepared or not.
                </p>

                <p>As a small gesture of support, we’ve rounded up ten articles we published over the years that may be helpful as you guide your team through new communication norms.</p>

                <h2 className="my-4">Navigating tone in written communication</h2>
                <p>
                  Current circumstances are anything but normal, and it’s okay—important, even—to acknowledge that. And while what you say to others during this tumultuous time is very important, it’s worth taking the time to consider how your messages are coming across to be sure you’re striking
                  the right balance of empathy and professionalism.
                </p>

                <p>
                  <a href="https://www.grammarly.com/blog/tone-and-emotions/" target="_blank">
                    What Is Tone in Writing?
                  </a>
                  <br />
                  <a href="https://www.grammarly.com/blog/tone-and-voice/" target="_blank">
                    What’s the Difference Between Tone and Voice?
                  </a>
                  <br />
                  <a href="https://www.grammarly.com/blog/tone-matching/" target="_blank">
                    Tone Matching: What Is It, Who Uses It, and Why?
                  </a>
                  <br />
                  <a href="https://www.grammarly.com/blog/formal-vs-informal-writing/" target="_blank">
                    Formal vs. Informal Writing
                  </a>
                </p>

                <h2 className="my-4">Communicating at a distance</h2>
                <p>
                  Whether you were already working remotely or now find yourself newly at a distance, remote work means reliance on digital communication. To keep everyone on a team connected and moving in sync, it’s important to take extra care to provide context and clarity in our writing—even
                  when moving fast. Consider sharing these articles with team members as a helpful reminder to be mindful of context and to take their audience into account before hitting send.
                </p>

                <p>
                  <a href="https://www.grammarly.com/blog/improve-communication-skills/" target="_blank">
                    Three Strategies to Improve Communication at Work
                  </a>
                  <br />
                  <a href="https://www.grammarly.com/blog/concise-email-writing/" target="_blank">
                    Five Ways to Keep Your Emails Short and Sweet
                  </a>
                  <br />
                  <a href="https://www.grammarly.com/blog/how-writing-helps-people-connect/" target="_blank">
                    How Writing Helps People Connect
                  </a>
                  <br />
                  <a href="https://www.grammarly.com/blog/email-writing-apps/" target="_blank">
                    Seven Email Apps to Make Your Messages Shine
                  </a>
                </p>

                <h2 className="my-4">Showing appreciation</h2>
                <p>As organizations and individuals adapt to new working conditions and routines, don’t forget to let your team members know that you recognize their contributions and that you appreciate them. </p>

                <p>
                  <a href="https://www.grammarly.com/blog/how-to-show-appreciation-to-your-staff/" target="_blank">
                    How to Show Appreciation to Your Staff
                  </a>
                  <br />
                  <a href="https://www.grammarly.com/blog/compliments-for-any-person/" target="_blank">
                    75 Compliments to Use When You Want to Say Something Nice
                  </a>
                </p>

                <p>We also understand that business leaders are going above and beyond to do what’s best for their teams, their companies, and their communities right now. From all of us here at Grammarly, please stay healthy and safe.</p>
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" 
                      className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EmpatheticLeadershipResources;
