import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import {Waypoint} from "react-waypoint";
import anime from "animejs";
import Carousel from "react-bootstrap/Carousel";
import CallToAction from "../components/Button";
import Layout from "../components/Layout";
import AllTeamsCardSection from '../components/AllTeamsCardSection';

class ThankYouCPScheduled extends React.Component {
  state = {
    gbsSectionPlay: false,
    edmSectionPlay: false,
    centerSectionPlay: false,
    lastSectionPlay: false,
  };

  constructor() {
    super();

    const script = document.createElement("script");
    script.async = true;
    script.src = "https://js.chilipiper.com/marketing.js";
    //For head
    document.head.appendChild(script);

    this.fireChilliPiper = this.fireChilliPiper.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.CPbutton.click();
      // $('CilliPiperBtn').click();
    }, 700);
  }

  async fireChilliPiper() {
    await window.ChiliPiper.getQuery("grammarly", "test_inbound_router", {
      queryVariable: "Email",
    });
  }

  gbsSectionAnime = () => {
    anime({
      targets: ".gbs-image-section .main-sheet2",
      scale: [0, 1],
      duration: 1000,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
    anime({
      targets: ".gbs-image-section .main-sheet",
      scale: [0, 1],
      // translateX: [500, 0],
      // translateY: [-255, 0],
      duration: 1200,
      delay: 1000,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
    anime({
      targets: ".gbs-image-section .main-sheet1",
      scale: [0, 1],
      // translateX: [500, 0],
      // translateY: [-255, 0],
      duration: 1200,
      delay: 1500,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
  };

  edmSectionAnime = () => {
    anime({
      targets: ".edm-mockup-img-section .edm-img1",
      translateX: [-210, 0],
      duration: 2200,
      easing: "easeInOutBack",
      autoplay: false,
    }).play();
    anime({
      targets: ".edm-mockup-img-section .edm-img2",
      translateX: [-330, 0],
      duration: 2200,
      easing: "easeInOutBack",
      autoplay: false,
    }).play();
    anime({
      targets: ".edm-mockup-img-section .edm-img3",
      translateX: [-460, 0],
      duration: 2200,
      easing: "easeInOutBack",
      autoplay: false,
    }).play();
  };

  centerSectionAnime = () => {
    anime({
      targets: "#centralised-img1",
      translateX: [530, 0],
      duration: 1500,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
    anime({
      targets: "#centralised-img2",
      translateX: [-360, 0],
      duration: 1500,
      easing: "easeInOutSine",
      autoplay: false,
    }).play();
  };

  lastSectionAnime = () => {
    const timeline = anime
      .timeline({
        duration: 1500,
        easing: "easeInOutSine",
        autoplay: false,
      })
      .add({
        targets: ".why-work-img-collection .oval",
        scale: [0.5, 1],
        autoplay: false,
      })
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets1",
          translateX: [-310, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      ) // relative offset
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets2",
          translateX: [-360, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      )
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets3",
          translateX: [-410, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      ) // relative offset
      .add(
        {
          targets: ".why-work-img-collection .msg-sheets4",
          translateX: [-460, 0],
          duration: 1800,
          easing: "easeInOutBack",
          autoplay: false,
        },
        0
      );
    timeline.play();
  };

  animateSection = (section) => {
    const {gbsSectionPlay, edmSectionPlay, centerSectionPlay, lastSectionPlay} = this.state;
    switch (section) {
      case "gbsSection":
        if (!gbsSectionPlay) {
          this.gbsSectionAnime();
          this.setState({gbsSectionPlay: true});
        }
        break;
      case "edmSection":
        if (!edmSectionPlay) {
          this.edmSectionAnime();
          this.setState({edmSectionPlay: true});
        }
        break;
      case "centerSection":
        if (!centerSectionPlay) {
          this.centerSectionAnime();
          this.setState({centerSectionPlay: true});
        }
        break;
      case "lastSection":
        if (!lastSectionPlay) {
          this.lastSectionAnime();
          this.setState({lastSectionPlay: true});
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <Layout className="new-home-landing-page">
        <div className="container-fluid px-0">
          <section className="header-section pt-2 pt-md-0">
            <div className="container">
              <div className="row pt-md-5">
                <div className="col-md-5">
                  <h1 className="mb-2">Better communications, brighter connections</h1>
                  <p className="py-3 pr-md-5">Real-time writing suggestions to empower teams of three or more.</p>
                </div>
                <div className="col-md-6 offset-md-1 mt-5 mt-md-0 pt-md-4 pb-3">
                  <div className="form-container position-relative">
                    <div className="Thankyou-Container text-center">
                      <img className="mt-4" src={require("../assets/images/newHome/paperPlane.png")} alt="" />
                      <h3 className="mt-4">You’re all set!</h3>
                      <p className="mt-4 pb-5">We look forward to speaking with you soon.</p>
                    </div>
                  </div>
                  <button ref={button => this.CPbutton = button} className="CilliPiperBtn hiden-btn" onClick={() => this.fireChilliPiper()}>Fire</button>
                  <div className="w-100 text-right position-absolute header-lines d-none d-md-block">
                    <img src={require("../assets/images/lines.png")} alt="" />
                  </div>
                </div>
                <div className="w-100 position-relative header-dots">
                  <img src={require("../assets/images/header-dots.png")} alt="" />
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="all-teams-section ">
          <div className="container all-teams-header">
            <div className="row text-center">
              <div className="col">
                <h2 className="heading-type-2">See how Grammarly Business helps you perform better</h2>
              </div>
            </div>
          </div>
          <AllTeamsCardSection />
          
        </section>
        <section className="map-holder-section">
          <div className="container">
            <div className="row ">
              <div className="col-sm-5 align-self-center text-center text-md-left">
                <h2 className="heading-type-2 heading-type-2-white">Recognized for excellence, used by leading companies</h2>
              </div>
              <div className="col-sm-7 ">
                <div className="d-flex justify-content-end">
                  <div className="fast-company-section">
                    <div className="row">
                      <div className="col-4 mic-img-section align-self-center">
                        <img className="img-fluid" src={require("../assets//images/mic_logo.png")} alt="MIC Logo" />
                      </div>
                      <div className="col-8 fc-img-section">
                        <img className="img-fluid" src={require("../assets//images/FC_logo.png")} alt="FC Logo" />
                        <p className="p-type-2 pr-3 pr-md-0">One of Fast Company’s Most Innovative Companies in AI</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <div className="logo-collection">
                    <div className="row">
                      <div className="col-6 col-lg-auto lc-cisco text-center text-md-left">
                        <img className="img-fluid" src={require("../assets//images/cisco.png")} alt="Cisco logo" />
                      </div>
                      <div className="col-6 col-lg-auto lc-dell text-center text-md-left">
                        <img className="img-fluid ml-lg-2" src={require("../assets//images/dell.png")} alt="Dell logo" />
                      </div>
                      <div className="col-6 col-lg-auto lc-expedia text-center text-md-left">
                        <img className="img-fluid ml-lg-2 mt-md-3 mt-lg-0" src={require("../assets//images/expedia.png")} alt="Expedia logo" />
                      </div>
                      <div className="col-6 col-lg-auto lc-expensify ">
                        <img className="img-fluid ml-lg-3 mt-md-3 mt-lg-0" src={require("../assets//images/expensify-logo_one-color.png")} alt="Expensify logo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-grey beyond-grammar-section">
          <div className="container text-center">
            <div className="row">
              <div className="col-sm">
                <h2 className="heading-type-2">Beyond Grammar</h2>
              </div>
            </div>
          </div>

          <div className="container bgs-text-img-section bgs-gb">
            <div className="row">
              <div className="col-md-6 col-lg-6 ">
                <Waypoint onEnter={() => this.animateSection("gbsSection")} topOffset="70%">
                  <div id="gbsSection" className="gbs-image-section" style={{overflow: "hidden", position: "relative", width: "612px", marginLeft: "-136px", height: "412px"}}>
                    <img className="" src={require("../assets//images/beyond.png")} style={{opacity: "0"}} alt="" />
                    <img className="main-background" src={require("../assets//images/goal-based-suggestion.png")} alt="Goal based" style={{position: "absolute", top: "0", right: "0"}} />
                    <img className="main-sheet" src={require("../assets//images/goal-based-suggestion1.png")} alt="Goal based" style={{position: "absolute", top: "79px", width: "216px", left: "335px"}} />
                    <img className="main-sheet1" src={require("../assets//images/goal-based-suggestion2.png")} alt="Goal based" style={{position: "absolute", top: "142px", width: "130px", right: "54px"}} />
                    <img className="main-sheet2" src={require("../assets//images/goal-based-suggestion3.png")} alt="Goal based" style={{position: "absolute", top: "88px", right: "30px", width: "121px"}} />
                  </div>
                </Waypoint>
              </div>
              <div className="col-md-6 col-lg-5 offset-lg-1 bgs-text-section">
                <h3 className="heading-type-3">Goal-based suggestions</h3>
                <p className="pr-md-1">
                  Grammarly processes writing in real time, as your employees type. Sophisticated AI-powered algorithms offer comprehensive improvements for spelling and grammar as well as in-depth suggestions for improving clarity, readability, word choice, writing style, and tone.
                </p>
                <CallToAction to="https://grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta d-none d-md-inline-block m6reqdem" eName="service-team" eLabel="enterprise-service-teams-demo">
                  Get in touch
                </CallToAction>
              </div>
            </div>
          </div>

          <div className="container bgs-text-img-section bgs-pa ">
            <div className="row">
              <div className="col-md-6 col-lg-5 offset-lg-1 order-md-2 continer-show-dots edm-mockup-img-section">
                <Waypoint onEnter={() => this.animateSection("edmSection")} topOffset="70%">
                  <div id="edmAnimation" className="edmAnimationSection" style={{width: "463px", overflow: "hidden", position: "relative", paddingRight: "35px"}}>
                    <img className="" src={require("../assets//images/edm_mockup3-1.png")} style={{opacity: "0"}} alt="" />
                    <img className="edm-img1" src={require("../assets//images/edm1.png")} alt="Edm mockup" style={{width: "218px", position: "absolute", left: "0", bottom: "0"}} />
                    <img className="edm-img2" src={require("../assets//images/edm2.png")} alt="Edm mockup" style={{width: "218px", position: "absolute", bottom: "30px", left: "118px"}} />
                    <img className="edm-img3" src={require("../assets//images/edm3.png")} alt="Edm mockup" style={{width: "218px", position: "absolute", right: "35px", top: "0"}} />
                  </div>
                </Waypoint>
              </div>
              <div className="col-md-6 col-lg-6 bgs-text-section order-md-1">
                <h3 className="heading-type-3">Personalized assistance</h3>
                <p className="pr-md-5">
                  Grammarly offers detailed explanations along with every suggestion, so your team can up-level their writing skills as they go about their usual responsibilities. Each team member receives a personalized weekly report highlighting where their writing is strong and where it could be
                  stronger.
                </p>
                <CallToAction to="https://grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta d-none d-md-inline-block m7reqdem" eName="service-team" eLabel="enterprise-service-teams-demo">
                  Get in touch
                </CallToAction>
              </div>
            </div>
          </div>

          <div className="container bgs-text-img-section bgs-centralised">
            <div className="row">
              <div className="col-md-6 col-lg-6 centralised-img-section">
                <Waypoint onEnter={() => this.animateSection("centerSection")} topOffset="70%">
                  <div id="centralisedAnimation" className="clearfix" style={{overflow: "hidden", position: "relative", height: "347px", width: "537px", marginLeft: "-75px"}}>
                    <img className="centralized-img-whole" src={require("../assets//images/centralized.png")} style={{opacity: "0"}} alt="" />
                    <img id="centralised-img1" className="" src={require("../assets//images/centralised_report1.png")} alt="Centralized" style={{position: "absolute", top: "0", right: "0"}} />
                    <img id="centralised-img2" className="" src={require("../assets//images/centralised_report2.png")} alt="Centralized" style={{position: "absolute", bottom: "0", left: "0", width: "360px"}} />
                  </div>
                </Waypoint>
              </div>
              <div className="col-md-6 col-lg-5 offset-lg-1 bgs-text-section">
                <h3 className="heading-type-3">Centralized reporting</h3>
                <p className="pr-md-1">Admin dashboards allow team leaders to monitor adoption and aggregated writing trends for their team. Centralized billing tools make it easy to manage your team account and add, remove, or transfer employee accounts as needed.</p>
                <CallToAction to="https://grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta d-none d-md-inline-block m8reqdem" eName="service-team" eLabel="enterprise-service-teams-demo">
                  Get in touch
                </CallToAction>
              </div>
            </div>
          </div>

          <div className="container bgs-text-img-section bgs-wi">
            <div className="row">
              <div className="col-md-5 offset-md-1 order-md-2">
                <div className="where-you-work">
                  <Waypoint onEnter={() => this.animateSection("lastSection")} topOffset="70%">
                    <div id="lastAnimation" className="why-work-img-collection" style={{width: "530px", height: "436px", position: "relative", overflow: "hidden"}}>
                      <img className="" src={require("../assets//images/works.png")} style={{opacity: "0"}} alt="" />
                      <img className="oval" src={require("../assets//images/where-you-work-oval.png")} alt="Works" style={{position: "absolute", bottom: "0", right: "0", width: "385px"}} />
                      <img className="msg-sheets1" src={require("../assets//images/where-you-work4.png")} alt="Works" style={{position: "absolute", top: "0", left: "0", width: "310px"}} />
                      <img className="msg-sheets2" src={require("../assets//images/where-you-work1.png")} alt="Works" style={{position: "absolute", top: "35px", left: "50px", width: "310px"}} />
                      <img className="msg-sheets3" src={require("../assets//images/where-you-work2.png")} alt="Works" style={{position: "absolute", top: "77px", left: "100px", width: "310px"}} />
                      <img className="msg-sheets4" src={require("../assets//images/where-you-work3.png")} alt="Works" style={{position: "absolute", top: "115px", left: "150px", width: "310px"}} />
                    </div>
                  </Waypoint>
                </div>
              </div>
              <div className="col-md-6 bgs-text-section order-md-1">
                <h3 className="heading-type-3">Works where you work</h3>
                <p className="pr-md-1">Seamlessly integrating across devices and platforms, Grammarly Business is designed to meet the needs of today's workplace. Team members are empowered to execute tasks with speed without sacrificing quality – at their desk or on the go.</p>
                <CallToAction to="https://grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta d-none d-md-inline-block m9reqdem" eName="service-team" eLabel="enterprise-service-teams-demo">
                  Get in touch
                </CallToAction>
              </div>
            </div>
          </div>
          <div className="container py-3 section-grey sticky-bottom d-md-none">
            <div className="row">
              <div className="col-md-12">
                <CallToAction to="https://grammarly.com/contact/sales?source=enterprise" eType="Demo" className="btn-cta" eName="service-team" eLabel="enterprise-service-teams-demo m10reqdem">
                  Get in touch
                </CallToAction>
              </div>
            </div>
          </div>
        </section>

        <section className="accelarate-results-section d-none d-md-flex">
          <div className="container continer-show-dots ars-container">
            <div className="text-center ars">
              <h2 className="heading-type-2">Up-level execution, accelerate results</h2>
              <video id="explorevideo" muted autoPlay>
                <source src={require("../assets//videos/mother_demo_desktop_v1.webm")} type="video/webm" />
                <source src={require("../assets//videos/mother_demo_desktop_v1.mp4")} type="video/mp4" />
              </video>
              <CallToAction to="https://grammarly.com/contact/sales?source=enterprise" eType="Talk" className="mt-0" eName="service-team" eLabel="enterprise-service-teams-talk m11letstalk">
                Let's Talk
              </CallToAction>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid px-0 continer-show-dots ars-container d-md-none">
            <div className="text-center ars">
              <h2 className="heading-type-2 mt-5 mb-4 pt-5">Up-level execution, accelerate results</h2>

              <Carousel>
                <Carousel.Item>
                  <img src={require("../assets//images/Mobile1_4.gif")} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={require("../assets//images/Mobile2_3.gif")} alt="" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={require("../assets//images/Mobile3_3.gif")} alt="" />
                </Carousel.Item>
              </Carousel>
              <CallToAction to="https://grammarly.com/contact/sales?source=enterprise" eType="Talk" className="mt-4 mb-5 w-75" eName="service-team" eLabel="enterprise-service-teams-talk m12letstalk">
                Let's Talk
              </CallToAction>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default ThankYouCPScheduled;
