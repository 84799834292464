import React from 'react';

const BlogResourcesYoutubeModal = (props) => {
    
    const setShowYoutubeVids = props.setShowYoutubeVids;
    const youtubeVidId = props.youtubeVidId;

    return (
    <>
    <div class="modal modal-vids-custom fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block" }}>
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button onClick={ () => setShowYoutubeVids(false) } type="button" class="close" data-dismiss="modal" aria-label="Close">
                        {/* <span aria-hidden="true">&times;</span> */}
                        <img src="/assets/images/close-icon.png" alt=""/>
                    </button>
                    <iframe
                        title="youtube-blog-video" 
                        width="100%" 
                        height="450" 
                        src={ "https://www.youtube.com/embed/" + youtubeVidId } 
                        frameborder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop modal-vids-backdrop-custom fade show"></div>
    </>
    );
}
 
export default BlogResourcesYoutubeModal;