import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class GrammarlyBusinessStyleGuide extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <link rel="canonical" href="https://www.grammarly.com/blog/grammarly-business-style-guide/" />
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>Introducing Company Style Guides from Grammarly Business</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/grammarly-business-style-guide/501-Style-Guide_Desktop_1X.jpg")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>June 9<sup>th</sup>, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">
                  It takes commitment and consistency to develop a strong, compelling brand. But as today’s business leaders know, that’s easier said than done.
                </p> 
                
                <p>
                  Customers are savvy, and they’re slow to put their trust in a company whose personality seems to change with every interaction. When employees use inconsistent language or internal jargon that customers don’t understand, creating a brand-forward experience across channels is a challenge.
                </p>

                <p>
                  Training employees to write consistently in the company style requires a large &mdash; and ongoing &mdash; time investment. On top of that, employees may be writing in dozens of different interfaces and tools. Trying to ensure compliance with a manual review process is rarely scalable or sustainable. Over time, individual deviations from company style add up, creating confusion for customers, eroding trust, and negatively impacting your brand and bottom line. 
                </p>

                <p>That’s why we’re delighted to roll out a new feature exclusively for Grammarly Business customers: <a href="https://grammarly-enterprise.com/styleguide" target="_blank">customizable company style guides</a>. With a company style guide, Grammarly Business can deliver highly customized, real-time writing suggestions to your employees. You can be confident that whether they’re exploring your blog, browsing your website, or chatting with your support team, customers always feel like they’re interacting with the same company.</p>

                <div className="container-fluid px-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <img className="mt-4 mb-5 img-fluid" src={require("../../assets/images/blog/grammarly-business-style-guide/Comms_Product_example_still_Marketing-V3.png")} alt="" />
                    </div>
                  </div>
                </div>
                <p>
                  <em>Style guides help employees spell company and client names correctly, use brand language properly, and avoid jargon customers don’t understand.</em>
                </p>

                <p>
                  In developing this feature, we worked closely with leading companies like Atlassian and Zapier, who have already begun to see positive results since implementing style guides with Grammarly Business. It has enabled the team at <a href="https://grammarly-enterprise.com/zapier-case-study" target="_blank">Zapier to unify their company voice</a> across departments
                  and individual employees by consolidating multiple reference documents and internal style guides. Thanks to the dynamic nature of a style guide created with Grammarly Business, employees receive up-to-date guidance on branded terms, partner names, and other important language right
                  at the moment they need it.
                </p>

                <div className="container-fluid my-5 px-0">
                  <div className="green-container">
                    <div className="row no-gutters">
                      <div className="col-12 p-3">
                        <h3>“It’s easy to customize the suggestions our team receives with the words and styles that are important to us. <span className="green-highlight">The style guide ensures that our message is always on brand and coming across the right way, no matter the channel</span> we use to communicate.”</h3>
                        <p><em>- Janine Anderson, Managing Editor, Zapier</em></p>
                      </div>
                    </div>
                  </div>
                </div>

                <p>As we continue to invest in building out essential business features like style guides, we believe that the value of Grammarly Business to companies large and small will only grow. With more distributed workforces, growing digital collaboration, and increased competition, high-quality communication is the key to delivering better, faster results.</p>

                <p>
                <a href="https://grammarly-enterprise.com" target="_blank">Learn more</a> about how Grammarly Business helps companies succeed or <a href="https://support.grammarly.com/hc/en-us/articles/360043832652" target="_blank">start building your own company style guide</a> today.
                </p>
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default GrammarlyBusinessStyleGuide;
