import React from 'react';
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import ReactGA from "react-ga";

class AllTeamsCardSection extends React.Component {
    constructor(props) {
      super(props);
    }

    registerGAPageViewCustom = (e) => {
        const {history} = this.props;
    
        e.preventDefault();
    
        var redirectLocation = "/";
        if (e.target.getAttribute("href")) {
          redirectLocation = e.target.getAttribute("href");
        }
        redirectLocation = redirectLocation + window.location.search;
    
        ReactGA.pageview(redirectLocation);
        history.push(redirectLocation);
    };

    render() {
        return (  
            <div className="container card-section">
                <div className="row text-center justify-content-center">
                    
                    <div className="col-md-6 col-lg-3">
                        <div className="card ">
                        <img className="card-img-top" src={require("../assets/images/customer_service_image.png")} alt="Card cap" />
                        <div className="card-body">
                            <h5 className="heading-type-3 card-title">Customer Experience</h5>
                            <p className="card-text pr-lg-3">Respond quickly with quality.</p>
                            <Link to="/yourteam/customer-experience" onClick={this.registerGAPageViewCustom} className="btn btn-primary btn-lm m5cust">
                            Learn more
                            </Link>
                        </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-3 ">
                        <div className="card">
                        <img className="card-img-top" src={require("../assets/images/hr_image.png")} alt="Card cap" />
                        <div className="card-body">
                            <h5 className="heading-type-3 card-title">HR<span className="d-none d-lg-block">&nbsp;</span></h5>
                            <p className="card-text">Make every communication count.</p>
                            <Link to="/yourteam/hr" onClick={this.registerGAPageViewCustom} className="btn btn-primary btn-lm m2hr">
                            Learn more
                            </Link>
                        </div>
                        </div>
                    </div>
                
                    <div className="col-md-6 col-lg-3  mt-md-5 mt-lg-0">
                        <div className="card">
                        <img className="card-img-top" src={require("../assets/images/marketing_image.png")} alt="Card cap" />
                        <div className="card-body">
                            <h5 className="heading-type-3 card-title">Marketing<span className="d-none d-lg-block">&nbsp;</span></h5>
                            <p className="card-text">
                            Make your
                            <br className="d-none d-lg-block" /> message memorable.
                            </p>
                            <Link to="/yourteam/marketing" onClick={this.registerGAPageViewCustom} className="btn btn-primary btn-lm m3mar">
                            Learn more
                            </Link>
                        </div>
                        </div>
                    </div>
                
                    <div className="col-md-6 col-lg-3 mt-md-5 mt-lg-0">
                        <div className="card last-card">
                        <img className="card-img-top" src={require("../assets/images/sales_image.png")} alt="Card cap" />
                        <div className="card-body">
                            <h5 className="heading-type-3 card-title">Sales<span className="d-none d-lg-block">&nbsp;</span></h5>
                            <p className="card-text px-md-2">Bring your A-game to every email.</p>
                            <Link to="/yourteam/sales" onClick={this.registerGAPageViewCustom} className="btn btn-primary btn-lm m4sales">
                            Learn more
                            </Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default withRouter(AllTeamsCardSection);