import React from "react";
import Layout from "../components/Layout";
import CallToAction from "../components/Button";
import ebookFile from "../assets/pdf/Four Strategies to Maximize Customer Value.pdf";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import { saveAs } from "file-saver";


class CustomerExperienceeBook extends React.Component {
  constructor() {
      super()
      this.UTMSource = ""
      this.UTMSourceFlag = false
      this.getURLQuery();

      this.state = {
          retUrl: "https://grammarly-enterprise.com/thankyou",
      };
  }

  getURLQuery() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('utm_source')){
      this.UTMSource = urlParams.get('utm_source');
      this.UTMSourceFlag = true;
      console.log(this.UTMSource);
    }
    else{
      this.UTMSource = "";
    }
  }

  verifyCaptcha(value) {
    if (value != "") {
      $("#FormSubmit").removeClass("d-none");
      $("#FormSubmit").addClass("d-flex");
    } else {
      $("#FormSubmit").removeClass("d-flex");
      $("#FormSubmit").addClass("d-none");
    }
  }

  submitForm(e) {
    saveAs(ebookFile,"Four Strategies to Maximize Customer Value.pdf");
    // e.preventDefault();
  }

  render() {
    return (
      <Layout className="ebook-landing-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
              <section className="py-4 pt-lg-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 pr-lg-0">
                      <h1 className="font-family-circular font-weight-bold heading-txt">Four Strategies to Maximize Customer Value in 2020 &amp; Beyond</h1>
                      <p>Download this free ebook for CX leaders.</p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section-grey pb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-6 text-left d-none d-md-flex flex-column justify-content-center">
                      <div className="pt-4 pt-lg-0 text-left form-container text-lg-left" style={{ position: 'relative', zIndex: 1 }}>
                        
                        {/* <form className="pt-lg-1" id="pardot-form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                          <input type={"hidden"} name="oid" value="00D80000000d8Lr" />
                          <input type={"hidden"} name="retURL" value={this.state.retUrl} />
                          <input type={"hidden"} name="lead_source" value={this.props.leadSourceInputData?this.props.leadSourceInputData:"grammarly-enterprise"} />
                          <input type={"hidden"} id="00N80000003lP66" name="00N80000003lP66" title="License Type" value="Volume License"/>
                          <input type={"hidden"} id="00N3c000006cghu" name="00N3c000006cghu" title="utmSource" value={this.UTMSource}></input>
                          <input type={"hidden"} id="00N3c000006wQW2" maxLength="255" name="00N3c000006wQW2" size="20"  />

                          <div className="form-elements">
                            <input id="first_name" maxLength="40" name="first_name" placeholder="First Name" size="20" type="text" required />
                            <br />

                            <input id="last_name" maxLength="80" name="last_name" placeholder="Last Name" size="20" type="text" required />
                            <br />

                            <input id="company" maxLength="40" name="company" placeholder="Company Name" size="20" type="text" required />
                            <br />

                            <input id="email" maxLength="80" name="email" placeholder="Work Email" size="20" type="email" required />
                            <br />
                            <br />

                            <div style={{ maxWidth:304, margin: "0 auto" }}>
                              <ReCAPTCHA sitekey="6Lcm_7EZAAAAAHKVOJZw7F0Hgg-H-FBRGTNwiPue" render="explicit" onChange={this.verifyCaptcha} />
                            </div>
                            <br />

                            <input id="FormSubmit" className="d-none align-self-center btn btn-primary btn-talk" type="submit" name="submit" value="Download PDF" onClick={this.submitForm} />
                            <CallToAction to={ebookFile} eType="Download" id="DownloadBtn" className="py-3 px-5 d-none" eName="Customer Experience ebook" eLabel="customer-experience-ebook-download" noRouter addGAEvent="yes">
                              Download PDF
                            </CallToAction>
                          </div>
                        </form>
                       */}

                        <div className="iframe-form-ebook d-flex flex-column ">
                          {/* <iframe src="https://go.grammarly.com/l/894581/2020-12-07/2chx" width="100%" height="250" type="text/html" frameborder="0" allowTransparency="true" style={{border: 0}}></iframe> */}
                          <iframe src="https://go.grammarly.com/l/894581/2021-01-19/471t" width="100%" height="250" type="text/html" frameborder="0" allowTransparency="true" style={{border: 0}}></iframe>

                        </div>
                      </div>
                    </div>

                    <div class="pt-5 col-12 col-lg-6 text-center">
                      <img
                        src={require('../assets/images/customer_experience_ebook_new.jpg')}
                        className="img-fluid rounded"
                        alt="Grammarly Ebook"
                        style={{
                          marginLeft: -100,
                          marginRight: -20,
                          width: 'auto',
                          maxWidth: 'calc(100% + 20px)',
                          position: 'relative',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section className="section-grey mb-6 pb-5">
                <div className="container">
                  <h2 className="mb-3">Unlock the $700M upside to the customer experience</h2>

                  <div className="row">
                    <div className="col-12 col-lg-4 pb-lg-0">
                      <ul className="pl-4"><li><p>Nearly 70% of customers say their standards for a good experience are higher than ever. Learn about four different realities CX teams must face in the most critical era yet.</p></li></ul>
                    </div>
                    <div className="col-12 col-lg-4 pb-lg-0">
                    <ul className="pl-4"><li><p>Get a quick crash course in strategies that can improve the way your team communicates and operates, like integrating your technology.</p></li></ul>
                    </div>
                    <div className="col-12 col-lg-4 mb-4">
                    <ul className="pl-4"><li><p>See how Grammarly Business helps enterprise teams communicate more accurately and consistently while providing admin tools that monitor adoption.</p></li></ul>
                    </div>
                  </div>


                  <blockquote className="blockquote mb-5">
                    <img className="inverted-comma" src={require('../assets/images/double-inverted-comma.png')} alt="" />
                      <p className="blockquote-body">For today’s consumers, just getting a response from a brand—even if it’s timely—is not enough. Consumers want a high-quality experience. They have high expectations for consistency, convenience, friendliness, speed, and more besides. 75% expect consistent experiences across multiple channels. 53% say that unclear communication is the biggest issue preventing them from a positive customer experience.”
                      </p>
                  </blockquote>

                  <h2>About</h2>
                  <p>Grammarly is a digital writing assistant that helps more than 20 million people write more clearly and effectively every day. Grammarly Business is an integrated solution that empowers teams to communicate better with each other and with their customers while staying on-brand. It goes beyond spelling and grammar with real-time suggestions for improving readability, word choice, writing style, and tone. Learn more about <a href="https://grammarly-enterprise.com">Grammarly Business</a>.</p>
                </div>
              </section>
            </div>
            <div className="col-12 px-0 sticky-bottom d-md-none">
              <section className="section-grey">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center">
                      <div className="py-3 text-center">
                        <CallToAction to={ebookFile} download eType="Download" className="py-3 px-5" eName="Customer Experience ebook" eLabel="customer-experience-ebook-download" noRouter addGAEvent="yes">
                          Download Now
                        </CallToAction>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CustomerExperienceeBook;
