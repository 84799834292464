import React from "react";
import {Link} from "react-router-dom";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class ConsistentCustomerExperience extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>The Secret to Earning Customer Trust Is Consistency</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100" src={require("../../assets/images/blog/Consistent-customer-experience/Consistent_Customer_Experience_Desktop_Hero.png")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>May 14, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                <p className="IntroPara">
                  Customer trust can be hard to earn. Traditionally, brands have considered it qualitatively, and all too often, it comes down to reacting when faced with its downside impacts. The truth is that trust is difficult to measure and even harder to assign tangible value. However, research
                  shows that it’s not only possible but also imperative for brands to shift this mindset to remain both profitable and competitive. Customer support leaders and organizations are uniquely positioned to lead the charge.
                </p>

                <p>
                  In their Competitive Agility Index, Accenture found that 54% of the 7,000 brands scored had experienced a significant drop in trust over the previous two and a half years. The revenues that these brands stood to consequently lose equated to roughly $180 billion <sup>1</sup>.
                </p>

                <p>
                  A drop in trust correlated not only with potential revenue loss, but also with a more significant decrease in overall competitiveness compared to brands which experienced no such drop. The implication? Brands simply can’t afford to take a reactive approach when it comes to trust –
                  they need proactive strategies that place customer trust at their foundation, aiming to reinforce every interaction.
                </p>

                <p>
                  With 64% of Americans reporting some form of contact with customer support <sup>2</sup>, and the majority using multiple channels to get in touch <sup>3</sup>, leaders who oversee these functions have a meaningful opportunity to build trust and influence its role in internal
                  culture.
                </p>

                <h2 className="my-4">Understanding Customer Trust</h2>
                <p>
                  While definitions of trust may vary slightly, most tend to agree that intent and competence play a role when it comes to winning over customers. In the book Extreme Trust: Honesty as a Competitive Advantage, authors Don Pepper and Martha Rodgers, Ph.D. relay that brands need to be
                  pure in their intention to do what’s best for their customers to create a trustworthy environment. But it’s not enough to just have pure intentions; brands also need the capability to execute against them. Intent and competence must work together to build trust effectively.
                </p>

                <h2 className="my-4">Four Trust-Building Practices to Consider</h2>

                <div className="container-fluid px-0">
                  <div className="row no-gutters">
                    <div className="col-12">
                      <img className="mt-4 mb-5 img-fluid" src={require("../../assets/images/blog/Consistent-customer-experience/Consistent_Customer_Experience_in-post.png")} alt="" />
                    </div>
                  </div>
                </div>

                <p>
                  Trust won’t happen overnight, but by introducing (or re-emphasizing) these four practices, you’ll be one step closer toward placing your customers’ best interests at the center of your operations. You know your team best – consider starting with an area of relative strength and
                  then build from there.
                </p>

                <ol>
                  <li className="my-2">
                    <b>Empathy:</b> At Grammarly, we define empathy as active listening, which enables us to treat others as they want to be treated. Ensure that listening is a part of your support playbook and that team members know how to use <a href="https://www.grammarly.com/blog/tone-matching/" target="_blank">
                      tone matching
                    </a> in their responses to make customers feel heard.
                  </li>
                  <li className="my-2">
                    <b>Credibility:</b> The need for information is what often compels customers to reach out to brands for support. Three out of four customers expect support representatives to be knowledgeable about their circumstances or product, and 31% say that a knowledgable representative is
                    the most important contributor to a satisfying customer experience <sup>3</sup>. If you don’t have one in place already, create a process that equips your customer-facing teams with accurate and up-to-date company information. <a href="https://www.g2.com/categories/help-desk" target="_blank">
                      Tools
                    </a> that increase visibility across customer interactions and support knowledge-sharing across teams can further empower individual representatives in the moment.
                  </li>
                  <li className="my-2">
                    <b>Commitment:</b> We’re all familiar with the adage, “Actions speak louder than words” for good reason. Few things can diminish trust more quickly or completely than a broken promise or lack of follow-through, so if your teams make commitments to customers, they must deliver.
                    Performance indicators like service level agreements (SLAs) and first contact resolution make it possible to measure and incentivize that commitment. Leaders making an effort to celebrate these fundamentals and model them in their behavior is also essential.
                  </li>
                  <li className="my-2">
                    <b>Transparency:</b> When customers reach out to brands for information, the perception that it’s being withheld breeds frustration and suspicion. And suspicion leads to distrust. Being transparent doesn’t mean you have to disclose everything, but it does mean being truthful and
                    admitting to mistakes. Setting clear guidelines about what can be shared and implementing policies for resolving issues can empower your teams to make better, more confident decisions about information sharing.
                  </li>
                </ol>

                <h2 className="my-4">Consistency Ties It All Together</h2>
                <p>
                  Recent research from Edelman shows that when a customer sees your message repeated across three different channels, their trust in it strengthens <sup>4</sup>. The same goes for behaviors. When customers experience the same behavior again and again – across channels, individual
                  representatives, order numbers, etc. – they form an expectation. And when expectations are consistently met, well, that’s the very foundation of trust.
                </p>

                <p className="mb-5 sources-text">
                  Sources: <br />
                  <ol className="px-md-2 ml-md-1 mt-2">
                    <li>Accenture Agility Index.</li>
                    <li>Customer Thermometer,tomer Service Stats for 2020.</li>
                    <li>Microsoft, State of Global Customer Service Report, 2019.</li>
                    <li>Edelman, Edelman Trust Barometer, 2019.</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#"  className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ConsistentCustomerExperience;
