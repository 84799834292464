import React from "react";
import ReactGA from "react-ga";

const CallToAction = ({ children, to, className, eType, eName, eLabel, addGAEvent, noRouter, useTargetClass }) => {

  const btnClickAction = (e) => {
    e.preventDefault();

    if( addGAEvent === "yes" ){
      console.log("event fired");
      if (eType) {
        ReactGA.event({
          category: eName,
          action: eType,
          label: eLabel,
          // value: window.location.href
        });
      }
    }

    let redirectLocation = "/";
    if( e.target.getAttribute('href') ){
      redirectLocation = e.target.getAttribute('href');
    }

    if(window.location.search){
      if( redirectLocation.includes("?") ){
        redirectLocation = redirectLocation + "&";
      }
      else{
        redirectLocation = redirectLocation + "?";  
      }
      
      redirectLocation = redirectLocation + window.location.search.replace("?", "");
    }
    
    if( e.target.getAttribute('target') === "_blank" ){
      window.open(redirectLocation);
    }
    else{
      window.location = redirectLocation;
    }
  
   
  }
  
  let classData = `btn btn-primary btn-talk ${className}`;
  if( useTargetClass === "yes" ){
    classData = className;
  }
  return (
    
    noRouter ? (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={classData}
        onClick={btnClickAction}
        >
        {children}
      </a>
    ) : (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={classData}
        onClick={btnClickAction}
        >
        {children}
      </a>
    )
  );
};

CallToAction.defaultProps = {
  className: '',
  to: '/',
  eType: false,
  eName: false,
  eLabel: false,
  simple: false
};

export default CallToAction;
