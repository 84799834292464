import React from 'react';

const Pagination = (props) => {
    
    const  getPrevCounter = (index) =>{
        let prevCounter = index-1;
        if(prevCounter<0){
            prevCounter = 0;
        }
        return prevCounter;
    } 

    const  getNextCounter = (index) =>{
        let nextCounter = index+1;
        if(nextCounter>=paginateNum.length){
            nextCounter = index;
        }
        return nextCounter;
    }

    const paginateNum = props.paginateNum;
    const changePaginate = props.changePaginate;
    const index = props.index;
    const prevCounter = getPrevCounter(index);
    const nextCounter = getNextCounter(index, paginateNum);

    return (
    <>  
    {(paginateNum.length > 0)?
    <nav>
        <ul className="pagination justify-content-center">
            <li className="page-item nav-control prev-container">
                <a className="page-link" onClick={() => changePaginate(prevCounter)} tabIndex="-1">Previous</a>
            </li>
            {paginateNum.map( (item, itemIndex) => {
                let activeClass = "";   
                if( item == index ){
                    activeClass = " active ";       
                } 
                return <li className={"page-item " + activeClass}  key={itemIndex} >
                    <a className="page-link" onClick={() => changePaginate(item)} >{item+1}</a>
                    </li>;
            })}
            <li className="page-item nav-control next-container">
                <a className="page-link" onClick={() => changePaginate(nextCounter)} >Next</a>
            </li>
        </ul>
    </nav> 
    :null}
    </>
    );
}
 
export default Pagination;