import React from 'react';
import $ from "jquery";
import Select from 'react-select';
import DropdownIndicator from './DropdownIndicator';

const options = [
    { value: '', label: '--None--' },
    { value: "I'm interested in Grammarly for myself", label: "I'm interested in Grammarly for myself" },
    { value: "I’m interested in Grammarly for my team", label: "I’m interested in Grammarly for my team" },
    { value: "I’m evaluating Grammarly for my company", label: "I’m evaluating Grammarly for my company" },
    { value: "I’m evaluating Grammarly for a governmental organization", label: "I’m evaluating Grammarly for a governmental organization" },
    { value: "I’m evaluating Grammarly for a non-profit organization", label: "I’m evaluating Grammarly for a non-profit organization" },
    { value: "I’m evaluating Grammarly for an educational institution", label: "I’m evaluating Grammarly for an educational institution" }
];
 


class CSFChallengeSelect extends React.Component {
    state = {}

    componentDidMount(){
        $(".rsdc-csf-challenge-select-custom > input").attr("id", "Sales_Interest__c");
        $(".rsdc-csf-challenge-select-custom > input").attr("title", "Sales_Interest__c");
    }

    processSelectOption(selectedOption){
        if( selectedOption.value == "I’m evaluating Grammarly for an educational institution")  {
            $(".form-container .csf-faculty-section").removeClass("d-none");
        }
        else{
            $(".form-container .csf-faculty-section").addClass("d-none");    
        }
    }


    render() { 
        return ( 
            <> 
            <label className="">How can our Sales team help you?</label>
            <Select 
            components={{ DropdownIndicator }}
            maxMenuHeight = "400"
            id="rsd-csf-challenge-select-id" 
            name="00N3c000006wQGx" 
            options={options} 
            className="rs-dropdown-container-custom mt-0 rsdc-csf-challenge-select-custom" 
            onChange={ selectedOption => this.processSelectOption(selectedOption) }
            placeholder="Make a selection"
            styles={{
                indicatorSeparator: () => {}, // removes the "stick"
            }}
            />
            </>
        );
    }
}
 
export default CSFChallengeSelect;