import React from "react"
import { Link } from 'react-router-dom'
import Layout from "../components/Layout"
import Meta from "../components/Meta"

const NotFoundPage = () => (
  <Layout className="not-found-page">
    <Meta title="404 Not found" />
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="error-template">
            <h1>
              Oops!</h1>
            <h2>
              404 Not Found</h2>
            <div className="error-details">
              Sorry, an error has occurred, Requested page not found!
            </div>
            <div className="error-actions">
              <Link to="/" className="btn btn-primary btn-cta">Go to Home</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
