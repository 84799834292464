import React from "react";
import Layout from "../components/Layout";
import CallToAction from "../components/Button";
import ebookFile from "../assets/pdf/Hacking CX eBook.pdf";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";

class MeetGrammarlyBusinessVideoLp extends React.Component {
  constructor() {
      super()
      this.UTMSource = ""
      this.UTMSourceFlag = false
      this.getURLQuery();

      this.state = {
          retUrl: "https://grammarly-enterprise.com/meet-grammarly-business-video-ty",
      };
  }

  getURLQuery() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('utm_source')){
      this.UTMSource = urlParams.get('utm_source');
      this.UTMSourceFlag = true;
      console.log(this.UTMSource);
    }
    else{
      this.UTMSource = "";
    }
  }

  verifyCaptcha(value) {
    if (value != "") {
      $("#FormSubmit").removeClass("d-none");
      $("#FormSubmit").addClass("d-flex");
    } else {
      $("#FormSubmit").removeClass("d-flex");
      $("#FormSubmit").addClass("d-none");
    }
  }

  scrollToRef(xPos, yPos){
    window.scrollTo(xPos, yPos);
  }

  render() {
    return (
      <Layout className="ebook-landing-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-0">
              <section className="py-4 pt-lg-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 pr-lg-0">
                      <h1 className="font-family-circular font-weight-bold heading-txt">Meet Grammarly Business</h1>
                      <p>Grammarly Business Demo</p>
                    </div>
                  </div>
                </div>
              </section>
              
              <section className="section-grey pt-5 pb-2">
                <div className="container">               
                  <h2>Watch our quick demo video to learn more.</h2>
                  <p>Grammarly Business is helping organizations communicate more effectively and productively than ever before. Remote work models have exposed just how critical good communication is to the health of a company. This year, leaders quickly adopted new messaging apps, productivity platforms, and video conference software to keep teams connected. But these new ways to connect also create more opportunities for miscommunication. See how Grammarly Business can help your team achieve its goals with seamless, omnichannel communication.</p>
                </div>
              </section>

              <section className="section-grey pb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-6 text-left ">
                      <div id="form-container-id" className="pt-4 pt-lg-5 text-left form-container text-lg-left" style={{ position: 'relative', zIndex: 1 }}>
                        <form className="pt-lg-1" id="pardot-form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                          <input type={"hidden"} name="oid" value="00D80000000d8Lr" />
                          <input type={"hidden"} name="retURL" value={this.state.retUrl} />
                          <input type={"hidden"} name="lead_source" value={"HackingCXEbookDownloaders"} />
                          <input type={"hidden"} id="00N80000003lP66" name="00N80000003lP66" title="License Type" value="Volume License"/>
                          <input type={"hidden"} id="00N3c000006cghu" name="00N3c000006cghu" title="utmSource" value={this.UTMSource}></input>
                          <input type={"hidden"} id="00N3c000006wQW2" maxLength="255" name="00N3c000006wQW2" size="20"  />

                          <div className="form-elements">
                            {/* <label for="first_name">First Name</label> */}
                            <input id="first_name" maxLength="40" name="first_name" placeholder="First Name" size="20" type="text" required />
                            <br />

                            {/* <label for="last_name">Last Name</label> */}
                            <input id="last_name" maxLength="80" name="last_name" placeholder="Last Name" size="20" type="text" required />
                            <br />

                            {/* <label for="company">Company Name</label> */}
                            <input id="company" maxLength="40" name="company" placeholder="Company Name" size="20" type="text" required />
                            <br />

                            {/* <label for="email">Work Email</label> */}
                            <input id="email" maxLength="80" name="email" placeholder="Work Email" size="20" type="email" required />
                            <br />
                            <br />

                            <div style={{ maxWidth:304, margin: "0 auto", minHeight: "78px", overflow: "auto" }}>
                              <ReCAPTCHA sitekey="6Lcm_7EZAAAAAHKVOJZw7F0Hgg-H-FBRGTNwiPue" render="explicit" onChange={this.verifyCaptcha} />
                            </div>
                            <br />

                            <input id="FormSubmit" className="d-none align-self-center btn btn-primary btn-talk" type="submit" name="submit" value="Watch Now" />
                            {/* <CallToAction to={ebookFile} eType="Download" id="DownloadBtn" className="py-3 px-5 d-none" eName="Customer Experience ebook" eLabel="customer-experience-ebook-download" noRouter addGAEvent="yes">
                              Download PDF
                            </CallToAction> */}
                          </div>
                        </form>
                        
                      </div>
                      
                    </div>

                    <div class="pt-5 pl-md-5 col-12 col-lg-6 text-center">
                      <img
                        src={require('../assets/images/1041_Video_marketing.png')}
                        className="img-fluid rounded"
                        alt="Grammarly Ebook"
                        style={{
                          marginLeft: -50,
                          marginRight: -20,
                          width: 'auto',
                          maxWidth: 'calc(100% + 20px)',
                          position: 'relative',
                        }}
                      />
                    </div>


                  </div>
                </div>
              </section>

              
            </div>

          </div>
        </div>
      </Layout>
    );
  }
}

export default MeetGrammarlyBusinessVideoLp;
