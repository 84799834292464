import React from 'react'
import { Link } from 'react-router-dom'
import ReactGA from "react-ga";
import PropTypes from 'prop-types'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import logo from '../assets/images/Grammarly-Business-Logo.svg'
import { useHistory } from "react-router-dom";
import CallToAction from '../components/Button'
import Pdf from '../assets/pdf/styleguide-custom-rules.pdf';

const Header = ({ siteTitle, landing }) => {
  
  const history = useHistory();
  const registerGAPageViewCustom = (e) => {
    e.preventDefault();
    
    var redirectLocation = "/";
    if( e.target.getAttribute('href') ){
      redirectLocation = e.target.getAttribute('href');
    }
    redirectLocation = redirectLocation + window.location.search;
    
    ReactGA.pageview(redirectLocation);
    history.push( redirectLocation );
  }

  return (
    <section className="navigation-section py-md-2 px-md-5">
      <Navbar expand="lg">
        <div className="row m-0 ">
          <div className="col-sm-12 col-md nav-logo-section">
            <Link onClick={registerGAPageViewCustom} to="/" className="navbar-brand">
              <img
                className="banner-logo img-fluid rounded"
                src={logo}
                alt={siteTitle}
              />
            </Link>
            {!landing && (
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="justify-content-end d-md-none float-right ">
                <img className="navbar-icon" src={require('../assets/images/navbar-icon.png')} alt="navbar icon" />
                <img className="close-icon" src={require('../assets/images/close-icon.png')} alt="close icon" />
              </Navbar.Toggle>
            )}
          </div>
          {!landing && (
            <div className="col-sm-12 col-md p-0">
              <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end mt-md-2">
                <Nav className="navbar-nav">
                  <NavDropdown title="Your Team" id="teams-dropdown">
                    <NavDropdown.Item as={Link} onClick={registerGAPageViewCustom} to="/yourteam/customer-experience" className="hservteams">Grammarly for Customer Experience Teams</NavDropdown.Item>
                    <NavDropdown.Item as={Link} onClick={registerGAPageViewCustom} to="/yourteam/hr" className="hhrteams">Grammarly for HR Teams</NavDropdown.Item>
                    <NavDropdown.Item as={Link} onClick={registerGAPageViewCustom} to="/yourteam/marketing" className="hmarteams">Grammarly for Marketing Teams</NavDropdown.Item>
                    <NavDropdown.Item as={Link} onClick={registerGAPageViewCustom} to="/yourteam/sales" className="hsalteams">Grammarly for Sales Teams</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link  as={Link} to="/resources" >
                      Resources
                  </Nav.Link>
                  {/* <Nav.Link  as={Link} to="/get-in-touch-3">
                    GIT4
                  </Nav.Link> */}
                  
                  {/* <Nav.Link  as={Link} to="/thankyou-2" useTargetClass="yes">
                      TY2
                  </Nav.Link>
                  <Nav.Link  as={Link} to="/get-in-touch" useTargetClass="yes">
                      Get in touch
                  </Nav.Link> */}
                 {/*  <NavDropdown title="Resources" id="resource-dropdown">
                    <NavDropdown.Item as={Link} onClick={registerGAPageViewCustom} to="/ebook" className="hebook">Workforce Success eBook</NavDropdown.Item>
                    <NavDropdown.Item as={Link} onClick={registerGAPageViewCustom} to="/customer-experience-ebook" className="hebook">Customer Experience eBook</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/frostandsullivan-case-study" className="">Frost &amp; Sullivan Case Study</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/zapier-case-study" className="">Zapier Case Study</NavDropdown.Item>
                    <NavDropdown.Item as={CallToAction} to="https://www.grammarly.com/press/research/docs/2019-state-of-workplace-email.pdf" className="" useTargetClass="yes">State of Email Report</NavDropdown.Item>
                    <NavDropdown.Item as={CallToAction} to="/styleguide-custom-rules.pdf" className="" useTargetClass="yes">Style Guide Custom Rules</NavDropdown.Item> */}
                    
                    {/* 
                    <NavDropdown.Item href="https://www.grammarly.com/press/research/docs/2019-state-of-workplace-email.pdf" target="_blank">State of Email Report</NavDropdown.Item> 
                    <NavDropdown.Item as={Link} to="/blog/communication-empowers-teamwork" className="">Communication Empowers Team</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/blog/consistent-communication-retains-customers" className="">Consistent communication retains customers</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/blog/grammarly-business-style-guide" className="">Introducing Style Guides</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/styleguide" className="">Styleguide</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/styleguide-cs" className="">Styleguide-cs</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/cp-notscheduled" className="">Thank you cp notscheduled</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/cp-scheduled" className="">Thank you cp scheduled</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/cp-support" className="">Thank you support</NavDropdown.Item>
                    */}
                    {/* <NavDropdown.Item as={Link} to="/resources" className="">Resources</NavDropdown.Item> */}
                    {/* <NavDropdown.Item as={Link} to="/blog/ensure-consistent-branding-with-style-guides" className="">Ensure consistent branding</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/chilipipertest" className="">Chillipiper test</NavDropdown.Item> */}
                  {/* </NavDropdown> */}
                  <Nav.Item className="nav-item-btn">
                    {/* <Nav.Link  href="https://grammarly.com/contact/sales?source=enterprise" className="btn btn-primary btn-small-cm text-uppercase d-block mt-4 mt-md-0 hgetstart" >
                      Get Started
                    </Nav.Link> */}
                    <Nav.Link  as={CallToAction} to="https://www.grammarly.com/upgrade/business?utm_medium=internal&utm_source=enterprise&utm_campaign=enterprise-site" className="btn btn-primary btn-small-cm text-uppercase d-block mt-4 mt-md-0 hgetstart" useTargetClass="yes">
                      Get Started
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </div>
          )}
        </div>
      </Navbar>
    </section>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``,
  landing: false
};

export default Header
