import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import ServiceForm from "../../components/Form";

class ZouraGBCaseStudy extends React.Component {
  state = { };

  constructor() {
    super();
  }

  render() {
    return (
      <Layout className="zapier-case-study-page blind-case-study-page zourna-case-study-page">
        <div className="container-fluid px-0">
          
          <div className="headerContainer">
            <div className="container px-lg-0">
              <div className="row">
                <div className="col-lg-9  order-2 order-lg-1">
                  <h6 className="pre-header">CASE STUDY</h6>
                  <h1 className="pr-md-2">How a leading global enterprise software company champions the customer experience channeling soft skills</h1>
                  <p className="py-md-3 pr-md-5 mw-xl-67-custom">Using Grammarly Business, the company empowered a globally diverse team to leverage empathy, tone, and clarity through written communication.</p>
                </div>
                <div className="col-lg-3 p-lg-0 order-1 order-lg-2">
                  <div className="h-100 d-flex justify-content-left justify-cintent-lg-center align-items-center ">
                    <img className="img-fluid mb-4 mb-lg-0 banner-hero-image" src={require("../../assets/images/case-study/zoura-case-study/hero-image.png")} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-0 mt-lg-4">
            <div className="row">
              <div className="col-12">
                <p className="IntroPara">
                  The global Business Process Outsourcing (BPO) acts as an extension to their client's customers, requiring excellent written communication that stays on brand. In order to establish a baseline for consistency and excellence, the outsourcing provider looked to Grammarly Business as the tool to deliver high-quality communication.
                </p>
                
              </div>
            </div>

            <div className="row mt-3 pt-3 ">
              <div className="col-12">
                <h3 class="mt-4">The Challenge</h3>
                <p className="pt-4">
                  With a sizeable customer base of 1000+, creating impactful, efficient support was imperative. The company measured their success, in part, based on a net promoter score (NPS), indicative of the likelihood that customers’ would recommend their services to others. To influence this important metric, the company sought to develop soft skills within its team, measuring the quality of communications to customers in support tickets to gauge progress. They knew that measuring soft skills quality before and after this focused effort would allow them to assess its effects on NPS.  Being a global organization, the company realized they needed a global solution. They decided to bring in Grammarly Business to help increase their speed in resolving issues, and by extension, improve the customer experience, all while and leveraging the ability to track and analyze progress.
                </p>
                
              </div>
            </div>
          </div>

          <div className="QuoteContainer">
            <div className="container ">
              <div className="row">
                <div className="col-md-12 ">
                  <h3 class="pr-lg-4">After six months with Grammarly Business, the team saw their overall quality scores increase to 72% in a quarter, increasing month over month.</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-3 mb-md-5 pb-4">
            <div className="row mt-3">
              <div className="col-12">
                <h3>The Solution</h3>
              </div>
            </div>
            <div className="row mt-0">
              <div className="col-12">
                <p className="pt-4">
                The global enterprise software company implemented Grammarly Business to empower support team members with real-time communication coaching. They saw the ability to improve soft skills by leveraging the tone detection feature, in particular. This feature provided helpful cultural context, enabling support team members to visually understand how customers would likely perceive their responses before hitting send. Grammarly Business prompted the customer support team with spelling suggestions, increasing clarity, and leading to faster ticket resolution. The AI-backed tool coached the global customer support team by creating a learning environment around the nuances of written communication. With Grammarly Business, the company’s support team had the resources and communication support they needed while also giving leadership the time and visibility to effectively manage the initiative.
                </p>
                
              </div>
              
            </div>
            

          </div>
          
          <div className="green-container my-3 my-md-5 py-3 py-md-5">
            <div className="container py-4">
              <div className="row pr-xs-15p-custom">
                <div className="col-12">
                  <h3>The Results</h3>
                  
                  <p className="pt-4">
                    To get an accurate assessment of the impact of Grammarly Business, the company compared their NPS before and after implementation. After six months with Grammarly Business, the team saw their overall quality scores increase to 72%, increasing month over month. They also achieved an improvement in the clarity of customer communications by 15% in the first quarter. By providing Grammarly Business to its support teams, the company overall saw improved scores, faster ticket resolution, and a higher percentage of friendly communication. 
                  </p>
                </div>
              </div>
            
            </div>
          </div>
          <div className="container my-3 my-md-5 py-4  key-to-success-section">
            <div className="row text-center">
              <div className="col-12">
                <h3>Keys to Success</h3>
              </div>
            </div>
            <div className="row mt-3 mt-md-5">
              <div className="col-md-4">
                <img src={require("../../assets/images/case-study/zoura-case-study/icon-1.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Show versus tell to drive adoption.</span>
                </div>
                <p className="mt-4 pr-xl-3 mr-xl-4">The company understood the importance of high-quality interactions with customers. Using the metrics built into the Grammarly Business admin dashboard helped gain buy-in for the product among senior team members.</p>
              </div>
              <div className="col-md-4 mt-4 mt-md-0 pl-xl-0">
                <img src={require("../../assets/images/case-study/zoura-case-study/icon-2.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">Increase momentum and sustainability by sharing results.</span>
                </div>
                <p className="mt-4 pr-xl-1 ">
                  Grammarly Business is built with analytics in mind so that customers can see their results and understand how to improve their communication—at both the individual and team level. The company shared weekly results with the team to highlight their progress, which helped sustain momentum after the initial onboarding phase. These consistent progress updates helped boost team morale and normalized the prioritization of clear and effective communication.
                </p>
              </div>
              <div className="col-md-4 mt-4 mt-md-0">
                <img className="" src={require("../../assets/images/case-study/zoura-case-study/icon-3.png")} alt="" />
                <div className="mt-5">
                  <span className="heading-type-3">How you communicate matters.</span>
                </div>
                <p className="mt-4 pr-xl-3 mr-xl-4">The right answer can be misconstrued if wrapped in an unflattering context, leading to a dissatisfied customer. The enterprise software company chiefly relied on the tone detector feature from Grammarly Business to reinforce a consistently positive tone in every customer interaction. The ability to help its team prioritize clear, correct, friendly customer communication with a positive tone made a difference in the NPS.</p>
              </div>
            </div>
          </div>
          <div className="blue-container pb-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      {/* <a href="#"  
                      className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction> */}
                      <CallToAction to="https://www.grammarly.com/contact/sales?source=enterprise" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto">
                        Get in touch
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ZouraGBCaseStudy;
