import React from "react";
import CallToAction from "../../components/Button";
import Layout from "../../components/Layout";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton} from "react-share";
import ServiceForm from "../../components/Form";

class EnshoredCustomerStory extends React.Component {
  state = { };

  constructor() {
    super();

    this.pageURL = window.location.href;
  }

  render() {
    return (
      <Layout className="blog-page">
        <div className="container-fluid px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 my-4">
                <h1>How Global BPO Enshored Remains Competitive in an Evolving Market</h1>
              </div>
            </div>
          </div>
          <div className="row mt-4 header-section-content">
            <div className="col-12">
              <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/enshored-customer-story/desktop-banner.jpg")} alt="" />
              <img className="img-fluid w-100 d-md-none" src={require("../../assets/images/blog/enshored-customer-story/mobile-banner.jpg")} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="row pb-5">
              <div className="col-md-4">
                <div className="row pt-3 pt-md-5 sticky-top sidebar-Container d-md-flex">
                  <div className="col-12 text-center">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-6 col-md-12 text-left text-md-center">
                            <span>September 1, 2020</span>
                          </div>
                        </div>
                      </div>
                      <div className="row my-4 w-75 social-icon-container justify-content-around d-none d-md-flex">
                        <div className="col-xs text-center d-flex align-items-center">
                          <FacebookShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/facebook-logo.png")} alt="" />
                          </FacebookShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <LinkedinShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/linkedin-logo.png")} alt="" />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <TwitterShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/twitter-logo.png")} alt="" />
                          </TwitterShareButton>
                        </div>
                        <div className="col-xs text-center d-flex align-items-center">
                          <EmailShareButton url={this.pageURL}>
                            <img src={require("../../assets/images/blog/mail-logo.png")} alt="" />
                          </EmailShareButton>
                        </div>
                      </div>
                      <div className="col-10 social-icon-divider d-none d-md-block">
                        <hr />
                      </div>
                      <div className="col-12 d-none d-md-flex">
                        <h2 className="px-5 pt-4">Interested in Grammarly Business?</h2>
                      </div>
                      <div className="col-12 d-none d-md-block pt-4">
                        <a className="ghost-btn" href="#FormContainer">
                          Get started &gt;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 pt-4 pt-md-5">
                
                <p className="IntroPara">Industries across the globe are experiencing the impacts of the current economic landscape, and business processing organizations (BPO) are without exception. As companies are scaling back, reducing expenditures, and taking processes in-house, forward-thinking BPOs must adapt to remain competitive.</p>

                <ul>
                  <li>
                    <p>Enshored, a global outsourcing firm, employs Grammarly Business to ensure sharp documentation and positive customer interactions.</p>
                  </li>
                  <li>
                    <p>Enshored’s customer support team leverages Grammarly’s tone detector to deliver a seamless, clear, and friendly experience.</p>
                  </li>
                  <li>
                    <p>Investing in Grammarly Business has helped the team create authentic touchpoints by adopting each client’s lexicon and brand guidelines.</p>
                  </li>
                </ul>

                <p><a href="http://enshored.com" target="_blank">Enshored</a> is an outsourcing firm that provides support to some of the fastest-growing companies in the world, from e-commerce startups to global tech giants. The team works to scale disruptive, high-growth businesses by employing an agile framework supported by highly-skilled talent. As a leading global BPO, Enshored understands the importance of <a href="https://www2.deloitte.com/content/dam/Deloitte/uk/Documents/consultancy/deloitte-uk-covid-19-a-wake-up-call-for-the-bpo-industry.pdf" target="_blank">embracing flexibility</a> to deliver value-driven services. It has doubled-down on building flexibility into its strategy, offering clients back-end services, customer support, content management, and more. The company takes the unique approach to providing a white-glove experience, especially when working with startups that require a degree of agility. “Instead of focusing on what’s in the contract, we build tailored campaigns around each of our clients,” shares Maureen Toner, Client Relations Manager at Enshored. “Every engagement is different.”</p>

                <p>Flexible BPO solutions don’t happen overnight and take careful planning to execute. “We work with each client to deliver a solution that is specific to the client’s organization. It's really important for us to keep communication open and thorough,” shares Toner. She travels several times a year to the Philippines to ensure continuity of strategy with her global customer support team, who are regarded as heroes at Enshored. “We have robust discussions about what clients need, how we can serve them better, and what their customer base is looking for, as well.” To streamline these objectives, specific protocols have to be in place. Toner shares Enshored’s best practices for delivering tailored solutions for its clients.</p>

                <h2 className="my-4">Revisit the process documentation</h2>
                <p>One of the biggest opportunities for BPOs to partner with their clients is by revisiting their process documentation. “The process documentation is a form of communication,” explains Toner. “It’s important to define the steps and identify possible gaps. In addition, through the years, processes change, especially within start-ups. From year one to year five, the company is different—their process documents should be too.”</p>

                <h2 className="my-4">Define your goals</h2>
                <p>For many BPOs, their goals revolve around client satisfaction, often in the form of customer satisfaction (CSAT) or Net Promoter Score (NPS). Companies should align with clients’ metrics to track and measure results and achieve better operations. “We help our clients refine their QA process. Our food tech clients, for example, we define their KPIs, how they can track their performance and our heroes align with those standards. It creates consistency across the board.”</p>

                <h2 className="my-4">Adopt company messaging</h2>
                <p>BPO companies are an extension of the clients they serve. Providing their clients’ customers with an integrated and seamless experience fosters a successful relationship. “We take the time to learn about our clients’ lexicon and company messaging. Clients are pleasantly surprised that we take this on. We strive to be a genuine partner with our clients so that their customers receive the same experience throughout their customer journey.”</p>

                <p>Enshored builds these authentic partnerships with thorough commitment and continuous communication. Of course, this does not have to be a manual process. Enshored integrates  AI-powered technologies like Grammarly Business to <a href="https://grammarly-enterprise.com/yourteam/customer-experience" target="_blank">help streamline communication</a> for their clients and their customers, and improve internal workflows in a few key ways.</p>

                <img className="img-fluid w-100 d-none d-md-block" src={require("../../assets/images/blog/enshored-customer-story/bpo-global-ensored.png")} alt="" />
                <h2 className="my-4">Bridging the culture gap</h2>
                <p>Enshored’s customer support team works with clients all over the world. The team integrated Grammarly Business to create a seamless, clear, and friendly experience while leveraging the tone detector feature. Using the tone feature helps Enshored’s customer support heroes confidently deliver written communication that translates with their customer audience.</p>

                <h2 className="my-4">Seamless client collaboration</h2>
                <p>When strategically leveraged, technology empowers BPOs to operate within a symbiotic relationship with their clients; as a result, the customers win. Using instant messaging to help communicate success and opportunities, Enshored and their clients operate in unison, which paves the way for a phenomenal customer experience. “We like to operate as if we are in the next room from our clients,” shares Toner. “Technology allows us to make recommendations and respond to client feedback. It strengthens the customer experience and brand loyalty, which feels like a win for us.”</p>

                <h2 className="my-4">Creating authentic client touchpoints</h2>
                <p>Investing in tools to provide better service ultimately improves client satisfaction. When clients see their partners go the extra mile to deliver superior service, it creates a meaningful dynamic. “We went with Grammarly Business because we believed it to be the next step in elevating our service. Clients were pleasantly surprised to find us adopting their lexicon and adhering to their brand guidelines. They feel we’re invested in their success which helps us develop a more authentic relationship.”</p>

                <h2 className="my-4">Build a strategy to weather the storm</h2>
                <p>BPOs are learning to adapt and pivot, and in those moments, create a true partnership with their clients. To be a partner for your clients, add flexibility and guidance along the way. For Enshored, it’s the only way it knows how to operate. “We see the need and we fill it,” shares Toner. “The fact that we’re willing to jump in and help improve their business, clients love that. It creates synergy and buy-in.”</p>

              </div>
            </div>
          </div>
          <div className="blue-container">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 form-container text-center" id="FormContainer">
                  <ServiceForm />

                  <div className="Form-Intro-Container">
                    <div className="w-100 text-center pb-4 pt-0 d-flex">
                      {/* <CallToAction to="https://www.grammarly.com/business/create-team?sid=RLkRhGlJ39YuSEfDJVKH" className="initialBtn green-btn mt-0 mb-0 mx-auto">
                        Learn More
                      </CallToAction> */}
                      <a href="#" className="btn btn-primary btn-talk initialBtn green-btn mt-0 mb-0 mx-auto"
                      onClick={ (e) => {e.preventDefault();this.showConnectForm();} }>Learn More</a>
                      <CallToAction to="https://www.grammarly.com/business/create-team-new-admin?sid=ajUojFlaH1MBfDSnEERy" className="selectedBtn green-btn mt-0 mb-0 mx-auto">
                        Create your team
                      </CallToAction>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EnshoredCustomerStory;
