import React from "react"
import Layout from "../../components/Layout"
import CallToAction from '../../components/Button'

const Hr = () => (
  <Layout className="hr-landing-page">
    <section className="banner-section">
      <div className="banner">
        <div className="banner-items">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="card-title heading-type-1">Grammarly for HR Teams</h1>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="card-text p-type-1">Make Every Communication Count</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CallToAction
                  to="https://grammarly.com/contact/sales?source=enterprise"
                  eType="See-Demo"
                  eName="hr-team"
                  className="hr1seedem"
                  eLabel="enterprise-hr-team-see-demo"
                >
                  Get in touch
                </CallToAction>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-grey testimonial-1">
      <div className="container">
        <div className="d-flex flex-row flex-sm-column flex-column flex-md-row flex-xl-row">
          <div className="p-2 fb-img-block">
            <img src={require('../../assets/images/Module2_UI_hr.gif')} alt="" />
          </div>
          <div className="p-2 align-self-stretch mt-2 text-center text-md-left">
            <blockquote className="blockquote">
              <img className="inverted-comma" src={require('../../assets/images/double-inverted-comma.png')} alt="" />
              <p className="blockquote-body">Grammarly has been a great way to get just a little extra
                safeguard as we hire new teams—to make sure that everything going out to our clients and
                customers is as professional as it can possibly be.”</p>
              <footer className="blockquote-footer-1">
                <cite>
                  <span className="author mb-2">Kati Voluntine</span>
                  <span className="position">VP of People Operations, BuildingConnected</span>
                </cite>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>

    <div className="sticky-container-holder">

      <section className="help-your-team">
        <div className="container">

          <div className="row text-center hyt-img-header">
            <div className="col-md-12 hyt-img-section">
              <img src={require('../../assets/images/HR_PopOut_Icon.png')} alt="" />
              <h2 className="heading-type-4">HR sets the standard for how employees communicate.</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="help-your-team section-grey">
        <div className="container continer-show-dots">
          <div className="row text-left text-md-center hyt-sub-header">
            <div className="col-md help-team mt-3 mt-md-0 ">
              <h2 className="heading-type-2">Grammarly Business can help your team:</h2>
            </div>
          </div>
          <div className="row help-team">
            <div className="col-md">
              <h3>Foster internal alignment</h3>
              <p>Communicating clearly is essential to get everyone in your organization on the same page.
              </p>
            </div>

            <div className="col-md">
              <h3>Make good first impressions</h3>
              <p>Using precise words and the right tone attracts the best talent.</p>
            </div>

            <div className="col-md">
              <h3>Stay professional</h3>
              <p>Everyone needs to be heard and understood across an increasingly diverse and distributed
                workforce.</p>
            </div>

            <div className="col-md">
              <h3>Scale across platforms</h3>
              <p>Grammarly Business works with Gmail, Workday, Salesforce, Zendesk, and
                hundreds of other platforms where employees write.</p>
            </div>
          </div>

          <div className="row text-center d-none d-md-block">
            <div className="col-sm">
              <CallToAction
                to="https://grammarly.com/contact/sales?source=enterprise"
                eType="Lets-talk"
                eName="hr-team"
                eLabel="enterprise-hr-team-lets-talk"
                className="hr2letstalk"
              >
                Let's Talk
              </CallToAction>
            </div>
          </div>

        </div>
      </section>
      <section className="why-service-team">
        <div className="container container-circle">
          <div className="row">
            <div className="col text-left text-md-center">
              <h2 className="heading-type-3">Why HR Leaders Love Grammarly Business</h2>
            </div>
          </div>
          <div className="row card-row">
            <div className="col-md">
              <img src={require('../../assets/images/HR_1_Clear.png')} className="img-fluid"  alt="" />
              <h3>Clear</h3>
              <p>Grammarly simplifies communications to ensure everyone understands the message.</p>
            </div>
            <div className="col-md">
              <img src={require('../../assets/images/HR_2_Consistent.png')} alt=""
                   className="img-fluid"/>
              <h3>Consistent</h3>
              <p>Grammarly helps a diverse workforce communicate effectively in all interactions across
                teams.</p>
            </div>
            <div className="col-md">
              <img src={require('../../assets/images/HR_3_Enabling.png')}  alt=""
                   className="img-fluid"/>
              <h3>Timely </h3>
              <p>Grammarly improves whatever employees write as they type it, in all systems and
                workflows.</p>
            </div>
          </div>
          <div className="row d-none d-md-block">
            <div className="col text-center">
              <CallToAction
                to="https://grammarly.com/contact/sales?source=enterprise"
                eType="Lets-talk"
                eName="hr-team"
                eLabel="enterprise-hr-team-lets-talk"
                className="hr3letstalk"
              >
                Let's Talk
              </CallToAction>
            </div>
          </div>
        </div>
      </section>
      <div className="col-12 px-0 sticky-bottom d-md-none sb-custom">
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <CallToAction
                  to="https://grammarly.com/contact/sales?source=enterprise"
                  classname="d-md-none hr4letstalk"
                  eType="Lets-talk"
                  eName="hr-team"
                  eLabel="enterprise-hr-team-lets-talk"
                >
                  Let's Talk
                </CallToAction>
              </div>
            </div>
          </div>
        </section>
      </div>

    </div>
  </Layout>
)

export default Hr;
